import React, { useEffect, useMemo, useState } from 'react'
import { HttpCallGet, HttpCallPUT } from '../../utils/Services/NetworkCall'
import Loader from '../../utils/Loader/Loader'
import CustomTable from '../../utils/CustomTable/CustomTable'
import moment from 'moment'
import { Form } from 'react-bootstrap'
import './locationUser.style.scss'
import CustomButton from '../../utils/CustomButton/CustomButton'
import { ToastOnSuccess } from '../../utils/Toast/Toast'
import { ConfirmDeleteWithAction } from '../../utils/ConfirmAlerts'
import Pagination from 'react-js-pagination'
import axios from 'axios'
import UserDetails from '../User/Slider/UserDetails'
import SubHeader from '../../component/subHeader/SubHeader'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'

function LocationUser(props: any) {
    const [userData, setUserData] = useState<any>([])
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [searchField, setSearchField] = useState('')
    const [pageSize, setPageSize] = useState<any>(10)
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    const [historyDetails, setHistoryDetails] = useState<any>([])

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const fetchUsersAccountType = () => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_BASE_URL}admin/locations/users`, {
                headers: {
                    Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                },
                params: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                    search: searchField,
                    // accountType: 1,
                    locationId: _id,
                },
            })
            .then((res: any) => {
                setLoading(false)
                setUserData(res.data.data)
                setTotal(res.data.totalUsers)
            })
            .catch((err: any) => {
                console.log('error', err)
            })
    }

    const handleRenewal = (data: any) => {
        setLoading(true)
        HttpCallPUT(
            `admin/user/update-auto-renewal`,
            {
                userId: data._id,
                status: false,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                ToastOnSuccess(res.data.message)
                fetchUsersAccountType()
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const userDetails = (data: any) => {
        setShow(true)
        HttpCallGet(
            `admin/user/history?userId=${data._id}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setHistoryDetails(res.data.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    // User Delete details
    const deleteUserDetails = (data: any) => {
        setLoading(true)
        HttpCallPUT(
            `admin/user/update-status`,
            {
                userId: data._id,
                status: data.status == 1 ? 0 : 1,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                ToastOnSuccess(res.data.message)
                fetchUsersAccountType()
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'User Name',
                accessor: 'name',
                Cell: (item: any) => {
                    return (
                        <div className="User_Name">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: (item: any) => {
                    return (
                        <div className="User_email">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Account type',
                accessor: 'accountType',
                Cell: (item: any) => {
                    return (
                        <div className="Account_Type">
                            <div>
                                {item.cell?.value == '1'
                                    ? 'Individual'
                                    : 'Corporate'}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (item: any) => {
                    return (
                        <div className="User_type">
                            <div>
                                {item.cell?.value == 1 ? 'Active' : 'Inactive'}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: 'Resgistered On',
                accessor: 'createdAt',
                Cell: (item: any) => {
                    return (
                        <div className="Created_At">
                            <div>
                                {moment(item.value).format('DD-MMM-YYYY')}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: 'Boxes In use',
                accessor: 'totalBoxes',
                Cell: (item: any) => {
                    return (
                        <div className="Total_No_Boxes Auto_Renewal ">
                            <div className="autorenewalBox">
                                {item.cell?.value}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                accessor: '',
                Cell: (item: any) => {
                    return (
                        <div className="User_category">
                            <div className="Info">
                                <i
                                    className="fa-solid fa-circle-info infoIcon"
                                    onClick={() => {
                                        userDetails(item.cell.row.original)
                                    }}
                                ></i>
                            </div>
                            <div className="UserDelete">
                                <i
                                    className={
                                        item?.cell?.row?.original.status == 1
                                            ? 'fa-solid fa-trash-can delete'
                                            : 'fa-solid fa-recycle recycleIcon'
                                    }
                                    onClick={() =>
                                        ConfirmDeleteWithAction(
                                            item?.cell?.row?.original,
                                            '',
                                            `Are you sure, you want to ${
                                                item.cell.row.original.status ==
                                                1
                                                    ? 'Delete'
                                                    : 'Restore'
                                            } it?`,
                                            `${
                                                item.cell.row.original.status ==
                                                1
                                                    ? 'Delete'
                                                    : 'Restore'
                                            }`,
                                            deleteUserDetails
                                        )
                                    }
                                ></i>
                            </div>
                        </div>
                    )
                },
            },
        ],
        [currentPage]
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchUsersAccountType()
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [searchField, currentPage, pageSize, props.activeUser])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchField])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <>
                    <div className="user-sec locationUser">
                        <SubHeader HeaderTitle="Users" />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="FirstRow">
                                        <div className="Demo">
                                            <Form.Control
                                                type="text"
                                                value={searchField}
                                                placeholder="Search"
                                                onChange={(e: any) => {
                                                    setSearchField(
                                                        e.target.value
                                                    )
                                                }}
                                                className="Input_Filed"
                                            />
                                            <i
                                                className="fa fa-search searchIcon"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="User_table">
                            <CustomTable
                                msg="No user found"
                                columns={columns}
                                data={userData || []}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="pagination_section">
                                <div className="table__pagination__container pagination_table">
                                    {showPagination(total, pageSize) && (
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={pageSize}
                                            totalItemsCount={total}
                                            onChange={(page: any) => {
                                                setCurrentPage(page)
                                            }}
                                        />
                                    )}
                                    {showPageSize(total) && (
                                        <select
                                            className="pagination__select"
                                            value={pageSize}
                                            onChange={handlePageSizeChange}
                                        >
                                            {[10, 20, 30].map((size) => (
                                                <option key={size} value={size}>
                                                    Show {size}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    <div className="perPageItem">
                                        <p>
                                            {paginationHandler(
                                                currentPage,
                                                pageSize,
                                                total
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="User_Details">
                            <UserDetails
                                open={show}
                                setOpen={setShow}
                                data={historyDetails}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default LocationUser
