import React, { useMemo, useState, useEffect } from 'react'
import './order.scss'
import CustomTable from '../../utils/CustomTable/CustomTable'
import axios from 'axios'
import ReactDatePicker from 'react-datepicker'
import Pagination from 'react-js-pagination'
import Loader from '../../utils/Loader/Loader'
import CustomDropdown from '../../utils/CustomDropdown/CustomDropdown'
import {
    convertUnixFormat,
    dateFormater,
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'
import CustomButton from '../../utils/CustomButton/CustomButton'
import {
    bindMultiDataHandler,
    filterContainer,
    handlerModifier,
    statusHandler,
} from './handler'
import { HttpCallGet } from '../../utils/Services/NetworkCall'
import FirstModel from './firstModel/FirstModel'
import SubHeader from '../../component/subHeader/SubHeader'
import config from '../../configs/config'

const DropDownDataValue = [
    { label: 'New', value: 1 },
    { label: 'In Progress', value: 2 },
    { label: 'Dispatched', value: 3 },
    { label: 'Deliverd', value: 4 },
    { label: 'Invoiced', value: 5 },
    { label: 'Paid', value: 6 },
    { label: 'Draft', value: 7 },
]

function Order(props: any): JSX.Element {
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [pageSize, setPageSize] = useState<number>(10)

    const [total, setTotal] = useState<number>(0)

    const [loading, setLoading] = useState<boolean>(false)

    const [orderTableData, setOrderTableData] = useState<any>([])

    const [dateHolder, setDateHolder] = useState<any>('')

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    const [activeOrder, setActiveOrder] = useState<any>({
        label: 'Select status',
    })

    const [firstSlider, setFirstSlider] = useState<boolean>(false)

    const [secondSlider, setSecondSlider] = useState({
        isActive: false,
        flag: '',
        orderDetails: '',
        data: [],
    })

    const [orderHistoryDetails, setOrderHistoryDetails] = useState<any>()

    const [orderDetailTotalValues, setOrderDetailTotalValues] = useState<any>()

    const fetchOrderDetail = () => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_BASE_URL}admin/vendor/order-list`, {
                headers: {
                    Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                },
                params: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                    locationId: _id,
                    date: dateHolder,
                    userType: '',
                    orderStatus: activeOrder?.value,
                },
            })
            .then((res: any) => {
                setLoading(false)
                setOrderTableData(res.data.data)
                setTotal(res.data.totalOrder)
            })
            .catch((err: any) => {
                setLoading(false)
                console.log('error', err)
            })
    }

    const fetchOrderHistoryDetails = async (id: any) => {
        try {
            setLoading(true)
            const box: any = await HttpCallGet(
                `admin/boxes-type?currentPage=1&pageSize=1000`,
                sessionStorage.getItem('AUTH_TOKEN')
            )

            const wrh: any = await HttpCallGet(
                `admin/getLocationInventoryByWarehouse?_id=${_id}&currentPage=1&pageSize=1000`, /// i'm not giving pageSize as dynammic because requirement for now pageSize=1000
                sessionStorage.getItem('AUTH_TOKEN')
            )

            HttpCallGet(
                `admin-order/individualInfo?orderId=${id}`,
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setLoading(false)
                    setFirstSlider(true)
                    const activeData = handlerModifier(
                        res,
                        bindMultiDataHandler(
                            res.data.data,
                            wrh,
                            filterContainer(box.data.data)
                        )
                    )
                    setOrderHistoryDetails(activeData)
                    if (activeData.orderStatus >= 3) {
                        fetchOrderDetailsTotal(activeData._id)
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    console.log('errrorr', err)
                })
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const fetchOrderDetailsTotal = (id: any) => {
        HttpCallGet(
            `admin-order/payment-summary?orderId=${id}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setOrderDetailTotalValues(res.data.data[0])
            })
            .catch((err) => {
                console.log('Error', err)
            })
    }
    const removeLastCharacter = (str: string) => {
        let result = null
        if (str != null && str.length > 0) {
            result = str.substring(0, str.length - 1)
        }
        return result?.toUpperCase()
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'Order Id',
                accessor: 'orderNumber',
                Cell: (item: any) => {
                    return <div>{item.cell?.value}</div>
                },
            },
            {
                Header: 'User Name',
                accessor: 'vendorName',
                Cell: (item: any) => {
                    return <div>{item.cell?.value}</div>
                },
            },
            {
                Header: 'User Type',
                accessor: 'userType',
                Cell: (item: any) => {
                    return (
                        <div>
                            {item.cell.value === 'VENDOR'
                                ? removeLastCharacter(config.venderTitle)
                                : item.cell.value}
                        </div>
                    )
                },
            },
            {
                Header: 'Quantity',
                accessor: 'totalBoxes',
                Cell: (item: any) => {
                    return <div>{item.cell?.value}</div>
                },
            },
            {
                Header: 'Order On',
                accessor: 'orderTime',
                Cell: (item: any) => {
                    return <div>{dateFormater(item.value)}</div>
                },
            },
            {
                Header: 'Updated On',
                accessor: 'updatedAt',
                Cell: (item: any) => {
                    return <div>{dateFormater(item.value)}</div>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (item: any) => {
                    return (
                        <div
                            className={`${
                                statusHandler(item.cell.value)?.activeClName
                            } `}
                        >
                            {statusHandler(item.cell.value)?.activeBtName}
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'button',
                Cell: (item: any) => {
                    return (
                        <div>
                            <CustomButton
                                clsName="orderDetailbtn"
                                btnEvt={() => {
                                    showSliderModel(
                                        item.cell.row.original,
                                        true
                                    )
                                }}
                                btnName="Details"
                            >
                                <i className="fa-solid fa-circle-info infoIcon"></i>
                            </CustomButton>
                        </div>
                    )
                },
            },
        ],
        [currentPage]
    )

    const showSliderModel = (orderH: any, showModel: boolean) => {
        setSecondSlider({ ...secondSlider, orderDetails: orderH })
        fetchOrderHistoryDetails(orderH._id)
    }

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
        setCurrentPage(1)
    }

    useEffect(() => {
        fetchOrderDetail()
    }, [pageSize, currentPage, activeOrder, dateHolder, props.activeUser])

    useEffect(() => {
        setCurrentPage(1)
    }, [dateHolder, activeOrder])

    useEffect(() => {
        setCurrentPage(1)
    }, [activeOrder, dateHolder])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <>
                    <div className="OrderSection">
                        <SubHeader HeaderTitle="Order" />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="DateAndDropdown row">
                                    <div className="OrderDateSelect col-12 col-md-6">
                                        <ReactDatePicker
                                            selected={dateHolder}
                                            placeholderText="Order date"
                                            onChange={(date: any) => {
                                                setDateHolder(
                                                    convertUnixFormat(date)
                                                )
                                                setCurrentPage(1)
                                            }}
                                        />
                                    </div>
                                    <div className="Status_Section col-12 col-md-6">
                                        <CustomDropdown
                                            parrentClassName="statusSec ms-4 ms-md-0 "
                                            toggleClassName="toggle"
                                            name=""
                                            data={DropDownDataValue}
                                            activeDrop={activeOrder}
                                            setActiveDrop={setActiveOrder}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="orderTable table-responsive custmizeOrdeTable">
                                    <CustomTable
                                        msg="No order found"
                                        columns={columns}
                                        data={orderTableData || []}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="table__pagination__container tablePagination">
                                    <div className="itemPagination">
                                        {showPagination(total, pageSize) && (
                                            <Pagination
                                                activePage={currentPage}
                                                itemsCountPerPage={pageSize}
                                                totalItemsCount={total}
                                                onChange={(page: any) => {
                                                    setCurrentPage(page)
                                                }}
                                            />
                                        )}
                                        {showPageSize(total) && (
                                            <select
                                                className="pagination__select"
                                                value={pageSize}
                                                onChange={handlePageSizeChange}
                                            >
                                                {[10, 20, 30].map((size) => (
                                                    <option
                                                        key={size}
                                                        value={size}
                                                    >
                                                        Show {size}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                    <div className="perPageItem">
                                        <p>
                                            {paginationHandler(
                                                currentPage,
                                                pageSize,
                                                total
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <FirstModel
                show={firstSlider}
                setShow={setFirstSlider}
                orderHistoryDetails={orderHistoryDetails}
                secondSlider={secondSlider}
                setSecondSlider={setSecondSlider}
                orderDetailTotalValues={orderDetailTotalValues}
                fetchInfoData={fetchOrderHistoryDetails}
            />
        </>
    )
}
export default Order
