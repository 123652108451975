import React from 'react';
import './customBtn.style.scss';
interface iButton {
    btnName?:string;
    clsName?:string;
    btnEvt?:any;
    children?:any;
}
const CustomButton:React.FC<iButton> =({btnName="Custom Button", clsName=``, btnEvt, children}) =>{
  return (
      <button className={`Custom__button ${clsName}`} onClick={() => btnEvt()}>
          {children && children}
          {btnName}
      </button>
  )
}

export default CustomButton