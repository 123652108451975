import React from 'react'
import Chart from 'react-apexcharts'
import './chart.style.scss'

export default function PendingDonutchart(props: any) {
    const { pending } = props

    return (
        <div>
            <center>
                <label className="pending_label">Total Pending Orders</label>
            </center>
            <Chart
                type="donut"
                options={{
                    fill: {
                        colors: ['#27c24c'],
                    },
                    legend: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the tooltip display on hover
                    },
                    dataLabels: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        formatter: function (val: any, opts: any) {
                            if (val === 0) {
                                return '0'
                            } else {
                                return opts.w.config.series[opts.seriesIndex]
                            }
                        },
                    },
                }}
                series={pending ? [pending] : ['0.1']} // while passing zero nothing is displaying so we passed 0.1
                width={400}
                height={300}
            />
        </div>
    )
}
