import React, { useEffect, useRef, useState, useMemo } from 'react'
import {
    HttpCallGet,
    HttpCallPost,
    HttpCallPUT,
} from '../../utils/Services/NetworkCall'
import Loader from '../../utils/Loader/Loader'
import SubHeader from '../../component/subHeader/SubHeader'
import './Donations.scss'
import CustomButton from '../../utils/CustomButton/CustomButton'
import CustomTable from '../../utils/CustomTable/CustomTable'
import Pagination from 'react-js-pagination'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'

const Donations = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [donationData, setDonationData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [pageSize, setPageSize] = useState<number>(1)

    const [total, setTotal] = useState<number>(0)
    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)
    const fetchDonationData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/getListUserDonatedReward?currentPage=1&locationId=${_id}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setDonationData(res?.data?.data)
                setTotal(res?.data?.totalRecords)
                // setDonationData(
                //     res.data.data != '' ? res.data.data : res.data.message
                // )
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }
    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }
    const columns: any = useMemo(
        () => [
            {
                Header: 'Coupon code',
                accessor: 'couponCode',
                Cell: (item: any) => {
                    return <div>{''}</div>
                },
            },
            {
                Header: 'Used points',
                accessor: 'points',
                Cell: (item: any) => {
                    return (
                        <div>{`${item?.cell?.row?.original.usedBenefit[0]?.points}`}</div>
                    )
                },
            },
            {
                Header: 'Rewards Title',
                accessor: 'benefitTitle',
                Cell: (item: any) => {
                    return (
                        <div>{`${item?.cell?.row?.original.usedBenefit[0]?.benefitTitle}`}</div>
                    )
                },
            },
            {
                Header: 'Rewards Type',
                accessor: 'earnedType',
                Cell: (item: any) => {
                    return (
                        <div>{`${
                            item?.cell?.row?.original.earnedType == 2
                                ? 'Goods'
                                : 'Cash'
                        }`}</div>
                    )
                },
            },
            {
                Header: 'Rewards Value',
                accessor: 'benefitValue',
                Cell: (item: any) => {
                    return (
                        <div>{`${item?.cell?.row?.original.usedBenefit[0]?.benefitValue}`}</div>
                    )
                },
            },
            {
                Header: 'Charity',
                accessor: 'rewardInfo',
                Cell: (item: any) => {
                    return (
                        <div>{`${item?.cell?.row?.original.rewardInfo[0]?.charities[0]?.name}`}</div>
                    )
                },
            },
            {
                Header: 'User',
                accessor: 'userDetails',
                Cell: (item: any) => {
                    return (
                        <div>{`${item?.cell?.row?.original.userDetails[0]?.name}`}</div>
                    )
                },
            },
        ],
        []
    )
    useEffect(() => {
        fetchDonationData()
    }, [props.activeUser, pageSize, currentPage])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="Claims">
                    <SubHeader HeaderTitle="Donations" />
                    <div className="Content container-fluid">
                        <div className="row no-available">
                            <div className="col-md-12">
                                <CustomTable
                                    msg="No donation found"
                                    columns={columns}
                                    data={donationData || []}
                                />
                            </div>
                            <div className="col-md-12">
                                {/* <div className="table__pagination__container">
                                    {total > pageSize && (
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={pageSize}
                                            totalItemsCount={total}
                                            onChange={(page: any) => {
                                                setCurrentPage(page)
                                            }}
                                        />
                                    )}
                                    {donationData.length > 0 && (
                                        <select
                                            className="pagination__select"
                                            value={pageSize}
                                            onChange={handlePageSizeChange}
                                        >
                                            {[10, 20, 30].map((size) => (
                                                <option key={size} value={size}>
                                                    Show {size}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div> */}
                                <div className="table__pagination__container tablePagination">
                                    <div className="itemPagination">
                                        {showPagination(total, pageSize) && (
                                            <Pagination
                                                activePage={currentPage}
                                                itemsCountPerPage={pageSize}
                                                totalItemsCount={total}
                                                onChange={(page: any) => {
                                                    setCurrentPage(page)
                                                }}
                                            />
                                        )}
                                        {showPageSize(total) && (
                                            <select
                                                className="pagination__select"
                                                value={pageSize}
                                                onChange={handlePageSizeChange}
                                            >
                                                {[10, 20, 30].map((size) => (
                                                    <option
                                                        key={size}
                                                        value={size}
                                                    >
                                                        Show {size}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                    <div className="perPageItem">
                                        <p>
                                            {paginationHandler(
                                                currentPage,
                                                pageSize,
                                                total
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Donations
