import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import './Modal.scss'
import NoData from '../../../utils/NoData/NoData'

function DetailModal(props: any) {
    const { open, setOpen, data } = props
    const statusHandler = (orderStatus: number) => {
        if (orderStatus == 1) {
            return <span className="commonText">ORDERED</span>
        } else if (orderStatus == 2) {
            return <b className="commonText">IN PROGRESS</b>
        } else if (orderStatus == 3) {
            return <b className="commonText">DISPATCHED</b>
        } else if (orderStatus == 4) {
            return <b className="commonText">DELIVERED</b>
        } else if (orderStatus == 5) {
            return <b className="commonText"> INVOICED</b>
        } else {
            return <b className="commonText">PAID</b>
        }
    }
    return (
        <>
            <div>
                <Offcanvas
                    show={open}
                    onHide={() => setOpen(false)}
                    placement="end"
                    className="Main_head"
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title className="wrh__title drp_title">
                            Details ({sessionStorage.getItem('WR_TITLE')})
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <hr />
                    <Offcanvas.Body>
                        {data.length > 0 ? (
                            data.map((item: any, i: number) => {
                                return (
                                    <div key={i}>
                                        <div className="Main_Content">
                                            <div className="card Image_part">
                                                <div className="img">
                                                    <div>
                                                        <img
                                                            src={
                                                                item.inventoryIcon
                                                            }
                                                            className="image"
                                                            alt="..."
                                                        />
                                                    </div>
                                                    <div className="card-body">
                                                        <span className="card-title">
                                                            Quality {item.count}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="Details">
                                                    <h6>
                                                        Order ID:{' '}
                                                        <span className="orderId">
                                                            {item.orderNumber}
                                                        </span>
                                                    </h6>
                                                    <h6>
                                                        Order Status:{' '}
                                                        {statusHandler(
                                                            item.orderStatus
                                                        )}
                                                    </h6>
                                                    {item.deliveryDate && (
                                                        <h6>
                                                            Delivered On:{' '}
                                                            {item.deliveryDate}
                                                        </h6>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <NoData content="No Details Found" />
                        )}
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    )
}

export default DetailModal
