export const statusHandler = (statusCode: number): { activeClName: string, activeBtName: string } => {
    const statusList: any = {
        1: { activeClName: 'NewBtn', activeBtName: 'New' },
        2: { activeClName: 'inprocessBtn', activeBtName: 'In Process' },
        3: { activeClName: 'shippedBtn', activeBtName: 'Shipped' },
        4: { activeClName: 'deliverBtn', activeBtName: 'Delivered' },
        5: { activeClName: 'invoicedBtn', activeBtName: 'Invoiced' },
        6: { activeClName: 'paidBtn', activeBtName: 'Paid' },
    };

    const status = statusList[statusCode];
    if (status) {
        return status;
    } else {
        return { activeClName: '', activeBtName: `` };
    }
};


export const handlerModifier = (res: any, items: any) => {
    const orderHistoryDetails: any = res.data.data;
    orderHistoryDetails.verticalList = [];
    orderHistoryDetails.horizontalList = [];
    const {wareHouseList} = items;
    orderHistoryDetails.wrh = wareHouseList.data.data;
    const assignedQty: any = {}

    if (res.data.status == 1) {

        for (let i = 0; i < items.containerList.length; i++) {

            assignedQty[items.containerList[i]._id] = 0;

            const index = orderHistoryDetails.orderedBoxes.findIndex(function (val: any) {
                return val._id === items.containerList[i]._id
            })
            if (index > -1) {
                const newObj: any = {}
                newObj._id = orderHistoryDetails.orderedBoxes[index]._id
                newObj.boxTitle = orderHistoryDetails.orderedBoxes[index].boxTitle
                newObj.quantity = orderHistoryDetails.orderedBoxes[index].quantity
                orderHistoryDetails.verticalList.push(newObj);
            } else {
                orderHistoryDetails.verticalList.push({
                    _id: items.containerList[i]._id,
                    boxTitle: items.containerList[i].title,
                    quantity: 0
                })
            }
        }


        for (let i = 0; i < orderHistoryDetails.assignedItems.length; i++) {
            if (orderHistoryDetails.assignedItems[i].itemType == "CONTAINER") {
                const newObj: any = {
                    boxId: orderHistoryDetails.assignedItems[i].boxId,
                    itemType: orderHistoryDetails.assignedItems[i].itemType,
                    containers: []
                }

                for (const key in assignedQty) {
                    if (key == orderHistoryDetails.assignedItems[i].counts[0]._id) {
                        assignedQty[key] = assignedQty[key] + orderHistoryDetails.assignedItems[i].counts[0].quantity;
                        newObj.containers.push(orderHistoryDetails.assignedItems[i].counts[0])
                    } else {
                        newObj.containers.push({ _id: key, quantity: 0 })
                    }
                }
                orderHistoryDetails.horizontalList.push(newObj)
            } else {
                const newObj: any = {
                    boxId: orderHistoryDetails.assignedItems[i].boxId,
                    itemType: orderHistoryDetails.assignedItems[i].itemType,
                    containers: []
                }

                const newCartonObj: any = {}
                for (let j = 0; j < orderHistoryDetails.assignedItems[i].counts.length; j++) {
                    if (newCartonObj.hasOwnProperty.call(orderHistoryDetails.assignedItems[i].counts[j]._id)) {
                        newCartonObj[orderHistoryDetails.assignedItems[i].counts[j]._id] = Object.prototype.hasOwnProperty.call(
                            orderHistoryDetails.assignedItems[i].counts[j],
                            '_id'
                        ) + orderHistoryDetails.assignedItems[i].counts[j].quantity;

                        // newCartonObj[orderHistoryDetails.assignedItems[i].counts[j]._id] = orderHistoryDetails.assignedItems[i].counts[j]._id + orderHistoryDetails.assignedItems[i].counts[j].quantity
                    } else {
                        newCartonObj[orderHistoryDetails.assignedItems[i].counts[j]._id] = orderHistoryDetails.assignedItems[i].counts[j].quantity
                    }
                }

                for (const key in assignedQty) {
                    if (Object.hasOwnProperty.call(newCartonObj, key)) {
                        assignedQty[key] = assignedQty[key] + newCartonObj[key];
                        newObj.containers.push({ _id: key, quantity: newCartonObj[key] });
                    } else {
                        newObj.containers.push({ _id: key, quantity: 0 });
                    }

                }

                orderHistoryDetails.horizontalList.push(newObj)

            }
        }

        for (let k = 0; k < orderHistoryDetails.verticalList.length; k++) {
            orderHistoryDetails.verticalList[k].assigned = assignedQty[orderHistoryDetails.verticalList[k]._id]
            orderHistoryDetails.verticalList[k].remaining = orderHistoryDetails.verticalList[k].quantity - orderHistoryDetails.verticalList[k].assigned
            if (orderHistoryDetails.verticalList[k].remaining < 0) {
                orderHistoryDetails.verticalList[k].remaining = (orderHistoryDetails.verticalList[k].remaining * -1) + " Extra"
            }
        }

        // getOrderDetailsTotal();
    } else {
        console.log("error", "Error", res.data.error);
    }

    return orderHistoryDetails;




}


export const printOrder = function (printableOrders: string) {
    const element = document.getElementById(printableOrders);
    if (element !== null) {
        const innerContents: any = element.innerHTML;
        const popupWindow: any = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        if (popupWindow !== null) {
            popupWindow.document.open();
            popupWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print();window.close();">' + innerContents + '</html>');
            // popupWindow.document.close();
            popupWindow.print()

        }
    }
}


export const bindMultiDataHandler = (order: any, wrhList: any, container: any) => {
    return {
        order: order,
        wareHouseList: wrhList,
        containerList: container
    }

}

export const filterContainer = (data: any) => {
    return data.filter((item: any) => item.itemType === "CONTAINER")
}


const formatDeliveryDate = (deliveryDate:any) => {
    if (!deliveryDate) return '';
  
    const formattedDate = new Date(deliveryDate).toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  
    return formattedDate;
  };
  
  export const OrderHistoryDateHandler = (date:any) => {
    return formatDeliveryDate(date)

  }

 export  const orderDropHandler = (data: any) => {
    const obj ={
        name: "Any",
        value:undefined
    }
    const arr =[...data];
    arr.unshift(obj)
    return arr.map((item: any) => ({ ...item, label: item.name, value: item.value }))
}