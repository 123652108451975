import Offcanvas from 'react-bootstrap/Offcanvas'
import React, { useState, useEffect, useRef } from 'react'
import CustomButton from '../../../utils/CustomButton/CustomButton'
import { HttpCallPost } from '../../../utils/Services/NetworkCall'
import axios from 'axios'
import CustomDropdown from '../../../utils/CustomDropdown/CustomDropdown'
import { ToastOnFailure, ToastOnSuccess } from '../../../utils/Toast/Toast'
import { ConfirmDelete } from '../../../utils/ConfirmAlerts'
import { orderDropHandler } from '../handler'
import './secondModel.style.scss'
import Loader from '../../../utils/Loader/Loader'

interface Iprops {
    show: any
    setShow: any
    orderHistoryDetails: any
    fetchInfoData: any
}

const OrderModelSecond: React.FC<Iprops> = ({
    show,
    setShow,
    orderHistoryDetails,
    fetchInfoData,
}) => {
    const [data, setData] = useState<any>([])

    const [currentPage, setCurrentPage] = useState(1)

    const [pageSize, setPageSize] = useState(20)

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    const [activeWrhId, setActiveWrhId] = useState<any>({
        label: 'Any',
    })

    const [search, setSearch] = useState('')

    const scrollContainerRef = useRef<any>(null)

    const [loading, setLoading] = useState(false)

    const [total, setTotal] = useState<any>()

    const endPointHandler = (data: any) => {
        return data.orderDetails?.status == 2
            ? `admin/getLocationInventory`
            : `admin-order/carton-items`
    }
    const doneHandler = () => {
        setShow({ ...show, isActive: false })
        fetchInfoData(orderHistoryDetails._id)
    }
    const fetchDetails = () => {
        if (show.flag == 'SHOW_ORDER') {
            setLoading(true)
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}${endPointHandler(show)}`,
                    {
                        headers: {
                            Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                        },
                        params: {
                            currentPage: 1, //currentPage
                            pageSize: 1000, //pageSize
                            cartonId: show.data.boxId,
                            orderId: show.orderDetails._id,
                        },
                    }
                )
                .then((res: any) => {
                    setLoading(false)
                    setData((prev: any) => [...prev, ...res.data.data])
                    setTotal(res.data.totalRecords)
                })
                .catch((err: any) => {
                    console.log('error', err)
                    setLoading(false)
                })
        } else if (show.flag == 'ADD_ORDER') {
            setLoading(true)
            axios
                .get(`${process.env.REACT_APP_BASE_URL}admin-order/boxes`, {
                    headers: {
                        Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                    },
                    params: {
                        currentPage: currentPage,
                        pageSize: pageSize,
                        locationId: _id,
                        orderId: show.orderDetails._id,
                        warehouseId: activeWrhId?._id,
                        search: search,
                    },
                })
                .then((res: any) => {
                    setLoading(false)

                    setData(res.data.data)
                })
                .catch((err: any) => {
                    console.log('error', err)
                    setLoading(false)
                })
        }
    }
    // const fetchDetails = () => {
    //     if (show.flag == 'SHOW_ORDER') {
    //         setLoading(true)
    //         axios
    //             .get(
    //                 `${process.env.REACT_APP_BASE_URL}admin-order/carton-items`,
    //                 {
    //                     headers: {
    //                         Authorization: sessionStorage.getItem('AUTH_TOKEN'),
    //                     },
    //                     params: {
    //                         currentPage: currentPage,
    //                         pageSize: pageSize,
    //                         cartonId: show.data.boxId,
    //                         orderId: show.orderDetails._id,
    //                     },
    //                 }
    //             )
    //             .then((res: any) => {
    //                 setLoading(false)
    //                 setData((prev: any) => [...prev, ...res.data.data])
    //                 setTotal(res.data.totalRecords)
    //             })
    //             .catch((err: any) => {
    //                 console.log('error', err)
    //                 setLoading(false)
    //             })
    //     } else if (show.flag == 'ADD_ORDER') {
    //         setLoading(true)
    //         axios
    //             .get(`${process.env.REACT_APP_BASE_URL}admin-order/boxes`, {
    //                 headers: {
    //                     Authorization: sessionStorage.getItem('AUTH_TOKEN'),
    //                 },
    //                 params: {
    //                     currentPage: currentPage,
    //                     pageSize: pageSize,
    //                     locationId: _id,
    //                     orderId: show.orderDetails._id,
    //                     warehouseId: activeWrhId?._id,
    //                     search: search,
    //                 },
    //             })
    //             .then((res: any) => {
    //                 setLoading(false)

    //                 setData(res.data.data)
    //             })
    //             .catch((err: any) => {
    //                 console.log('error', err)
    //                 setLoading(false)
    //             })
    //     }
    // }

    const removeCartonInventry = (box: any) => {
        HttpCallPost(
            `admin/removeInventory`,
            {
                actionfrom: 'CARTON',
                _id: box._id,
                itemfrom: 'ORDER',
                orderId: show.orderDetails._id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    const assignedBox = (box: any) => {
        HttpCallPost(
            `admin-order/assign-boxes-in-order`,
            {
                orderId: show.orderDetails._id,
                isAssigned: true,
                boxId: box.boxId,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                fetchDetails()
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    const uiRender = (key: string, data: any) => {
        if (key == 'SHOW_ORDER') {
            return (
                <div>
                    <table>
                        <tr>
                            <th>Warehouse</th>
                            <th>Inventory Id</th>
                            <th>Type</th>
                            {orderHistoryDetails.orderStatus < 3 && (
                                <th>Action</th>
                            )}
                        </tr>
                        {data.length > 0 &&
                            data.map((item: any) => {
                                return (
                                    <tr key={item._id}>
                                        <td>{item.warehouseInfo[0].name}</td>
                                        <td>{item.boxId}</td>
                                        <td>{item.boxTypeDetails[0].type}</td>
                                        {orderHistoryDetails.orderStatus <
                                            3 && (
                                            <td>
                                                <a
                                                    className="orderBoxTable__cmpOrder"
                                                    onClick={() =>
                                                        ConfirmDelete(
                                                            item,
                                                            removeCartonInventry
                                                        )
                                                    }
                                                >
                                                    Remove
                                                </a>
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                    </table>
                </div>
            )
        } else if (key == 'ADD_ORDER') {
            return (
                <div>
                    <div className="row mb-2 addOrder">
                        <div className="col-12 col-md-6">
                            <CustomDropdown
                                name="Warehouse"
                                data={orderDropHandler(orderHistoryDetails.wrh)}
                                activeDrop={activeWrhId}
                                setActiveDrop={setActiveWrhId}
                            />
                        </div>
                        <div className="col-12 col-md-6 secRow">
                            <p className="label">Search</p>
                            <input
                                className="search "
                                type="text"
                                value={search}
                                onChange={(e: any) => setSearch(e.target.value)}
                            />

                            <span className="searchIcon">
                                {!search && (
                                    <i
                                        className="fa fa-search "
                                        aria-hidden="true"
                                    ></i>
                                )}
                            </span>
                        </div>
                    </div>

                    <table>
                        <tr>
                            <th>Warehouse</th>
                            <th>Inventory Id</th>
                            <th>Type</th>
                            <th>Total Containers</th>
                            <th>Action</th>
                        </tr>
                        {data?.length > 0 &&
                            data?.map((item: any) => {
                                return (
                                    <tr key={item._id}>
                                        <td>{item?.warehouseInfo[0].name}</td>
                                        <td>{item?.boxId}</td>
                                        <td>
                                            {item?.boxTypeDetails[0].itemType}
                                        </td>

                                        <td>
                                            {item?.counts?.map(
                                                (it: any, index: number) => {
                                                    return (
                                                        <>
                                                            <span>
                                                                {index != 0 && (
                                                                    <b>,</b>
                                                                )}
                                                                {it?.title}{' '}
                                                                {it?.counts}
                                                            </span>
                                                        </>
                                                    )
                                                }
                                            )}
                                        </td>
                                        <td>
                                            <a
                                                className="orderBoxTable__addOrder"
                                                onClick={() =>
                                                    assignedBox(item)
                                                }
                                            >
                                                Add
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })}
                    </table>
                </div>
            )
        }
    }

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current
        if (
            scrollContainer.scrollTop + scrollContainer.clientHeight >=
            scrollContainer.scrollHeight
        ) {
            if (!loading) {
                setCurrentPage((prevPage) => prevPage + 1)
            }
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchDetails()
        }, 400)
        return () => {
            clearTimeout(timer)
        }
    }, [show.flag, currentPage, activeWrhId, search])

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current

        if (data.length < total && scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll)
            }
        }
    }, [data.length, total, show.flag])

    return (
        <>
            <Offcanvas
                className="OrderHistory"
                placement="end"
                show={show.isActive}
                onHide={() => setShow({ ...show, isActive: false })}
            >
                <Offcanvas.Header className="offHeader">
                    <Offcanvas.Title>
                        <span className="commonHeaderTitle">Order</span>
                    </Offcanvas.Title>
                    <CustomButton btnName="Done" btnEvt={() => doneHandler()} />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <hr />
                    {loading ? (
                        <Loader startLoader={loading} />
                    ) : (
                        <div
                            ref={scrollContainerRef}
                            className="orderBoxTable table-responsive"
                        >
                            {uiRender(show.flag, data)}
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OrderModelSecond
