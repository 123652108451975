import React, { useEffect, useMemo, useState } from 'react'
import Loader from '../../utils/Loader/Loader'
import CustomTable from '../../utils/CustomTable/CustomTable'
import moment from 'moment'
import './pending.style.scss'
import CustomButton from '../../utils/CustomButton/CustomButton'

import Pagination from 'react-js-pagination'
import axios from 'axios'
import SubHeader from '../../component/subHeader/SubHeader'
import { dataHandler, keyExtracter } from './hander'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'

function PendingContainer(props: any) {
    const [pendingData, setPendingData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<any>(10)
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const downloadPdf = async () => {
        try {
            const baseUrl = process.env.REACT_APP_BASE_URL
            const newTab: any = window.open(
                `${baseUrl}admin/downloadOutStandingContainersList?locationId=${_id}`,
                '_blank'
            )
            const closeTimeout = 1000
            setTimeout(() => {
                newTab.close()
            }, closeTimeout)
        } catch (error) {
            console.log('error', error)
        }
    }

    const collumnAppender = (data: any) => {
        const pending = pendingData ? pendingData[0]?.userAdditionField : []
        // eslint-disable-next-line prefer-const
        let spreadData: any = data
        for (let i = 0; i < pending?.length; i++) {
            const keys = keyExtracter(pending[i]).keys
            const appendData = {
                Header: `${keys}`,
                accessor: `${keys}`,
                Cell: (item: any) => {
                    return (
                        <div className="Created_At">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            }
            spreadData.push(appendData)
        }
        return spreadData
    }

    const fetchPendingContainer = () => {
        setLoading(true)
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}admin/outStandingContainersList`,
                {
                    headers: {
                        Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                    },
                    params: {
                        currentPage: currentPage,
                        pageSize: pageSize,
                        locationId: _id,
                    },
                }
            )
            .then((res: any) => {
                setLoading(false)
                setPendingData(dataHandler(res.data.data))
                setTotal(res.data.totalReports)
            })
            .catch((err: any) => {
                console.log('error', err)
            })
    }

    const columns: any = useMemo(
        () =>
            collumnAppender([
                {
                    Header: 'User Name',
                    accessor: 'userName',
                    Cell: (item: any) => {
                        return (
                            <div className="User_Name">
                                <div>{item.cell?.value}</div>
                            </div>
                        )
                    },
                },
                {
                    Header: 'Email',
                    accessor: 'userEmail',
                    Cell: (item: any) => {
                        return (
                            <div className="User_email">
                                <div>{item.cell?.value}</div>
                            </div>
                        )
                    },
                },
                {
                    Header: 'User Contact',
                    accessor: 'userContact',
                    Cell: (item: any) => {
                        return (
                            <div className="Account_Type">
                                <div>{item.cell.value}</div>
                            </div>
                        )
                    },
                },
                {
                    Header: 'Box Name',
                    accessor: 'box',
                    Cell: (item: any) => {
                        return (
                            <div className="User_type">
                                <div>
                                    <img
                                        src={item.cell?.value.icon}
                                        alt={item.cell?.value.icon}
                                        className="boxIcon"
                                    />
                                    {item.cell?.value.name}
                                </div>
                            </div>
                        )
                    },
                },
                {
                    Header: 'Box Id',
                    accessor: 'boxId',
                    Cell: (item: any) => {
                        return (
                            <div className="Created_At">
                                <div>{item.cell?.value}</div>
                            </div>
                        )
                    },
                },
                {
                    Header: 'Checkout Date',
                    accessor: 'userCheckoutTime',
                    Cell: (item: any) => {
                        return (
                            <div className="Created_At">
                                <div>
                                    {moment(item.cell?.value).format(
                                        'DD-MMM-YYYY'
                                    )}
                                </div>
                            </div>
                        )
                    },
                },
            ]),
        [pendingData]
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchPendingContainer()
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [currentPage, pageSize, props.activeUser])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <>
                    <div className="pending-sec userTableSec">
                        <SubHeader
                            HeaderTitle="Pending Containers"
                            isShow={true}
                        >
                            <div className="pendingDiv">
                                <CustomButton
                                    btnName="Download Report"
                                    clsName="pendingBtn"
                                    btnEvt={() => downloadPdf()}
                                />
                            </div>
                        </SubHeader>
                        <div className="container-fluid"></div>
                        <div className="User_table">
                            <CustomTable
                                msg="No pending container found"
                                columns={columns}
                                data={pendingData.length > 0 ? pendingData : []}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="pagination_section">
                                <div className="table__pagination__container pagination_table">
                                    {showPagination(total, pageSize) && (
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={pageSize}
                                            totalItemsCount={total}
                                            onChange={(page: any) => {
                                                setCurrentPage(page)
                                            }}
                                        />
                                    )}
                                    {showPageSize(total) && (
                                        <select
                                            className="pagination__select"
                                            value={pageSize}
                                            onChange={handlePageSizeChange}
                                        >
                                            {[10, 20, 30].map((size) => (
                                                <option key={size} value={size}>
                                                    Show {size}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    <div className="perPageItem">
                                        <p>
                                            {paginationHandler(
                                                currentPage,
                                                pageSize,
                                                total
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default PendingContainer
