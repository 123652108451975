import React, { useEffect, useRef, useState } from 'react'
import './warehouse.style.scss'
import { Form } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import SearchLocationInput from '../../../utils/SearchLocation/SearchLocationInput'
import CustomButton from '../../../utils/CustomButton/CustomButton'
interface WrhProps {
    wrhEditDetails?: any
    setWrhEditDetails?: any
    show?: boolean
    setShow: any
    Evt?: any
    locationObj?: any
    setLocationObj?: any
    locationRef?: any
    isFlagUpdate?: any
    setIsFlagUpdate?: any
    flagBtnDisable?: any
}

const Warehousemodel: React.FC<WrhProps> = ({
    wrhEditDetails,
    setWrhEditDetails,
    show,
    setShow,
    Evt,
    locationObj,
    setLocationObj,
    locationRef,
    isFlagUpdate,
    setIsFlagUpdate,
    flagBtnDisable,
}) => {
    return (
        <>
            <Offcanvas
                show={show}
                onHide={() => setShow(false)}
                placement="end"
            >
                {' '}
                <Offcanvas.Header>
                    <Offcanvas.Title className="wrh__title">
                        {wrhEditDetails.isUpdate
                            ? `Edit (${sessionStorage.getItem('WR_TITLE')})`
                            : `Add(${sessionStorage.getItem('WR_TITLE')})`}
                    </Offcanvas.Title>
                    <CustomButton
                        clsName={`wrh__model me-2 ${
                            flagBtnDisable && `disabledContent`
                        }`}
                        btnEvt={() => !flagBtnDisable && Evt()}
                        btnName={
                            flagBtnDisable
                                ? 'Submiting'
                                : wrhEditDetails.isUpdate
                                ? `Update`
                                : `Add`
                        }
                    />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form.Label htmlFor="email">Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="email"
                        value={wrhEditDetails.name}
                        onChange={(e) =>
                            setWrhEditDetails({
                                ...wrhEditDetails,
                                name: e.target.value,
                            })
                        }
                        disabled={wrhEditDetails.isUpdate}
                    />
                    <Form.Label htmlFor="inputPassword5">Address</Form.Label>
                    <SearchLocationInput
                        existingAddress={wrhEditDetails.formatedAddres}
                        locationRef={locationRef}
                        locationObj={locationObj}
                        setLocationObj={setLocationObj}
                        isFlagUpdate={isFlagUpdate}
                        setIsFlagUpdate={setIsFlagUpdate}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default Warehousemodel
