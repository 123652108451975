import React, { useEffect, useMemo, useState } from 'react'
import './program.style.scss'
import CustomButton from '../../../utils/CustomButton/CustomButton'
import CustomTable from '../../../utils/CustomTable/CustomTable'
import { HttpCallGet, HttpCallPost } from '../../../utils/Services/NetworkCall'
import Pagination from 'react-js-pagination'
import ProgramSliderOne from './Sliders/SliderOne/ProgramSliderOne'
import Loader from '../../../utils/Loader/Loader'
import { ToastOnFailure, ToastOnSuccess } from '../../../utils/Toast/Toast'
import { ConfirmDeleteWithAction } from '../../../utils/ConfirmAlerts'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../../utils/util'
import config from '../../../configs/config'
interface Iprops {
    activeUser: any
}

const Program: React.FC<Iprops> = ({ activeUser }) => {
    const [total, setTotal] = useState<number>(0)

    const [currentPage, setCurrentPage] = useState<any>(1)

    const [pageSize, setPageSize] = useState<number>(10)

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    const [tableData, setTableData] = useState<any>([])

    const [loading, setLoading] = useState<boolean>(false)

    const [firstSlider, setFirstSlider] = useState<boolean>(false)

    const [titleShow, setTitleShow] = useState('')

    const [isFlag, setIsFlag] = useState(false)

    const [programInfo, setProgramInfo] = useState<any>({
        type: '',
        title: '',
        description: '',
        rewardPoints: '',
        donatePercent: '',
        maxDonate: '',
        image: '',
        benefitList: [],
    })

    const [restaurantList, setRestaurantList] = useState<any>([])

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
                Cell: (item: any) => {
                    return <div>{item.cell?.value}</div>
                },
            },
            {
                Header: 'description',
                accessor: 'description',
                Cell: (item: any) => {
                    return <div>{item.cell?.value}</div>
                },
            },
            {
                Header: 'Program Points',
                accessor: 'rewardPoints',
                Cell: (item: any) => {
                    return <div>{item.cell.value}</div>
                },
            },
            {
                Header: 'Enable Donation',
                accessor: 'isDonationEnabled',
                Cell: (item: any) => {
                    return <div>{item.cell?.value ? 'Yes' : 'No'}</div>
                },
            },
            {
                Header: 'status',
                accessor: 'status',
                Cell: (item: any) => {
                    return <div>{statusHandler(item.value)}</div>
                },
            },
            {
                Header: 'Sponsored by',
                accessor: 'type',
                Cell: (item: any) => {
                    return (
                        <div>
                            {item.value == 1
                                ? config.venderTitle
                                : 'Service Provider'}
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'button',
                Cell: (item: any) => {
                    return (
                        <div>
                            {btnGenerator(
                                item.cell.row.original.status,
                                item.cell.row.original
                            )}
                        </div>
                    )
                },
            },
        ],
        []
    )

    const updateTableHandler = (data: any, action: string) => {
        HttpCallPost(
            `admin/updRewardStatus`,
            {
                action: action,
                id: data._id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                if (currentPage === 1) {
                    fetchRewardDetails()
                }
                setCurrentPage(1)
                ToastOnSuccess(res.data.message)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    const btnGenerator = (statusNum: number, d: any) => {
        if (statusNum === 1) {
            return (
                <>
                    <CustomButton
                        clsName="ms-2 mb-2"
                        btnName="Edit"
                        btnEvt={() => {
                            updateStatus(true, d, 'Edit Program')
                        }}
                    />
                    <CustomButton
                        clsName="ms-2 mb-2"
                        btnName="Active"
                        btnEvt={() =>
                            ConfirmDeleteWithAction(
                                d,
                                'Activate',
                                'Are you sure, you want to activate it?',
                                'Activate',
                                updateTableHandler
                            )
                        }
                    />
                    <CustomButton
                        clsName="ms-2 mb-2"
                        btnName="Delete"
                        btnEvt={() =>
                            ConfirmDeleteWithAction(
                                d,
                                'Delete',
                                'Are you sure, you want to delete it?',
                                'Delete',
                                updateTableHandler
                            )
                        }
                    />
                </>
            )
        } else if (statusNum == 2) {
            return (
                <>
                    <CustomButton
                        clsName="ms-2 mb-2"
                        btnName="View"
                        btnEvt={() => {
                            updateStatus(true, d, 'View Program')
                        }}
                    />
                    <CustomButton
                        clsName="ms-2 mb-2"
                        btnName="Disable"
                        btnEvt={() =>
                            ConfirmDeleteWithAction(
                                d,
                                'Disable',
                                'Are you sure, you want to disable it?',
                                'Disable',
                                updateTableHandler
                            )
                        }
                    />
                </>
            )
        } else {
            return (
                <CustomButton
                    clsName="ms-2 mb-2"
                    btnName="View"
                    btnEvt={() => {
                        updateStatus(true, d, 'View Program')
                    }}
                />
            )
        }
    }

    const statusHandler = (statusNum: number) => {
        return statusNum == 1
            ? 'New'
            : statusNum == 2
            ? 'Activated'
            : 'Disabled'
    }

    const fetchRewardDetails = () => {
        setLoading(true)
        HttpCallGet(
            `admin/getRewardList?currentPage=${currentPage}&locationId=${_id}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setTableData(res.data.data ? res.data.data : [])
                setTotal(res.data.totalRecords)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const fetchProgramInfo = () => {
        setLoading(true)
        HttpCallGet(
            `admin-reward/details?_id=${sessionStorage.getItem('activeId')}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setProgramInfo(res.data.data[0])
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const fetchRestaurantsList = () => {
        HttpCallGet(
            `admin/getVendorsToAddReward?currentPage=1&pageSize=1000&locationId=${_id}`, // for now we're making 1000 as hardcoded just because earlier website has 1000 limit
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setRestaurantList(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const updateStatusHandler = (Mode: string, id: any) => {
        return Mode == 'Edit Program' || Mode == 'View Program'
            ? sessionStorage.setItem('activeId', id)
            : sessionStorage.removeItem('activeId')
    }
    const updateStatus = (Slider: boolean, data: any, title: string) => {
        updateStatusHandler(title, data._id)
        if (title !== 'Add Program') fetchProgramInfo()
        fetchRestaurantsList()
        setFirstSlider(Slider)
        setProgramInfo({
            type: '',
            title: '',
            description: '',
            rewardPoints: '',
            donatePercent: '',
            maxDonate: '',
            image: '',
            charities: '',
            benefitList: [],
        })
        setTitleShow(title)
    }

    useEffect(() => {
        fetchRewardDetails()
    }, [pageSize, currentPage, isFlag, activeUser])

    return (
        <div className="Program">
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <>
                    <div className="Program__header Inventory__header wrapper-md">
                        <p className="userTitle">
                            Programs ({sessionStorage.getItem('WR_TITLE')})
                        </p>
                    </div>
                    <div className="programeHeaderBtn">
                        <CustomButton
                            btnEvt={() =>
                                updateStatus(
                                    true,
                                    {
                                        title: '',
                                        rewardPoints: '',
                                        description: '',
                                    },
                                    'Add Program'
                                )
                            }
                            btnName="Add Program"
                        />
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="customizeProgramTable">
                                    <CustomTable
                                        columns={columns}
                                        data={tableData || []}
                                        msg="Programs not available."
                                    />
                                </div>
                                {/* <div className="table__pagination__container">
                                    {total > pageSize && (
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={pageSize}
                                            totalItemsCount={total}
                                            onChange={(page: any) => {
                                                setCurrentPage(page)
                                            }}
                                        />
                                    )}
                                    {tableData.length > 0 && (
                                        <select
                                            className="pagination__select"
                                            value={pageSize}
                                            onChange={handlePageSizeChange}
                                        >
                                            {[10, 20, 30].map((size) => (
                                                <option key={size} value={size}>
                                                    Show {size}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div> */}
                                <div className="table__pagination__container tablePagination">
                                    <div className="itemPagination">
                                        {showPagination(total, pageSize) && (
                                            <Pagination
                                                activePage={currentPage}
                                                itemsCountPerPage={pageSize}
                                                totalItemsCount={total}
                                                onChange={(page: any) => {
                                                    setCurrentPage(page)
                                                }}
                                            />
                                        )}
                                        {showPageSize(total) && (
                                            <select
                                                className="pagination__select"
                                                value={pageSize}
                                                onChange={handlePageSizeChange}
                                            >
                                                {[10, 20, 30].map((size) => (
                                                    <option
                                                        key={size}
                                                        value={size}
                                                    >
                                                        Show {size}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                    <div className="perPageItem">
                                        <p>
                                            {paginationHandler(
                                                currentPage,
                                                pageSize,
                                                total
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ProgramSliderOne
                        show={firstSlider}
                        setShow={setFirstSlider}
                        activeTitle={`${titleShow}`}
                        programInfo={programInfo}
                        setProgramInfo={setProgramInfo}
                        restaurantList={restaurantList}
                        setRestaurantList={setRestaurantList}
                        loading={loading}
                        setLoading={setLoading}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSize={pageSize}
                        isFlag={isFlag}
                        setIsFlag={setIsFlag}
                    />
                </>
            )}
        </div>
    )
}

export default Program
