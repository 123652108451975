import React, { useState } from 'react'
import './CustomTooltip.scss'

interface CustomTooltipProps {
    content: string
    children: React.ReactNode
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ content, children }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false)

    const handleMouseEnter = () => {
        setTooltipVisible(true)
    }

    const handleMouseLeave = () => {
        setTooltipVisible(false)
    }

    return (
        <div className="custom-tooltip-container">
            <div
                className={`custom-tooltip ${
                    isTooltipVisible ? 'visible' : ''
                }`}
            >
                {content}
            </div>
            <div
                className="custom-tooltip-trigger"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
        </div>
    )
}

export default CustomTooltip
