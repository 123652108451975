import React from 'react'
import '../subHeader/subheader.scss'

interface MyComponentProps {
    HeaderTitle: string
    isShow?: boolean
    children?: any
}

const SubHeader: React.FC<MyComponentProps> = ({
    HeaderTitle,
    isShow = true,
    children,
}) => {
    //  default function SubHeader(props: string) {
    return (
        <div className="headerDiv wrapper-md">
            <div className={`Inventory__header`}>
                <p className="userTitle">
                    {HeaderTitle}{' '}
                    {isShow && `(${sessionStorage.getItem('WR_TITLE')})`}
                </p>
                {children && children}
            </div>
        </div>
    )
}
export default SubHeader
