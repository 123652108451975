import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import CustomButton from '../../utils/CustomButton/CustomButton'
import CustomDropdown from '../../utils/CustomDropdown/CustomDropdown'
import CustomAccordian from '../../utils/CustomAccordian/CustomAccordian'
import './inventory.style.scss'
import CustomSlider from '../../utils/CustomSlider/CustomSlider'
import {
    HttpCallGet,
    HttpCallPATCH,
    HttpCallPost,
} from '../../utils/Services/NetworkCall'
import CustomInput from '../../utils/CustomInput/CustomInput'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDatePicker from 'react-datepicker'
import axios from 'axios'
import {
    formateDate,
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast'
import {
    ADDWEB_INVENTORY,
    CONTAINER_TYPE_CHANGE,
} from '../../utils/Services/EndPoints'
import Search from './searchbox/Search'
import {
    activeHandler,
    addNoneHandler,
    AddSpaceInWord,
    checkAsignHandler,
    containerStatusHandler,
    fiilterBulkHander,
    filterBulkContainerHandler,
    modifyCartonHandler,
    modifyContainerHandler,
    modifyWrhHandler,
    modifyWrhLocationHandler,
    objectHanlder,
    tableDataHander,
    valueHandler,
} from './methodHandler'
import { containerStatus, inventoryDetails } from './mockInvenData'
import moment from 'moment'
import CustomTable from '../../utils/CustomTable/CustomTable'
import Pagination from 'react-js-pagination'
import Loader from '../../utils/Loader/Loader'
import { ConfirmDeleteWithAction } from '../../utils/ConfirmAlerts'
import SubHeader from '../../component/subHeader/SubHeader'

function Inventory(props: any) {
    const [show, setShow] = useState(false)

    const [showSecondSlider, setShowSecondSlider] = useState(false)

    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState<any>(1)

    const [pageSize, setPageSize] = useState(10)

    const [wrhActiveTitle, setWrhActiveTitle] = useState<any>('')

    const [containerActiveTitle, setContainerActiveTitle] = useState<any>('')

    const [wareHouseDropDown, setWareHouseDropDown] = useState<any>([])

    const [containerDropDown, setContainerDropDown] = useState([])

    const [fromRange, setFromRange] = useState('')

    const [toRange, setToRange] = useState('')

    const [aStatusActive, setAsStatusActive] = useState<any>()

    const [locationList, setLocationList] = useState<any>({})

    const [containerActiveType, setContainerActiveType] = useState<any>()

    const [containerActiveStatus, setContainerActiveStatus] = useState<any>()

    const [activeDate, setActiveDate] = useState<any>()

    const [inventoryDate, setInventoryDate] = useState<any>()

    const [wrhDropLocation, setWrhDropLocation] = useState([])

    const [wrhDropActiveLocation, setWrhDropActiveLocation] = useState<any>([])

    const [wrhDropActiveDesk, setWrhDropActiveDesk] = useState<any>({})

    const [wrhCortonDetails, setWrhCortonDetails] = useState([])

    const [wrhSearchDataHolder, setWrhSearchDataHolder] = useState([])

    const [wrhSearchDetails, setWrhSearchDetails] = useState<any>('')

    const [searchField, setSearchFiled] = useState('')

    const [tableData, setTableData] = useState<any>([])

    const [flag, setFlag] = useState(true)

    const [initialDataReceived, setInitialDataReceived] = useState(false)

    const [bulkContainActiveTitle, setBulkContainActiveTitle] = useState<any>(
        []
    )

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    const [total, setTotal] = useState(0)

    const getWrhDetail = () => {
        setLoading(true)
        HttpCallGet(
            `admin-warehouse/list?currentPage=1&locationId=${_id}&pageSize=1000`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setWareHouseDropDown(res.data.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const getContainer = () => {
        HttpCallGet(
            `admin/boxes-type?currentPage=1&pageSize=1000`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setContainerDropDown(addNoneHandler(res.data.data))
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const fetchLocationDetails = () => {
        HttpCallGet(
            `admin/getLocationInventoryDetails?_id=${_id}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLocationList(res.data.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const fetchAllDetails = ({ isWrhDropActiveLocation }: any) => {
        setLoading(true)

        // if (isSearchOrFilterActive) setCurrentPage(1)
        const parameters: any = {
            assignTypes: checkAsignHandler(aStatusActive?.label),
            currentPage: currentPage,
            locationId: !objectHanlder(wrhDropActiveDesk) ? _id : undefined,
            containerTypeId: containerActiveType?._id,
            pageSize: pageSize,
            containerStatus: containerStatusHandler(
                containerActiveStatus?.value
            ),
            assignDate: valueHandler(formateDate(activeDate)),
            inventoryDate: valueHandler(formateDate(inventoryDate)),
            search: searchField,
            cartonId: wrhDropActiveLocation?._id,
        }
        if (!isWrhDropActiveLocation || !wrhDropActiveLocation) {
            parameters.warehouseId = wrhDropActiveDesk?._id
        }
        if (wrhDropActiveDesk?.label === 'None') {
            parameters.locationId = _id
        }

        setFlag(true)
        if (
            parameters.locationId ||
            parameters.warehouseId ||
            parameters.containerTypeId ||
            parameters.cartonId ||
            parameters.assignTypes ||
            parameters.containerStatus ||
            parameters.assignDate ||
            parameters.inventoryDate ||
            parameters.search
        ) {
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}admin/getLocationInventory`,
                    {
                        headers: {
                            Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                        },
                        params: parameters,
                    }
                )
                .then((res: any) => {
                    setLoading(false)
                    setTotal(res.data.totalRecords)
                    setWrhSearchDataHolder([])
                    setTableData(tableDataHander(res.data.data))
                })
                .catch((err: any) => {
                    setLoading(false)
                    console.log('error', err)
                })
        }
    }

    const addBox = () => {
        if (!containerActiveTitle._id) {
            ToastOnFailure('Please specify type of BOX')
            return
        }

        if (fromRange.length === 0) {
            ToastOnFailure('Please enter From QR')
            return
        }
        if (toRange.length === 0) {
            ToastOnFailure('Please enter To QR')
            return
        }

        setLoading(true)
        HttpCallPost(
            ADDWEB_INVENTORY,
            {
                boxInfo: [
                    {
                        typeId: containerActiveTitle._id,
                        fromQr: fromRange,
                        toQr: toRange,
                    },
                ],
                locationId: _id,
                warehouseId: wrhActiveTitle._id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setShow(false)
                setLoading(false)

                ToastOnSuccess(res.data.message)
                // fetchWrhItems()
            })
            .catch((err) => {
                setLoading(false)

                ToastOnFailure(err.message)
            })
    }

    const fetchwrhFirstLocationDetails = () => {
        HttpCallGet(
            `admin/getLocationInventoryByWarehouse?_id=${_id}&currentPage=1&pageSize=1000`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setWrhSearchDataHolder(addNoneHandler(res.data.data))
                setWrhDropLocation(addNoneHandler(res.data.data))
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const fetchwrhCartonDetails = () => {
        if (wrhDropActiveDesk?._id) {
            HttpCallGet(
                `admin/getCartonInventoryByWarehouseId?_id=${wrhDropActiveDesk?._id}&currentPage=1&pageSize=1000`,
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setLoading(false)
                    setWrhCortonDetails(addNoneHandler(res.data.data))
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err.message)
                })
        }
    }

    const fetchwrhSecondLocationDetails = () => {
        if (wrhDropActiveDesk?._id) {
            HttpCallGet(
                `admin/getWarehouseInventoryById?_id=${wrhDropActiveDesk?._id}`,
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setWrhSearchDetails(res.data.data)
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err.message)
                })
        }
    }

    const inventoryExport = () => {
        const parameters: any = {
            assignTypes: wrhDropActiveLocation?._id
                ? undefined
                : checkAsignHandler(aStatusActive?.label),
            currentPage: currentPage,
            locationId: wrhDropActiveDesk?._id ? undefined : _id,
            containerTypeId: containerActiveType?._id,
            pageSize: pageSize,
            containerStatus: containerStatusHandler(
                containerActiveStatus?.value
            ),
            assignDate: valueHandler(formateDate(activeDate)),
            inventoryDate: valueHandler(formateDate(inventoryDate)),
            search: searchField,
            cartonId: wrhDropActiveLocation?._id,
            warehouseId: wrhDropActiveLocation?._id
                ? undefined
                : wrhDropActiveDesk?._id,
        }
        axios
            .get(`${process.env.REACT_APP_BASE_URL}admin/export/inventory`, {
                headers: {
                    Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                },
                params: parameters,
            })
            .then((res: any) => {
                if (res.data.url.length > 0) {
                    window.open(res.data.url)
                    setLoading(false)
                } else {
                    ToastOnFailure('No data found')
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const changeInvContainerTypeInfo = () => {
        if (!bulkContainActiveTitle._id) {
            ToastOnFailure('Please select container type')
            return
        }
        setLoading(true)
        HttpCallPost(
            CONTAINER_TYPE_CHANGE,
            {
                boxInfo: filterBulkContainerHandler(tableData),
                boxTypeId: bulkContainActiveTitle._id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setShowSecondSlider(false)
                setLoading(false)
                ToastOnSuccess(res.data.message)
            })
            .catch((err) => {
                setLoading(false)
                ToastOnFailure(err.message)
            })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchAllDetails({})
            setInitialDataReceived(true)
        }, 400)
        return () => {
            clearTimeout(timer)
        }
    }, [
        activeDate,
        inventoryDate,
        aStatusActive?.label,
        currentPage,
        pageSize,
        containerActiveStatus?.value,
        containerActiveType?._id,
        wrhDropActiveDesk,
        searchField,
        props.activeUser,
    ])

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const inventoryContainerTypeInfo = () => {
        if (filterBulkContainerHandler(tableData).length == 0) {
            ToastOnFailure('Please select inventry')
            return
        }
        setShowSecondSlider(true)
    }

    const columns: any = useMemo(
        () => [
            {
                Header: (
                    <input
                        type="checkbox"
                        onClick={(e: any) => handlerSelectAllCheck(e)}
                        className="checkBoxTable"
                        onChange={() => console.log('')}
                    />
                ),
                accessor: 'checkbox',
                Cell: (item: any) => {
                    return (
                        <input
                            className="checkBoxTable"
                            type="checkbox"
                            onChange={() => console.log('')}
                            checked={item?.cell?.value?.isChecked}
                            onClick={() => handleInputClick(item)}
                        />
                    )
                },
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            <img
                                className="Inventory__typeTableImg"
                                src={item.cell?.value?.itemIcon}
                            />
                            <div>{item.cell?.value?.itemType}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Container ID',
                accessor: 'boxId',
            },
            {
                Header: 'Assign Status',
                accessor: 'assignStatus',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__asTabTableImg">
                            <img
                                className="Inventory__asTableImg"
                                src={item.cell.value.icon}
                            />
                            <div>{item.value.type}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Assign Date',
                accessor: 'createdAt',
                Cell: (item: any) => {
                    return <>{moment(item.cell.value).format('DD-MMM-YYYY')}</>
                },
            },
            {
                Header: 'Added in Inventory',
                accessor: 'updatedAt',
                Cell: (item: any) => {
                    return <>{moment(item.cell.value).format('DD-MMM-YYYY')}</>
                },
            },
            {
                Header: 'Container Status',
                accessor: 'boxStatus',
                Cell: (item: any) => {
                    return (
                        <div>
                            {item.cell.value == 1
                                ? `Active`
                                : item.cell.value == 2
                                ? ` Damage`
                                : `Lost`}
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'contain',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__buttonTable">
                            {item.cell.row.original.boxStatus == 1 && (
                                <>
                                    <CustomButton
                                        btnName="Mark as Lost"
                                        btnEvt={() =>
                                            ConfirmDeleteWithAction(
                                                item.cell.row.original._id,
                                                'LOST',
                                                'Are you sure , you want to mark as Lost',
                                                'Lost',
                                                disableAndLostHandler
                                            )
                                        }
                                    />
                                    <CustomButton
                                        btnName="Mark as Damage"
                                        btnEvt={() =>
                                            ConfirmDeleteWithAction(
                                                item.cell.row.original._id,
                                                'DAMAGE',
                                                'Are you sure , you want to mark as Damage',
                                                'Damage',
                                                disableAndLostHandler
                                            )
                                        }
                                    />
                                </>
                            )}
                        </div>
                    )
                },
            },
        ],
        [tableData.checkbox]
    )

    const disableAndLostHandler = (id: any, actionType: any) => {
        HttpCallPATCH(
            `admin/container/damage-lost`,
            {
                _id: id,
                action: actionType,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                fetchAllDetails({})
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    const handleInputClick = (data: any) => {
        const originalId = data.cell.row.original.boxId
        setTableData((item: any) => {
            const updatedItems = item.map((item: any) => {
                if (item.boxId === originalId) {
                    return {
                        ...item,
                        checkbox: {
                            boxId: item.boxId,
                            isChecked: !item.checkbox.isChecked,
                        },
                    }
                } else {
                    return item
                }
            })
            return updatedItems
        })
    }

    const handlerSelectAllCheck = (e: any) => {
        setTableData((item: any) => {
            const updatedItems = item.map((item: any) => {
                return {
                    ...item,
                    checkbox: {
                        boxId: item?.boxId,
                        isChecked: e.target.checked ? true : false,
                    },
                }
            })
            return updatedItems
        })
    }

    useEffect(() => {
        fetchwrhCartonDetails()
        fetchwrhSecondLocationDetails()
    }, [wrhDropActiveDesk, props.activeUser])

    useEffect(() => {
        getContainer()
        fetchLocationDetails()
        fetchwrhFirstLocationDetails()
    }, [_id, props.activeUser])

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchAllDetails({})
        }, 400)
        return () => {
            clearTimeout(timer)
        }
    }, [
        activeDate,
        inventoryDate,
        aStatusActive?.label,
        currentPage,
        pageSize,
        containerActiveStatus?.value,
        containerActiveType?._id,
        wrhDropActiveDesk,
        searchField,
        props.activeUser,
    ])

    useEffect(() => {
        fetchAllDetails({ isWrhDropActiveLocation: true })
    }, [wrhDropActiveLocation])

    // wrhDropActiveLocation
    useEffect(() => {
        setFromRange('')
        setContainerActiveStatus('')
        setToRange('')
        setWrhActiveTitle([])
        setContainerActiveTitle([])
        setWareHouseDropDown([])
        setFromRange('')
    }, [!show])

    useEffect(() => {
        setCurrentPage(1)
    }, [
        aStatusActive,
        containerActiveType,
        containerActiveStatus,
        activeDate,
        inventoryDate,
        wrhDropActiveDesk,
        searchField,
        props.activeUser,
    ])

    useEffect(() => {
        setWrhDropActiveLocation({ label: 'None', value: '0' })
    }, [wrhDropActiveDesk, props.activeUser])

    useEffect(() => {
        setActiveDate('')
        setAsStatusActive('')
        setContainerActiveType('')
        setContainerActiveStatus('')
        setInventoryDate('')
        setWrhDropActiveDesk('')
        setWrhDropActiveLocation('')
    }, [props.activeUser])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="Inventory">
                    <SubHeader HeaderTitle="Inventory" />
                    <div className="Inventory__accHolder">
                        <CustomAccordian
                            HeaderParam="Location "
                            HeaderName={` : Location (${sessionStorage.getItem(
                                'WR_TITLE'
                            )})`}
                            key="1"
                            headerFlagFirst={aStatusActive}
                            headerFirstFlagName="Assign Status"
                            headerSecondFlagName="Container"
                            headerFlagSecond={containerActiveType}
                            showFilterIcon={true}
                        >
                            <div className="Inventory__row row ">
                                <div className="Inventory__firstCol col-md-4 col-12">
                                    <div className="Inventory__dDown row">
                                        <div className="Inventory__dbDown col-md-6 col-12">
                                            <CustomDropdown
                                                name="Asign Status"
                                                data={inventoryDetails}
                                                activeDrop={aStatusActive}
                                                setActiveDrop={
                                                    setAsStatusActive
                                                }
                                            />
                                        </div>
                                        {/* <div className="demo"> */}
                                        <div className="col-md-6 Inventory__dbDown ContainerTypeClass col-12">
                                            <CustomDropdown
                                                name="Containers Type"
                                                data={modifyContainerHandler(
                                                    containerDropDown
                                                )}
                                                activeDrop={containerActiveType}
                                                setActiveDrop={
                                                    setContainerActiveType
                                                }
                                                menuClassName="inventryMenu"
                                                toggleClassName="containerTypeToggle"
                                            />
                                        </div>
                                        {/* </div> */}
                                    </div>

                                    <div className="Inventory__dDown-second row">
                                        <div className="col-md-6 Inventory__dbDown col-12">
                                            <CustomDropdown
                                                name="Container Status"
                                                data={containerStatus}
                                                activeDrop={
                                                    containerActiveStatus
                                                }
                                                setActiveDrop={
                                                    setContainerActiveStatus
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6 Inventory__dbDown col-12">
                                            <p className="Inventory__paraHeaderFirst">
                                                Assign Date
                                            </p>
                                            <ReactDatePicker
                                                name="startDateTime"
                                                className="month"
                                                selected={activeDate}
                                                onChange={(date: any) => {
                                                    setActiveDate(date)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="Inventory__dDown-third row">
                                        <div className="col-md-6 Inventory__dbDown col-12">
                                            <p className="Inventory__paraHeaderSec">
                                                Inventory Date
                                            </p>
                                            <ReactDatePicker
                                                name="inventoryDateTime"
                                                className="month"
                                                selected={inventoryDate}
                                                onChange={(date: any) => {
                                                    setInventoryDate(date)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 col-12"></div>
                                    </div>
                                </div>
                                <div className="Inventory__secondCol col-md-5 col-12">
                                    <table>
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <td>Original Inventory</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.TotalInventory
                                                            ? locationList[0]
                                                                  .TotalInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Current Inventory</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.currentInventory
                                                            ? locationList[0]
                                                                  .currentInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Warehouse Inventory</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.warehouseInventory
                                                            ? locationList[0]
                                                                  .warehouseInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Carton Inventory</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.cartonInventory
                                                            ? locationList[0]
                                                                  .cartonInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Drop Station</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.dropStationInventory
                                                            ? locationList[0]
                                                                  .dropStationInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Users</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.userInventory
                                                            ? locationList[0]
                                                                  .userInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Vendors</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.vendorInventory
                                                            ? locationList[0]
                                                                  .vendorInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Damage</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.damageInventory
                                                            ? locationList[0]
                                                                  .damageInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Lost</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.lostInventory
                                                            ? locationList[0]
                                                                  .lostInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Out Location</td>
                                                <td>
                                                    {Object.keys(locationList)
                                                        .length > 0
                                                        ? locationList[0]
                                                              ?.outLocationInventory
                                                            ? locationList[0]
                                                                  .outLocationInventory
                                                            : 0
                                                        : ''}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="Inventory__secondCol col-md-2">
                                    <div className="row">
                                        <div className="col-5 col-md-3  first-container"></div>
                                        <div className="col-12 col-md-12 text-end p-0 m-0">
                                            <CustomButton
                                                clsName="ms-2 mt-2 mt-md-0"
                                                btnName="Add Inventory"
                                                btnEvt={() => {
                                                    getWrhDetail()
                                                    setShow(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CustomAccordian>

                        <CustomAccordian
                            HeaderParam="Warehouse "
                            HeaderName={` : Warehouse (${sessionStorage.getItem(
                                'WR_TITLE'
                            )})`}
                            key="2"
                            headerFlagFirst={wrhDropActiveDesk}
                            headerFirstFlagName="Warehouse"
                            headerSecondFlagName="Carton"
                            headerFlagSecond={
                                activeHandler(wrhDropActiveDesk)
                                    ? {}
                                    : wrhDropActiveLocation
                            }
                            showFilterIcon={true}
                        >
                            <div className="Inventory__row row">
                                <div className="Inventory__firstCol inventoryCommon col-md-4 col-12">
                                    <div className="Inventory__dDown row">
                                        <div className="Inventory__dbDown col-md-6 col-12">
                                            <CustomDropdown
                                                name="Warehouse"
                                                data={modifyWrhLocationHandler(
                                                    wrhDropLocation
                                                )}
                                                activeDrop={wrhDropActiveDesk}
                                                setActiveDrop={
                                                    setWrhDropActiveDesk
                                                }
                                                isSearchAble={true}
                                                SearchingData={modifyWrhLocationHandler(
                                                    wrhSearchDataHolder
                                                )}
                                            >
                                                <Search
                                                    inputType="text"
                                                    placeHold="Search"
                                                    data={modifyWrhLocationHandler(
                                                        wrhDropLocation
                                                    )}
                                                    setData={
                                                        setWrhSearchDataHolder
                                                    }
                                                />
                                            </CustomDropdown>
                                        </div>
                                        {objectHanlder(wrhDropActiveDesk) &&
                                            !activeHandler(
                                                wrhDropActiveDesk
                                            ) && (
                                                <div className="col-md-6 Inventory__dbDown col-12">
                                                    <CustomDropdown
                                                        name="Carton"
                                                        data={modifyCartonHandler(
                                                            wrhCortonDetails
                                                        )}
                                                        activeDrop={
                                                            wrhDropActiveLocation
                                                        }
                                                        menuClassName="dropInventory"
                                                        setActiveDrop={
                                                            setWrhDropActiveLocation
                                                        }
                                                    ></CustomDropdown>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="Inventory__secondCol col-md-6 col-12">
                                    <table>
                                        <tbody>
                                            {wrhSearchDetails &&
                                                wrhDropActiveDesk?._id &&
                                                Object.keys(
                                                    wrhSearchDetails[0]
                                                ).map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {index != 0 &&
                                                                index != 1 && (
                                                                    <>
                                                                        <td>
                                                                            {AddSpaceInWord(
                                                                                item
                                                                            )}
                                                                        </td>{' '}
                                                                        <td>
                                                                            {
                                                                                wrhSearchDetails[0][
                                                                                    item
                                                                                ]
                                                                            }
                                                                        </td>
                                                                    </>
                                                                )}
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CustomAccordian>
                    </div>

                    <div className="Inventory__searchSection">
                        <div className="row">
                            <div className="col-md-5 col-12">
                                <Form.Control
                                    type="text"
                                    id="email"
                                    placeholder="Search"
                                    value={searchField}
                                    onChange={(e) =>
                                        setSearchFiled(e.target.value)
                                    }
                                />
                            </div>
                            <div className="col-md-7 col-12 text-end  text-end">
                                <CustomButton
                                    clsName="ms-2 mt-2 mt-md-0"
                                    btnName="Bulk Change Container Type"
                                    btnEvt={() => inventoryContainerTypeInfo()}
                                />
                                <CustomButton
                                    clsName="ms-2 mt-2 mt-md-0"
                                    btnName="Export"
                                    btnEvt={() => inventoryExport()}
                                />
                            </div>
                        </div>
                    </div>

                    <CustomSlider
                        activeTitle={`Add (${sessionStorage.getItem(
                            'WR_TITLE'
                        )})`}
                        show={show}
                        setShow={setShow}
                        Evt={() => addBox()}
                        btnEventName={'Add'}
                    >
                        <CustomDropdown
                            data={modifyWrhHandler(wareHouseDropDown)}
                            name="WareHouse"
                            activeDrop={wrhActiveTitle}
                            setActiveDrop={setWrhActiveTitle}
                        />

                        <CustomDropdown
                            data={modifyContainerHandler(containerDropDown)}
                            name="Container"
                            activeDrop={containerActiveTitle}
                            setActiveDrop={setContainerActiveTitle}
                        />

                        <div className="Inventory__rangeSec">
                            <CustomInput
                                typeOfField="text"
                                label="QR Range"
                                placeHold="From"
                                state={fromRange}
                                setState={setFromRange}
                            />
                            <CustomInput
                                typeOfField="text"
                                placeHold="To"
                                state={toRange}
                                setState={setToRange}
                            />
                        </div>
                    </CustomSlider>

                    <CustomSlider
                        activeTitle={`Bulk Change Container Type`}
                        show={showSecondSlider}
                        setShow={setShowSecondSlider}
                        Evt={() => changeInvContainerTypeInfo()}
                        btnEventName={'Save'}
                    >
                        <CustomDropdown
                            data={fiilterBulkHander(
                                modifyContainerHandler(containerDropDown)
                            )}
                            name="Container Type"
                            activeDrop={bulkContainActiveTitle}
                            setActiveDrop={setBulkContainActiveTitle}
                        />
                    </CustomSlider>

                    <div className="Inventory__table table-responsive">
                        <CustomTable
                            msg="No inventory found"
                            columns={columns}
                            data={tableData}
                        />

                        {/* <div className="table__pagination__container">
                            {total > pageSize && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {tableData.length > 0 && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div> */}
                        <div className="table__pagination__container tablePagination">
                            <div className="itemPagination">
                                {showPagination(total, pageSize) && (
                                    <Pagination
                                        activePage={currentPage}
                                        itemsCountPerPage={pageSize}
                                        totalItemsCount={total}
                                        onChange={(page: any) => {
                                            setCurrentPage(page)
                                        }}
                                    />
                                )}
                                {showPageSize(total) && (
                                    <select
                                        className="pagination__select"
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                    >
                                        {[10, 20, 30].map((size) => (
                                            <option key={size} value={size}>
                                                Show {size}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="perPageItem page">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Inventory
