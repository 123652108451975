import React from 'react'
import './footer.style.scss'

function Footer() {
    const dateGenerator = () => {
        const d = new Date()
        return d.getFullYear()
    }
    const currentYear = dateGenerator()
    return (
        <>
            <div className="FooterDiv">
                <span>{`Green GrubBox, LLC © ${currentYear}`}</span>
            </div>
        </>
    )
}

export default Footer
