import moment from 'moment'

export function capitalizeFirstLetter(str: any) {
    const str2: any = str.toLowerCase()
    return str2.charAt(0).toUpperCase() + str2.slice(1)
}

export const formateDate = (date: any) => {
    return date ? moment(date).format('YYYY-MM-DD') : undefined
}

export const getCurrentDate = (startDate?: any) => {
    const date = new Date()
    return `${date.getMonth() + 1}/${
        startDate ? startDate : date.getDate()
    }/${date.getFullYear()}`
}

export const convertUnixFormat = (date: any) => {
    return moment(date).valueOf()
}

export const dateFormater = (date: Date) => {
    return moment(date).format('DD-MMM-YYYY h:mm a')
}

export function paginationHandler(
    currentPage: number,
    pageSize: number,
    total: number
) {
    const startItem = (currentPage - 1) * pageSize + 1
    const endItem = Math.min(currentPage * pageSize, total)

    return `${startItem} - ${endItem} of ${total} items`
}

export const showPageSize = (pageSize: number) => {
    return pageSize > 10 && true
}

export const showPagination = (total: number, pageSize: number) => {
    return total >= pageSize
}

export function capitalizeWords(inputString: string) {
    const words = inputString.split(' ')
    const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
            return word
        }
        return word[0].toUpperCase() + word.slice(1).toLowerCase()
    })
    const resultString = capitalizedWords.join(' ')
    return resultString
}
