import React, { useEffect, useMemo, useState } from 'react'
import CustomButton from '../../utils/CustomButton/CustomButton'
import { HttpCallGet } from '../../utils/Services/NetworkCall'
import Loader from '../../utils/Loader/Loader'
import './claims.style.scss'
import moment from 'moment'
import CustomTable from '../../utils/CustomTable/CustomTable'
import Pagination from 'react-js-pagination'
import SubHeader from '../../component/subHeader/SubHeader'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'

function Claims(props: any) {
    const [loading, setLoading] = useState<boolean>(false)
    const [claimsData, setClaimsData] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'Coupon code',
                accessor: 'rewardQr',
                Cell: (item: any) => {
                    return <>{item.cell?.value}</>
                },
            },
            {
                Header: 'Used points',
                accessor: 'earnedPoints',
                Cell: (item: any) => {
                    return (
                        <div className="User_Points">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Rewards title',
                accessor: 'rewardInfo',
                Cell: (item: any) => {
                    return (
                        <div className="Claims_Reward">
                            <div>{item?.cell?.value[0]?.title}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Rewards Type',
                accessor: '',
                Cell: (item: any) => {
                    return (
                        <div className="Claims_Type">
                            <div>
                                {item?.data[0]?.earnedType == 1
                                    ? 'Cash'
                                    : 'Merchandise'}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: 'Rewards Value',
                accessor: 'earnedValue',
                Cell: (item: any) => {
                    return (
                        <div className="Claims_Value">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Vendor',
                accessor: 'vendorDetails',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_City">
                            <div>{item.cell?.value[0].name}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'User',
                accessor: 'userDetails',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_State">
                            <div>{item.cell?.value[0].name}</div>
                        </div>
                    )
                },
            },
            {
                // userdETAILS
                Header: 'Updated On',
                accessor: 'userDetails.name',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_Boxes">
                            <div>
                                {moment(
                                    item?.cell?.row?.original?.updatedAt
                                ).format('DD-MMM-YYYY h:mm a')}
                            </div>
                        </div>
                    )
                },
            },
            // {
            //     Header: 'Number Of Orders',
            //     accessor: 'totalOrders',
            //     Cell: (item: any) => {
            //         return (
            //             <div className="Resturant_Orders">
            //                 <div>{item.cell?.value}</div>
            //             </div>
            //         )
            //     },
            // },
        ],
        []
    )

    const fetchClaimsData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/getListUserClaimedReward?currentPage=${currentPage}&locationId=${_id}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setClaimsData(res.data.data)
                setTotal(res.data.totalRecords)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    useEffect(() => {
        fetchClaimsData()
    }, [currentPage, pageSize, props.activeUser])
    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="Claims">
                    <SubHeader HeaderTitle="Claims" />
                    <div className="Content">
                        <div className="main_section">
                            <CustomTable
                                msg="No claims found"
                                columns={columns}
                                data={claimsData || []}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        {/* <div className="table__pagination__container">
                            {total > pageSize && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {claimsData.length > 0 && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div> */}
                        <div className="table__pagination__container tablePagination">
                            <div className="itemPagination">
                                {showPagination(total, pageSize) && (
                                    <Pagination
                                        activePage={currentPage}
                                        itemsCountPerPage={pageSize}
                                        totalItemsCount={total}
                                        onChange={(page: any) => {
                                            setCurrentPage(page)
                                        }}
                                    />
                                )}
                                {showPageSize(total) && (
                                    <select
                                        className="pagination__select"
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                    >
                                        {[10, 20, 30].map((size) => (
                                            <option key={size} value={size}>
                                                Show {size}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="perPageItem">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Claims
