import React, { useState } from 'react'
import './login.style.scss'
import { useNavigate } from 'react-router-dom'
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast'
import { HttpCallPost } from '../../utils/Services/NetworkCall'
import { LOGIN } from '../../utils/Services/EndPoints'
import Loader from '../../utils/Loader/Loader'
import config from '../../configs/config'

const Login = (props: any) => {
    const { setSigninStatus } = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (email.length == 0) {
            ToastOnFailure('Please enter the email')
            return
        }
        if (password.length == 0) {
            ToastOnFailure('Please enter the password')
            return
        }
        setLoading(true)
        HttpCallPost(LOGIN, {
            email: email,
            password: password,
        })
            .then((response: any) => {
                setLoading(false)
                if (response.data.status == 0) {
                    ToastOnFailure(response.data.message)
                } else {
                    sessionStorage.setItem('AUTH_TOKEN', response.data.token)
                    sessionStorage.setItem(
                        'userDetails',
                        JSON.stringify(response.data.data)
                    )
                    navigate('/dashboard')
                    setSigninStatus(true)
                    ToastOnSuccess(response.data.message)
                }
            })
            .catch((error) => {
                ToastOnFailure(error?.data?.msg)
                setLoading(false)
                console.log(error)
            })
    }
    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="login">
                    <div className="login__background" />
                    <div className="login__content">
                        <form className="login__form" onSubmit={handleSubmit}>
                            <div className="login__form-group">
                                <div className="login__logo">
                                    <img
                                        className="login__bg"
                                        src={config.logoPath}
                                    />
                                </div>
                                <strong className="login__heading">
                                    Sign in to Admin portal
                                </strong>
                                <label className="login__label" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="login__input"
                                    id="email"
                                    type="email"
                                    value={email}
                                    placeholder="Email"
                                    onChange={handleEmailChange}
                                />
                            </div>
                            <div className="login__form-group">
                                <label
                                    className="login__label"
                                    htmlFor="password"
                                >
                                    Password
                                </label>
                                <input
                                    className="login__input"
                                    id="password"
                                    type="password"
                                    value={password}
                                    placeholder="Password"
                                    onChange={handlePasswordChange}
                                />
                            </div>
                            <button className="login__button" type="submit">
                                Login
                            </button>
                            <div className="login__form-group">
                                <a
                                    className="login__forget-password"
                                    onClick={() => navigate('/forget-password')}
                                >
                                    Forgot Password
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default Login
