import React from 'react'
import './NoData.scss'

import Card from 'react-bootstrap/Card'

export default function NoData(props: any) {
    return (
        <>
            <Card className='MainBody'>
                <Card.Body className='body'>
                    <div className="text-center">{props.content}</div>
                </Card.Body>
            </Card>
        </>
    )
}
