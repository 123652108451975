import React from 'react'
import { toast } from 'react-toastify'
import '../../App.css'

//Toaster for Sucessfully Auth
export const ToastOnSuccess = (str: string) => {
    return <div className="TostSuccessMessage">{toast.success(str)}</div>
}
//Toaster for Failure Auth

export const ToastOnFailure = (str: string) => {
    return <div className="TostErrorMessage">{toast.error(str)}</div>
}
