import React, { useEffect, useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import './scrolldropdown.style.scss'

export interface Option {
    label: string
    value: string
}

interface Props {
    name: string
    data: any
    defaultVal?: any
    setDefaultVal?: any
    parrentClassName?: string
    lebelClassName?: string
    toggleClassName?: string
    menuClassName?: string
    itemClassName?: string
    activeDrop?: any
    setActiveDrop?: any
    isSearchAble?: any
    children?: any
    SearchingData?: any
}

const ScrollDropDown: React.FC<Props> = ({
    name,
    data,
    lebelClassName = ``,
    toggleClassName = ``,
    menuClassName = ``,
    itemClassName = ``,
    parrentClassName = ``,
    activeDrop = ``,
    setActiveDrop,
    isSearchAble,
    children,
    SearchingData,
    defaultVal,
}) => {
    const DataHandler = () => {
        return SearchingData?.length > 0 ? SearchingData : data ? data : []
    }

    const labelRefs = useRef<any>({})

    const scrollToLabel = (label: string) => {
        if (labelRefs.current[label]) {
            labelRefs.current[label].scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key) {
                const option = DataHandler().find((item: any) =>
                    item.label.toLowerCase().startsWith(e.key.toLowerCase())
                )
                if (option) {
                    scrollToLabel(option.label)
                }
            }
        }

        document.addEventListener('keypress', handleKeyPress)

        return () => {
            document.removeEventListener('keypress', handleKeyPress)
        }
    }, [setActiveDrop])

    return (
        <div className={`CustomDropdown ${parrentClassName}`}>
            <p className={`CustomDropdown__header ${lebelClassName}`}>{name}</p>
            <Dropdown>
                <Dropdown.Toggle
                    className={`CustomDropdown__toggle ${toggleClassName}`}
                    variant="primary"
                    id={`dropdown-${name}`}
                >
                    {activeDrop?.label ? activeDrop?.label : 'None'}
                </Dropdown.Toggle>

                <Dropdown.Menu className={menuClassName}>
                    {isSearchAble && children}
                    {DataHandler().map((option: any, index: any) => (
                        <Dropdown.Item
                            id={option.label}
                            className={itemClassName}
                            key={option.value}
                            eventKey={option.value}
                            onClick={() => {
                                setActiveDrop(option)
                                scrollToLabel(option.label)
                            }}
                            ref={(el: any) =>
                                (labelRefs.current[option.label] = el)
                            }
                        >
                            {option?.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default ScrollDropDown
