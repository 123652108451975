import React from 'react'
import ReactDatePicker from 'react-datepicker'
import './report.style.scss'
import CustomTable from '../../utils/CustomTable/CustomTable'
import axios from 'axios'
import { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import Pagination from 'react-js-pagination'
import CustomButton from '../../utils/CustomButton/CustomButton'
import Loader from '../../utils/Loader/Loader'
import {
    convertUnixFormat,
    dateFormater,
    getCurrentDate,
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'
import ReportSlider from './Slider/Report-Slider'
import { reportTableDatahander } from './handlers'
import SubHeader from '../../component/subHeader/SubHeader'
import { HttpCallGet } from '../../utils/Services/NetworkCall'
import { dataHandler } from '../PendingContainer/hander'

/**@Strictly_TypeChecked Required  */
export interface DetailsInterface {
    map(arg0: (item: any) => JSX.Element): React.ReactNode
    imgUrl: string
    id: string
    from: string
    to: string
    date: string
}

function Report(props: any) {
    const [show, setShow] = useState<boolean>(false)

    const [total, setTotal] = useState<number>(0)

    const [currentPage, setCurrentPage] = useState<any>(1)

    const [pageSize, setPageSize] = useState<number>(10)

    const [loading, setLoading] = useState<boolean>(false)

    const [tableData, setTableData] = useState<any>([])

    const [dateHolder, setDateHolder] = useState<any>({
        startDate: getCurrentDate(1),
        lastDate: getCurrentDate(),
    })

    // const [selectedDetail, setSelectedDetail] = useState<DetailsInterface>({
    //     imgUrl: '',
    //     id: '',
    //     from: '',
    //     to: '',
    //     date: '',
    // })

    const [selectedDetail, setSelectedDetail] = useState<any>([])

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
        setCurrentPage(1)
    }

    const reportDetails = (show: boolean, boxId: any) => {
        console.log('boxId', boxId)
        setShow(show)
        setLoading(true)
        HttpCallGet(
            `admin/report-log-details?boxId=${boxId}&currentPage=${currentPage}&locationId=${_id}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setSelectedDetail(res.data.data)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'Box Id',
                accessor: '_id',
                Cell: (item: any) => {
                    return <div>{item.cell?.value}</div>
                },
            },
            {
                Header: 'Title',
                accessor: 'boxInfo',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            <img
                                className="Inventory__typeTableImg"
                                src={item.cell?.value?.icon}
                            />
                            <div>{item.cell?.value?.name}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Box Type',
                accessor: 'itemType',
            },
            {
                Header: 'Checkout Date',
                accessor: 'updatedAt',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__asTabTableImg">
                            <div>{dateFormater(item.value)}</div>
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'contain',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__buttonTable">
                            <CustomButton
                                // btnEvt={() => {
                                //     // setShow(true)
                                //     // setSelectedDetail({
                                //     //     imgUrl: item.cell.row.original.boxInfo
                                //     //         .icon,
                                //     //     id: item.cell.row.original._id,
                                //     //     from: item.cell.row.original.assignor[0]
                                //     //         .name,
                                //     //     to: item.cell.row.original.assignee[0]
                                //     //         .name,
                                //     //     date: dateFormater(
                                //     //         item.cell.row.original.updatedAt
                                //     //     ),
                                //     // })
                                // }}
                                btnEvt={() => {
                                    reportDetails(
                                        true,
                                        item.cell.row.original._id
                                    )
                                }}
                                btnName="Details"
                                clsName="report_sec"
                            >
                                <i className="fa-solid fa-circle-info infoIcon"></i>
                            </CustomButton>
                        </div>
                    )
                },
            },
        ],

        []
    )

    const fetchReportDetail = () => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_BASE_URL}admin/report-logs`, {
                headers: {
                    Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                },
                params: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                    locationId: _id,
                    startDate: convertUnixFormat(dateHolder.startDate),
                    endDate: convertUnixFormat(dateHolder.lastDate),
                },
            })
            .then((res: any) => {
                setLoading(false)
                setTableData(res.data.data)
                setTotal(res.data.totalRecords)
            })
            .catch((err: any) => {
                setLoading(false)
                console.log('error', err)
            })
    }

    useEffect(() => {
        fetchReportDetail()
    }, [pageSize, currentPage, dateHolder, props.activeUser])

    useEffect(() => {
        setCurrentPage(1)
    }, [dateHolder])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="ReportComponent">
                    <SubHeader HeaderTitle="Checked-In Containers" />
                    <div className="reportSearch">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="reportDateSelect">
                                        <ReactDatePicker
                                            name="inventoryDateTime"
                                            className="month"
                                            value={dateHolder.startDate}
                                            onChange={(date: any) => {
                                                setDateHolder({
                                                    ...dateHolder,
                                                    startDate:
                                                        moment(date).format(
                                                            'MM/DD/YYYY'
                                                        ),
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="reportDateSelect">
                                        <ReactDatePicker
                                            name="inventoryDateTime"
                                            className="month"
                                            value={dateHolder.lastDate}
                                            onChange={(date: any) => {
                                                setDateHolder({
                                                    ...dateHolder,
                                                    lastDate:
                                                        moment(date).format(
                                                            'MM/DD/YYYY'
                                                        ),
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 custmizeReportTable">
                                    <CustomTable
                                        columns={columns}
                                        data={
                                            reportTableDatahander(tableData) ||
                                            []
                                        }
                                        msg="No reports found"
                                    />

                                    <div className="table__pagination__container tablePagination">
                                        <div className="itemPagination">
                                            {showPagination(
                                                total,
                                                pageSize
                                            ) && (
                                                <Pagination
                                                    activePage={currentPage}
                                                    itemsCountPerPage={pageSize}
                                                    totalItemsCount={total}
                                                    onChange={(page: any) => {
                                                        setCurrentPage(page)
                                                    }}
                                                />
                                            )}
                                            {showPageSize(total) && (
                                                <select
                                                    className="pagination__select"
                                                    value={pageSize}
                                                    onChange={
                                                        handlePageSizeChange
                                                    }
                                                >
                                                    {[10, 20, 30].map(
                                                        (size) => (
                                                            <option
                                                                key={size}
                                                                value={size}
                                                            >
                                                                Show {size}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            )}
                                        </div>
                                        <div className="perPageItem">
                                            <p>
                                                {paginationHandler(
                                                    currentPage,
                                                    pageSize,
                                                    total
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ReportSlider
                            show={show}
                            setShow={setShow}
                            data={selectedDetail}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Report
