import React, { useEffect, useRef, useState, useMemo } from 'react'
import CustomButton from '../../utils/CustomButton/CustomButton'
import CustomTable from '../../utils/CustomTable/CustomTable'
import Loader from '../../utils/Loader/Loader'
import './charity.style.scss'
import {
    HttpCallGet,
    HttpCallPost,
    HttpCallPUT,
} from '../../utils/Services/NetworkCall'
import { Dropdown, Form } from 'react-bootstrap'
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast'
import CustomSlider from '../../utils/CustomSlider/CustomSlider'
import {
    ConfirmDelete,
    ConfirmDeleteWithAction,
} from '../../utils/ConfirmAlerts'
import Pagination from 'react-js-pagination'
import SubHeader from '../../component/subHeader/SubHeader'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'

function CharityList(props: any) {
    const [loading, setLoading] = useState<boolean>(false)
    const [charityData, setCharityData] = useState<any>([])
    const [show, setShow] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<any>(10)
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [isUpdate, setIsUpdate] = useState<any>('')
    const [updateData, setupdateData] = useState<any>([])
    const [charityDetails, setCharityDetails] = useState({
        name: '',
        address: '',
        description: '',
    })
    const [flagBtnDisabled, setFlagBtnDisabled] = useState(false)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    const AddCharity = () => {
        setShow(true)
        setIsUpdate('add')
        setCharityDetails({
            ...charityDetails,
            name: '',
            address: '',
            description: '',
        })
    }

    const editCharityRow = (data: any) => {
        setupdateData(data)
        setIsUpdate('update')
        setShow(true)
        setCharityDetails({
            ...charityDetails,
            address: data.address,
            description: data.description,
            name: data.name,
        })
    }
    //update charity details
    const updateCharityDetails = () => {
        if (
            charityDetails.name &&
            charityDetails.description &&
            charityDetails.address
        ) {
            setFlagBtnDisabled(true)
            setLoading(true)
            HttpCallPUT(
                `admin/charitable-organization`,
                {
                    address: charityDetails.address,
                    amountToBePayed: updateData.amountToBePayed,
                    createdAt: updateData.createdAt,
                    description: charityDetails.description,
                    isUsedInRewards: updateData.isUsedInRewards,
                    locationId: updateData.locationId,
                    name: charityDetails.name,
                    status: updateData.status,
                    updatedAt: updateData.updatedAt,
                    __v: updateData.__v,
                    _id: updateData._id,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)

                    if (res.data.status === 1) {
                        setShow(false)
                        ToastOnSuccess(res.data.message)
                        fetchCharityData()
                    } else {
                        ToastOnFailure(res.data.message)
                    }
                })
                .catch((err) => {
                    setFlagBtnDisabled(false)

                    ToastOnFailure(err.message)
                })
        } else {
            ToastOnFailure('please fill all fields..!')
        }
    }

    const AddCharityDetails = () => {
        if (charityDetails.name.length === 0) {
            ToastOnFailure('Please enter name')
            return
        }
        if (charityDetails.address.length === 0) {
            ToastOnFailure('Please enter address')
            return
        }
        if (charityDetails.description.length === 0) {
            ToastOnFailure('Please enter description')
            return
        }
        setFlagBtnDisabled(true)

        HttpCallPost(
            `admin/charitable-organization`,
            {
                name: charityDetails.name,
                address: charityDetails.address,
                description: charityDetails.description,
                locationId: _id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setFlagBtnDisabled(false)

                if (res.data.status === 1) {
                    setShow(false)
                    ToastOnSuccess(res.data.message)
                    if (currentPage === 1) {
                        fetchCharityData()
                    }
                    setCurrentPage(1)
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setFlagBtnDisabled(false)

                ToastOnFailure(err.message)
            })
    }

    //delete charity details
    const handleDelete = (data: any) => {
        setLoading(true)
        HttpCallPUT(
            `admin/charitable-organization-status`,
            {
                _id: data._id,
                status: 0,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                if (res.data.status === 1) {
                    setLoading(false)
                    ToastOnSuccess(res.data.message)
                    if (currentPage == 1) {
                        fetchCharityData()
                        setCurrentPage(1)
                    }
                    setCurrentPage(1)
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setLoading(false)
                ToastOnFailure(err.message)
                console.log(err.message)
            })
    }

    // status update detail
    const statusHandler = (data: any) => {
        setLoading(true)
        HttpCallPUT(
            `admin/charitable-organization-status`,
            {
                _id: data._id,
                status: data.status == '1' ? '2' : '1',
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                ToastOnSuccess(res.data.message)
                fetchCharityData()
            })
            .catch((err) => {
                setLoading(false)
                ToastOnFailure(err.message)
                console.log(err.message)
            })
    }

    const fetchCharityData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/charitable-organizations?currentPage=${currentPage}&locationId=${_id}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setCharityData(res.data.data)
                setTotal(res.data.totalRecords)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (item: any) => {
                    return (
                        <div className="Business_Name">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: ' Address',
                accessor: 'address',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_Address">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_City">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_State">
                            <div>
                                {item.cell?.value == 1 ? 'Active' : 'Inactive'}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'program',
                Cell: (item: any) => {
                    return (
                        <div className="Edit_Charity">
                            <CustomButton
                                btnName="Edit"
                                clsName="ms-2 mb-2"
                                btnEvt={() =>
                                    editCharityRow(item?.cell?.row?.original)
                                }
                            >
                                <i className="editIcon fa-solid fa-pen-to-square"></i>
                            </CustomButton>
                            <CustomButton
                                btnName={
                                    item.cell.row.original.status == 1
                                        ? 'Inactive'
                                        : 'Active'
                                }
                                clsName="ms-2 mb-2"
                                btnEvt={() =>
                                    ConfirmDeleteWithAction(
                                        item?.cell?.row?.original,
                                        '',
                                        `Are you sure, you want to ${
                                            item.cell.row.original.status == 1
                                                ? 'Inactive'
                                                : 'Active'
                                        } it?`,
                                        `${
                                            item.cell.row.original.status == 1
                                                ? 'Inactive'
                                                : 'Active'
                                        }`,
                                        statusHandler
                                    )
                                }
                            />
                            {item?.cell?.row?.original.status == 1 &&
                                item?.cell?.row?.original.isUsedInRewards
                                    .length == 0 && (
                                    <CustomButton
                                        btnName="Delete"
                                        clsName="deleteOne ms-2 mb-2"
                                        btnEvt={() =>
                                            ConfirmDelete(
                                                item?.cell?.row?.original,
                                                handleDelete
                                            )
                                        }
                                    >
                                        <i className="deletIcon fa-solid fa-trash"></i>
                                    </CustomButton>
                                )}
                        </div>
                    )
                },
            },
        ],
        [currentPage]
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchCharityData()
        }, 400)
        return () => {
            clearTimeout(timer)
        }
    }, [currentPage, pageSize, props.activeUser])
    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="CharityList">
                    <SubHeader HeaderTitle="Charities" />

                    <div className="Add_Btn mt-2">
                        <CustomButton
                            clsName="addCharity ms-2 mt-2 mt-md-0"
                            btnName="Add Charity"
                            btnEvt={() => AddCharity()}
                        />
                    </div>

                    <div className="Charity_table table-responsive custmizeCharityTable">
                        <CustomTable
                            msg="No charity found"
                            columns={columns}
                            data={charityData || []}
                        />
                    </div>
                    <CustomSlider
                        activeTitle={
                            isUpdate === 'update'
                                ? `Edit (${sessionStorage.getItem('WR_TITLE')})`
                                : `Add (${sessionStorage.getItem('WR_TITLE')})`
                        }
                        show={show}
                        setShow={setShow}
                        Evt={() => {
                            !flagBtnDisabled
                                ? isUpdate === 'update'
                                    ? updateCharityDetails()
                                    : AddCharityDetails()
                                : false
                        }}
                        btnEventName={isUpdate == 'update' ? 'Update' : 'Add'}
                    >
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    charityDetails.name
                                        ? charityDetails.name
                                        : ''
                                }
                                onChange={(e) =>
                                    setCharityDetails({
                                        ...charityDetails,
                                        name: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Address
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    charityDetails.address
                                        ? charityDetails.address
                                        : ''
                                }
                                onChange={(e) =>
                                    setCharityDetails({
                                        ...charityDetails,
                                        address: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Description
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    charityDetails.description
                                        ? charityDetails.description
                                        : ''
                                }
                                onChange={(e) =>
                                    setCharityDetails({
                                        ...charityDetails,
                                        description: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </CustomSlider>
                    <div className="col-md-12">
                        <div className="table__pagination__container">
                            {showPagination(total, pageSize) && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {showPageSize(total) && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="perPageItem">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CharityList
