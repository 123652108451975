import React, { useEffect, useMemo, useState } from 'react'
import CustomButton from '../../utils/CustomButton/CustomButton'
import './Container.style.scss'
import CustomTable from '../../utils/CustomTable/CustomTable'
import {
    HttpCallDelete,
    HttpCallGet,
    HttpCallPost,
    HttpCallPUT,
} from '../../utils/Services/NetworkCall'
import Loader from '../../utils/Loader/Loader'
import { Form } from 'react-bootstrap'
import CustomSlider from '../../utils/CustomSlider/CustomSlider'
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast'
import Pagination from 'react-js-pagination'
import CustomDropdown from '../../utils/CustomDropdown/CustomDropdown'
import SubHeader from '../../component/subHeader/SubHeader'
import { paginationHandler, showPageSize } from '../../utils/util'

const DropDownDataValue = [
    { label: 'Carton', value: 'Carton' },
    { label: 'Container', value: 'Container' },
]

function ContainerType(props: any) {
    const [loading, setLoading] = useState<boolean>(false)
    const [containerData, setContinerData] = useState<any>([])
    const [show, setShow] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<any>(10)
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [itemId, setItemId] = useState<any>('')
    const [dropActive, setDropActive] = useState<any>('')
    const [imageSrc, setImageSrc] = useState<any>('')
    const [containerDetails, setContainerDetails] = useState<any>({
        Title: '',
        Prefix: '',
        price: 0,
        isUpdate: '',
        imageSrc: '',
    })

    const [flagBtnDisabled, setFlagBtnDisabled] = useState(false)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const addContainerType = () => {
        setShow(true)
        setContainerDetails({
            ...containerDetails,
            Title: '',
            Prefix: '',
            price: 0,
            isUpdate: 'add',
            imageSrc: '',
        })
        setDropActive('')
    }

    const editContainerDetails = (data: any) => {
        setShow(true)
        setItemId(data)
        setContainerDetails({
            ...containerDetails,
            Title: data.title,
            Prefix: data.prefix,
            price: data.price,
            isUpdate: 'update',
            imageSrc: data.icon,
        })
        setDropActive({
            ...dropActive,
            label: data.itemType,
        })
    }

    const UploadImgHandler = () => {
        if (imageSrc.length > 0) {
            HttpCallPost(
                `s3/upload-file`,
                {
                    contentType: 'image/png',
                    locationType: 'admin-drop-station',
                    image: imageSrc,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    ToastOnSuccess(res.data.message)
                    setContainerDetails({
                        ...containerDetails,
                        imageSrc: res.data.data?.imageUrl,
                    })
                })
                .catch((err: any) => {
                    console.log('err', err)
                })
        }
    }

    const AddContainerDetails = () => {
        if (containerDetails.Title.length === 0) {
            ToastOnFailure('Please Fill title')
            return
        }
        if (containerDetails.Prefix.length === 0) {
            ToastOnFailure('Please Fill Prefix')
            return
        }
        if (containerDetails.price == 0 || containerDetails.price == '') {
            ToastOnFailure('Please Fill price')
            return
        }
        if (dropActive.label == undefined) {
            ToastOnFailure('Please fill type')
            return
        }
        if (containerDetails.imageSrc === '') {
            ToastOnFailure('Please upload an image')
            return
        }
        setFlagBtnDisabled(true)
        HttpCallPost(
            `admin/ContainerType/create`,
            {
                icon: containerDetails.imageSrc,
                itemType: dropActive.value,
                prefix: containerDetails.Prefix,
                price: containerDetails.price,
                title: containerDetails.Title,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setFlagBtnDisabled(false)

                if (res.data.status == 1) {
                    ToastOnSuccess(res.data.message)
                    setShow(false)
                    if (currentPage === 1) {
                        fetchConatinerData()
                        setCurrentPage(1)
                    } else {
                        setCurrentPage(1)
                    }
                    setImageSrc('')
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setFlagBtnDisabled(false)

                ToastOnFailure(err.message)
            })
    }

    const editContainer = () => {
        if (containerDetails.Title.length === 0) {
            ToastOnFailure('Please Fill title')
            return
        }
        if (containerDetails.Prefix.length === 0) {
            ToastOnFailure('Please Fill Prefix')
            return
        }
        if (containerDetails.price == 0) {
            ToastOnFailure('Please Fill price')
            return
        }
        if (dropActive.label == undefined) {
            ToastOnFailure('Please fill type')
            return
        }
        if (containerDetails.imageSrc === '') {
            ToastOnFailure('Please upload an image')
            return
        }
        setFlagBtnDisabled(true)

        setLoading(true)
        HttpCallPUT(
            `admin/ContainerType/${itemId._id}/update`,
            {
                canDelete: itemId.canDelete,
                createdAt: itemId.createdAt,
                ecoCount: itemId.ecoCount,
                icon: containerDetails.imageSrc,
                itemType: dropActive.label,
                maxNumber: itemId.maxNumber,
                prefix: containerDetails.Prefix,
                price: containerDetails.price,
                quickbooksId: itemId.quickbooksId,
                reuseCount: itemId.reuseCount,
                status: itemId.status,
                title: containerDetails.Title,
                totalContainers: itemId.totalContainers,
                type: itemId.type,
                updatedAt: itemId.updatedAt,
                __v: itemId.__v,
                _id: itemId._id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setFlagBtnDisabled(false)

                setLoading(false)
                setShow(false)
                ToastOnSuccess(res.data.message)
                fetchConatinerData()
            })
            .catch((err) => {
                setFlagBtnDisabled(false)

                setLoading(false)
                ToastOnFailure(err.message)
                console.log(err.message)
            })
    }

    const fetchConatinerData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/ContainerType/list?currentPage=${currentPage}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setTotal(res.data.totalRecords)
                setContinerData(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const checkImage = () => {
        return (containerDetails.isUpdate === 'isUpdate' && imageSrc) ||
            containerDetails.imageSrc.length > 0
            ? true
            : false
    }

    const deleteDetails = (data: any) => {
        HttpCallDelete
        setLoading(true)
        HttpCallDelete(
            `admin/ContainerType/${data._id}/delete`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                if (currentPage === 1) {
                    fetchConatinerData()
                    setCurrentPage(1)
                } else {
                    setCurrentPage(1)
                }
                ToastOnSuccess(res.data.message)
            })
            .catch((err) => {
                setLoading(false)
                ToastOnFailure(err.message)
                console.log(err.message)
            })
    }

    const handleImageChange = (e: any) => {
        const file = e.target.files[0]
        const reader: any = new FileReader()
        reader.onloadend = () => {
            setImageSrc(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const columns: any = useMemo(
        () => [
            {
                Header: '',
                accessor: 'icon',
                Cell: (item: any) => {
                    return (
                        <img
                            className="Container__IconIMG"
                            src={item.cell?.value}
                        />
                    )
                },
            },
            {
                Header: 'Title',
                accessor: 'title',
                Cell: (item: any) => {
                    return (
                        <div className="Container_title">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Prefix',
                accessor: 'prefix',
                Cell: (item: any) => {
                    return (
                        <div className="Container_Prefix">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Price',
                accessor: 'price',
                Cell: (item: any) => {
                    return (
                        <div className="Container_Price">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Type',
                accessor: 'itemType',
                Cell: (item: any) => {
                    return (
                        <div className="Container_Status">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'program',
                Cell: (item: any) => {
                    return (
                        <>
                            <div className="btnSection">
                                <div className="Edit_Container">
                                    <CustomButton
                                        btnName="Edit"
                                        btnEvt={() =>
                                            editContainerDetails(
                                                item.cell.row.original
                                            )
                                        }
                                        clsName="m-2"
                                    >
                                        {/* <i className="fa-thin fa-pen-to-square "></i> */}
                                        <i className="fa-solid fa-pen-to-square edit"></i>
                                    </CustomButton>
                                </div>
                                <div className="Delete">
                                    {item.cell.row.original.maxNumber == 0 ? (
                                        <CustomButton
                                            btnName="Delete"
                                            btnEvt={() =>
                                                deleteDetails(
                                                    item.cell.row.original
                                                )
                                            }
                                            clsName="m-2"
                                        >
                                            <i
                                                className="fa fa-trash edit"
                                                aria-hidden="true"
                                            ></i>
                                        </CustomButton>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </>
                    )
                },
            },
        ],
        [currentPage]
    )
    useEffect(() => {
        if (imageSrc.length > 0) {
            UploadImgHandler()
        }
    }, [imageSrc])

    useEffect(() => {
        fetchConatinerData()
    }, [props.activeUser, currentPage, pageSize])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="Container_type">
                    <SubHeader HeaderTitle="Container Types" isShow={false} />
                    <div className="Add_Btn">
                        <CustomButton
                            clsName="ms-2 mt-2 mt-md-0"
                            btnName="Add Container Type"
                            btnEvt={() => addContainerType()}
                        />
                    </div>
                    <div className="Charity_table table-responsive charityTableSec adminContainer_table">
                        <CustomTable
                            msg="No container type found"
                            columns={columns}
                            data={containerData}
                        />
                    </div>
                    <CustomSlider
                        activeTitle={
                            containerDetails.isUpdate === 'update'
                                ? `Edit`
                                : `Add`
                        }
                        show={show}
                        setShow={setShow}
                        Evt={() => {
                            !flagBtnDisabled
                                ? containerDetails.isUpdate === 'update'
                                    ? editContainer()
                                    : AddContainerDetails()
                                : false
                        }}
                        btnEventName={
                            containerDetails.isUpdate == 'update'
                                ? 'update'
                                : 'Add'
                        }
                        parrentClassName="add_container_type"
                    >
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Title
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    containerDetails.Title
                                        ? containerDetails.Title
                                        : ''
                                }
                                onChange={(e) =>
                                    setContainerDetails({
                                        ...containerDetails,
                                        Title: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Prefix
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    containerDetails.Prefix
                                        ? containerDetails.Prefix
                                        : ''
                                }
                                onChange={(e) =>
                                    setContainerDetails({
                                        ...containerDetails,
                                        Prefix: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Price
                            </Form.Label>
                            <Form.Control
                                type="number"
                                min="0"
                                step=".01"
                                value={
                                    containerDetails.price
                                        ? containerDetails.price
                                        : ''
                                }
                                onChange={(e) => {
                                    setContainerDetails({
                                        ...containerDetails,
                                        price:
                                            e.target.value == ''
                                                ? 0
                                                : parseInt(e.target.value),
                                    })
                                }}
                            />
                        </div>
                        <div>
                            <label>Type</label>
                            <CustomDropdown
                                name=""
                                data={DropDownDataValue}
                                activeDrop={dropActive}
                                setActiveDrop={setDropActive}
                            />
                        </div>
                        <div className="addInput">
                            <div className="Image_Input">
                                <button>Select icon</button>
                                <input
                                    type="file"
                                    className="uploadInput"
                                    onChange={handleImageChange}
                                />
                            </div>
                            <div className="Image_Check">
                                {checkImage() && (
                                    <img
                                        className="containerType_image"
                                        src={
                                            imageSrc
                                                ? imageSrc
                                                : containerDetails.imageSrc
                                        }
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                    </CustomSlider>
                    <div className="col-md-12">
                        <div className="table__pagination__container text-center mt-5 mb-5">
                            {total > pageSize && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {showPageSize(total) && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="perPageItem">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default ContainerType
