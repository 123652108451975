import React, { useEffect, useMemo, useState } from 'react'
import {
    HttpCallGet,
    HttpCallPost,
    HttpCallPUT,
    HttpCallDelete,
} from '../../utils/Services/NetworkCall'
import CustomButton from '../../utils/CustomButton/CustomButton'
import './RestaurantCategory.syle.scss'
import Loader from '../../utils/Loader/Loader'
import CustomTable from '../../utils/CustomTable/CustomTable'
import CustomSlider from '../../utils/CustomSlider/CustomSlider'
import { Form } from 'react-bootstrap'
import { ToastOnSuccess, ToastOnFailure } from '../../utils/Toast/Toast'
import Pagination from 'react-js-pagination'
import { ConfirmDelete } from '../../utils/ConfirmAlerts'
import RestSlider from './slider/RestSlider'
import SubHeader from '../../component/subHeader/SubHeader'
import { paginationHandler, showPageSize } from '../../utils/util'
import config from '../../configs/config'

function RestaurantCategory(props: any) {
    const [loading, setLoading] = useState<boolean>(false)
    const [catergoryData, setCategoryData] = useState<any>([])
    const [show, setShow] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<any>(10)
    const [total, setTotal] = useState<number>(0)
    const [name, setName] = useState<string>('')
    const [updateData, setupdateData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [isUpdate, setIsUpdate] = useState<any>('')

    const [containerShow, setContainerShow] = useState<boolean>(false)

    const [restList, setRestList] = useState([])

    const [restActiveList, setRestActiveList] = useState<any>([])

    const [activeId, setActiveId] = useState('')

    const [isFlag, setIsFlag] = useState<boolean>(false)

    const [flagBtnDisabled, setFlagBtnDisabled] = useState(false)

    const fetchCategoryData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/vendorCat/list?currentPage=${currentPage}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setCategoryData(res.data.data)
                setTotal(res.data.totalRecords)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const addCategory = () => {
        setShow(true)
        setName('')
        setIsUpdate('add')
    }

    const deleteCategoryDetails = (data: any) => {
        setLoading(true)
        HttpCallDelete(
            `admin/vendorCat/${data._id}/delete`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                ToastOnSuccess(res.data.message)

                if (res.data.status == 1) {
                    if (currentPage === 1) {
                        fetchCategoryData()
                        setCurrentPage(1)
                    } else {
                        setCurrentPage(1)
                    }
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setLoading(false)
                ToastOnFailure(err.message)
                console.log(err.message)
            })
    }

    const activateContainer = (data: any) => {
        setActiveId(data._id)
        setContainerShow(true)
    }

    const addCategoryDetails = () => {
        if (name.length !== 0) {
            setLoading(true)
            setFlagBtnDisabled(true)
            HttpCallPost(
                `admin/vendorCat/create`,
                {
                    name: name,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    if (res.data.status == 1) {
                        ToastOnSuccess(res.data.message)
                        setShow(false)
                        if (currentPage === 1) {
                            fetchCategoryData()
                            setCurrentPage(1)
                        } else {
                            setCurrentPage(1)
                        }
                    } else {
                        ToastOnFailure(res.data.message)
                    }
                })
                .catch((err) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    console.log(err.message)
                })
        } else {
            ToastOnFailure('please fill name field..! ')
        }
    }

    const updateCategoryDetails = () => {
        if (name.length !== 0) {
            setFlagBtnDisabled(true)
            setLoading(true)

            HttpCallPUT(
                `admin/vendorCat/${updateData._id}/update`,
                {
                    canDelete: updateData.canDelete,
                    createdAt: updateData.createdAt,
                    name: name,
                    status: updateData.status,
                    updatedAt: updateData.updatedAt,
                    __v: updateData.__v,
                    // _id: updateData._id
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    setShow(false)
                    ToastOnSuccess(res.data.message)
                    fetchCategoryData()
                })
                .catch((err) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    ToastOnFailure(err.message)
                    console.log(err.message)
                })
        } else {
            ToastOnFailure('please fill name field..! ')
        }
    }

    const editCatergory = (data: any) => {
        setShow(true)
        setupdateData(data)
        setName(data.name)
        setIsUpdate('update')
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (item: any) => {
                    return (
                        <div className="Container_Prefix">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'program',
                Cell: (item: any) => {
                    return (
                        <div className="Edit_Container">
                            <div className="editSection">
                                <CustomButton
                                    btnName="Edit"
                                    clsName="editButton ms-2"
                                    btnEvt={() =>
                                        editCatergory(item.cell.row.original)
                                    }
                                >
                                    <i className="fas fa-edit"></i>
                                </CustomButton>
                            </div>

                            <div className="deleteSection">
                                {item.cell.row.original.canDelete && (
                                    <CustomButton
                                        btnName="Delete"
                                        clsName="deleteBtn ms-2"
                                        btnEvt={() =>
                                            ConfirmDelete(
                                                item.cell.row.original,
                                                deleteCategoryDetails
                                            )
                                        }
                                    >
                                        <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                        ></i>
                                    </CustomButton>
                                )}
                            </div>

                            <div className="contianerType">
                                <CustomButton
                                    btnName="Container Type"
                                    clsName="containerButton ms-2"
                                    btnEvt={() =>
                                        activateContainer(
                                            item.cell.row.original
                                        )
                                    }
                                />
                            </div>
                        </div>
                    )
                },
            },
        ],
        []
    )

    useEffect(() => {
        fetchCategoryData()
    }, [isFlag, currentPage, pageSize, props.activeUser])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="RestaurantCategory">
                    <SubHeader HeaderTitle="Vendor Categories" isShow={false} />
                    <div className="Add_Btn">
                        <CustomButton
                            clsName="AddResturant ms-2 mt-2 mt-md-0"
                            btnName={`Add ${config.venderTitle} Category`}
                            btnEvt={() => addCategory()}
                        />
                    </div>
                    <div className="Charity_table table-responsive charityTableSec">
                        <CustomTable
                            msg="No vendors category found"
                            columns={columns}
                            data={catergoryData || []}
                        />
                    </div>

                    <CustomSlider
                        activeTitle={isUpdate === 'update' ? `Edit` : `Add`}
                        show={show}
                        setShow={setShow}
                        Evt={() => {
                            !flagBtnDisabled
                                ? isUpdate === 'update'
                                    ? updateCategoryDetails()
                                    : addCategoryDetails()
                                : false
                        }}
                        btnEventName={isUpdate == 'update' ? 'update' : 'Add'}
                    >
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={name ? name : ''}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </CustomSlider>

                    {containerShow && (
                        <RestSlider
                            show={containerShow}
                            setShow={setContainerShow}
                            restList={restList}
                            setRestList={setRestList}
                            restActiveList={restActiveList}
                            setRestActiveList={setRestActiveList}
                            currentPage={currentPage}
                            pageSize={pageSize}
                            activeId={activeId}
                            isFlag={isFlag}
                            setIsFlag={setIsFlag}
                        />
                    )}

                    <div className="col-md-12">
                        <div className="table__pagination__container text-center mt-5 mb-5">
                            {total > pageSize && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {showPageSize(total) && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="perPageItem">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default RestaurantCategory
