import React from 'react'
import { Accordion } from 'react-bootstrap'
import './accordian.style.scss'
interface iPorps {
    HeaderParam?: string
    HeaderName: any
    children: any
    key: any
    cName?: any
    headerFlagFirst?: any
    headerFlagSecond?: any
    headerFirstFlagName?: any
    headerSecondFlagName?: any
    showFilterIcon?: boolean
}

const CustomAccordian: React.FC<iPorps> = ({
    HeaderParam,
    HeaderName,
    children,
    key,
    cName,
    headerFirstFlagName,
    headerSecondFlagName,
    headerFlagFirst,
    headerFlagSecond,
    showFilterIcon,
}) => {
    const renderArrow = (arr: any, flag: any) => {
        return arr?.label !== 'NONE' && arr?.label !== 'None' && arr ? (
            arr?.label && (
                <>
                    <i className="fa-solid fa-greater-than fa-2xs greater-icons"></i>
                    <i className="fa-solid fa-greater-than fa-2xs greater-icons"></i>
                    <span>{flag + ` (` + arr?.label + `)`}</span>
                </>
            )
        ) : (
            <></>
        )
    }

    return (
        <div className={`custom-accordian ${cName}`}>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey={key}>
                    <Accordion.Header className="custom-accordian__header">
                        <div>
                            {HeaderParam}
                            {showFilterIcon ? (
                                <i className="fa-solid fa-filter"></i>
                            ) : (
                                ''
                            )}
                            {HeaderName}
                        </div>

                        {headerFlagFirst &&
                            renderArrow(headerFlagFirst, headerFirstFlagName)}
                        {headerFlagSecond &&
                            renderArrow(headerFlagSecond, headerSecondFlagName)}
                    </Accordion.Header>
                    <Accordion.Body>{children}</Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default CustomAccordian
