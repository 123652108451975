import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Column } from 'react-table'
import Loader from '../../utils/Loader/Loader'
import Warehousemodel from './warehouse-model/Warehouse.model'
import { WRH_ADD_ITEM } from '../../utils/Services/EndPoints'
import './warehouse.style.scss'
import {
    HttpCallDelete,
    HttpCallGet,
    HttpCallPost,
    HttpCallPUT,
} from '../../utils/Services/NetworkCall'
import WarehouseTable from './Table/Table'
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast'
import './warehouse.style.scss'
import { capitalizeFirstLetter } from '../../utils/util'
import SubHeader from '../../component/subHeader/SubHeader'

type Person = {
    name: string
    age: any
    id: any
    delete: any
}

function Warehouse(props: any) {
    const { activeUser } = props
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [wrhDetails, setWrhDetails] = useState<any>([])
    const [wrhEditDetails, setWrhEditDetails] = useState({
        _id: '',
        name: '',
        placeId: '',
        canDelete: '',
        latitude: 0,
        longitude: 0,
        isUpdate: false,
        formatedAddres: '',
    })

    const [locationObj, setLocationObj] = useState<any>({})
    const locationRef = useRef<any>()
    const [isFlagUpdate, setIsFlagUpdate] = useState<boolean>(true)
    const [flagBtnDisable, setFlagBtnDisable] = useState<boolean>(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState<number>(0)

    // const data: Person[] = [
    //     { name: 'John', age: 25, id: 'abc' },
    //     { name: 'avbadsf', age: 22, id: 'cbc' },
    // ]

    const columns: Column<Person>[] = [
        { Header: 'Name', accessor: 'name' },
        { Header: '', accessor: 'age' },
        // { Header: '', accessor: 'delete' },
    ]

    const fetchWrhItems = () => {
        setLoading(true)
        const storage: any = sessionStorage.getItem('locationStore') || {
            _id: '',
        }
        const { _id } = JSON.parse(storage)
        HttpCallGet(
            `admin-warehouse/list?currentPage=${currentPage}&locationId=${_id}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setTotal(res.data.totalRecords)
                setWrhDetails(modifyResponse(res.data.data))
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const addItemInWrh = () => {
        if (wrhEditDetails.name.length === 0) {
            ToastOnFailure('Please enter name')
            return
        }
        if (Object.keys(locationObj).length === 0) {
            ToastOnFailure('Please enter Location')
            return
        }
        const storage: any = sessionStorage.getItem('locationStore') || {
            _id: '',
        }
        const { _id } = JSON.parse(storage)
        setFlagBtnDisable(true)
        HttpCallPost(
            WRH_ADD_ITEM,
            {
                name: wrhEditDetails.name,
                placeId: locationObj.place_id,
                latitude: locationObj?.geometry?.location?.lat(),
                longitude: locationObj?.geometry?.location?.lng(),
                locationId: _id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setFlagBtnDisable(false)
                if (res.data.status === 1) {
                    setShow(false)
                    ToastOnSuccess(res.data.message)
                    if (currentPage == 1) {
                        fetchWrhItems()
                        setCurrentPage(1)
                    } else {
                        setCurrentPage(1)
                    }
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setFlagBtnDisable(false)

                ToastOnFailure(err.message)
            })
    }

    const updateItemInWrh = () => {
        if (wrhEditDetails.name.length === 0) {
            ToastOnFailure('please enter name')
            return
        }
        if (!isFlagUpdate) {
            ToastOnFailure('please enter location')
            return
        }
        setFlagBtnDisable(true)

        HttpCallPUT(
            `admin/warehouse/${wrhEditDetails._id}/update`,
            {
                _id: wrhEditDetails._id,
                name: wrhEditDetails.name,
                canDelete: wrhEditDetails.canDelete,
                placeId: locationObj.place_id
                    ? locationObj.place_id
                    : wrhEditDetails.placeId,
                latitude: locationObj?.geometry?.location?.lat()
                    ? locationObj?.geometry?.location?.lat()
                    : wrhEditDetails.latitude,
                longitude: locationObj?.geometry?.location?.lng()
                    ? locationObj?.geometry?.location?.lng()
                    : wrhEditDetails.longitude,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                if (res.data.status == 1) {
                    setShow(false)
                    ToastOnSuccess(res.data.message)
                    setWrhEditDetails({ ...wrhEditDetails, name: '' })
                    setFlagBtnDisable(false)

                    fetchWrhItems()
                } else {
                    ToastOnFailure(res.data.message)
                    setFlagBtnDisable(false)
                }
            })
            .catch((err) => {
                setFlagBtnDisable(false)
                ToastOnFailure(err.message)
            })
    }
    const modifyResponse = (data: []) => {
        return data.map((item: any) => {
            item.age = `<div style="text-align: end;" className='table__editDiv'>
            <button id='wr__edit_btn' className='wr__edit_btn'>
                <i class="fa-sharp fa-solid fa-pen-to-square" id='wr__edit_btn' ></i>
                Edit
                <button>
                <button id='wr__delete_btn' className='warehouse__delete_btn from_top'>
                      <i class="fa-solid fa-trash" id='wr__delete_btn' ></i>
                      Delete<button>
                <div>`
            item.name = capitalizeFirstLetter(item.name)
            return item
        })
    }

    const updateRow = (id: any, data?: any) => {
        setWrhEditDetails({
            ...wrhEditDetails,
            name: data.name,
            canDelete: data.canDelete,
            _id: data._id,
            placeId: locationObj.place_id ? locationObj.place_id : data.placeId,
            latitude: locationObj?.geometry?.location?.lat()
                ? locationObj?.geometry?.location?.lat()
                : data.geo.coordinates[0],
            longitude: locationObj?.geometry?.location?.lng()
                ? locationObj?.geometry?.location?.lng()
                : data.geo.coordinates[1],
            isUpdate: true,
            formatedAddres: data.address.formattedAddress,
        })
        setShow(true)
    }

    const deleteRow = (id: any) => {
        HttpCallDelete(
            `admin/warehouse/${id}/delete`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                setCurrentPage(1)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchWrhItems()
        }, 200)
        return () => {
            clearTimeout(timer)
        }
    }, [currentPage, pageSize, props.activeUser])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div>
                    <SubHeader HeaderTitle="WareHouse" />
                    <div className="container-fluid">
                        <div className="wrh">
                            <div className="warehouseSec">
                                <div className="AddWarehouseBtn">
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setShow(true)
                                            setWrhEditDetails({
                                                ...wrhEditDetails,
                                                name: '',
                                                isUpdate: false,
                                                formatedAddres: '',
                                            })
                                        }}
                                        className="wrh__addBtn"
                                    >
                                        Add Warehouse
                                    </Button>
                                </div>
                                <WarehouseTable
                                    updateRow={updateRow}
                                    deleteRow={deleteRow}
                                    data={wrhDetails}
                                    columns={columns}
                                    show={show}
                                    setShow={setShow}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    total={total}
                                />
                                <Warehousemodel
                                    Evt={
                                        wrhEditDetails.isUpdate
                                            ? updateItemInWrh
                                            : addItemInWrh
                                    }
                                    flagBtnDisable={flagBtnDisable}
                                    wrhEditDetails={wrhEditDetails}
                                    setWrhEditDetails={setWrhEditDetails}
                                    show={show}
                                    setShow={setShow}
                                    locationRef={locationRef}
                                    locationObj={locationObj}
                                    setLocationObj={setLocationObj}
                                    isFlagUpdate={isFlagUpdate}
                                    setIsFlagUpdate={setIsFlagUpdate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Warehouse
