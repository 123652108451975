import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./ConfirmAlerts.style.scss";

export const ConfirmDelete = (key:any, deleteCallback:any, cancelCallback?:any) => {
  confirmAlert({
    message: "Are you sure, you want to remove it?",
    buttons: [
      {
        label: "Remove",
        onClick: () => deleteCallback(key),
      },
      {
        label: "Cancel",
        onClick: () => cancelCallback && cancelCallback(),
      },
    ],
  });
};

export const ConfirmDeleteWithAction = (
  key?:any,
  secondkey?:any,
  LebelMsg?:any, 
  btnFirstLebel?:any,
  deleteCallback?:any,
  cancelCallback?:any) => {
  confirmAlert({
    message: LebelMsg || " Are you Sure ? ",
    buttons: [
      {
        label: btnFirstLebel || "Delete",
        onClick: () => deleteCallback(key,secondkey),
      },
      {
        label: "Cancel",
        onClick: () => cancelCallback && cancelCallback(),
      },
    ],
  });
};


// export const GenericConfirm = ({
//   key,
//   saveCallback,
//   triggerMsg,
//   SaveMsg,
//   cancelCallback,
// }) => {
//   confirmAlert({
//     message: triggerMsg,
//     buttons: [
//       {
//         label: SaveMsg || "Okay",
//         onClick: () => saveCallback(key),
//       },
//       {
//         label: "Cancel",
//         onClick: () => cancelCallback && cancelCallback(),
//       },
//     ],
//   });
// };
