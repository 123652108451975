import React, { useEffect, useState } from 'react'
import { Form, Offcanvas, Tab, Tabs } from 'react-bootstrap'
import CustomButton from '../../../../../utils/CustomButton/CustomButton'
import './programSliderOne.style.scss'
import ProgramSliderTwo from '../SliderTwo/ProgramSliderTwo'
import {
    HttpCallGet,
    HttpCallPost,
} from '../../../../../utils/Services/NetworkCall'
import {
    ToastOnFailure,
    ToastOnSuccess,
} from '../../../../../utils/Toast/Toast'
import Loader from '../../../../../utils/Loader/Loader'

interface IProps {
    show: boolean
    setShow: any
    activeTitle: string
    Evt?: any
    btnEventName?: string
    programInfo: any
    setProgramInfo: any
    restaurantList: any
    setRestaurantList: any
    loading: any
    setLoading: any
    currentPage: number
    pageSize: number
    isFlag: boolean
    setIsFlag: any
    setCurrentPage?: any
}
const ProgramSliderOne: React.FC<IProps> = ({
    activeTitle,
    show,
    setShow,
    Evt,
    btnEventName,
    programInfo,
    setProgramInfo,
    restaurantList,
    loading,
    setLoading,
    currentPage,
    pageSize,
    isFlag,
    setIsFlag,
    setCurrentPage,
}) => {
    const [secondSlider, setSecondSlider] = useState<boolean>(false)

    const [imageSrc, setImageSrc] = useState('')

    const [checkedIds, setCheckedIds] = useState(
        programInfo.vendors ? programInfo.vendors : []
    )

    const [checkedIdsDonation, setCheckedIdsDonations] = useState(
        programInfo.charities
    )

    const [charitiesList, setChartiesList] = useState<any>([])

    const [isEnableDonation, setIsEnabledDonation] = useState(false)

    const [flagBtnDisabled, setFlagBtnDisabled] = useState(false)

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    const handleRadioChange = (event: any) => {
        setProgramInfo((item: any) => {
            return { ...item, type: event.target.value }
        })
    }

    const [sliderSecond, setSliderSecond] = useState({
        type: '',
        rewardQr: '',
        points: '',
        benefitTitle: '',
        benefitValue: '',
    })

    const handleImageChange = (e: any) => {
        const file = e.target.files[0]
        const reader: any = new FileReader()
        reader.onloadend = () => {
            setImageSrc(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const showImgValidator = () => {
        const value = imageSrc ? imageSrc : programInfo?.image
        return value.length > 0 && true
    }

    const UploadImgHandler = () => {
        if (imageSrc.length === 0) {
            ToastOnFailure('Please upload an image')
            return
        }
        if (imageSrc.length > 0) {
            HttpCallPost(
                `s3/upload-file`,
                {
                    contentType: 'image/png',
                    locationType: 'admin-reward',
                    image: imageSrc,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    ToastOnSuccess(res.data.message)
                    setProgramInfo((prev: any) => {
                        return {
                            ...prev,
                            image: res.data.data?.imageUrl,
                        }
                    })
                })
                .catch((err: any) => {
                    console.log('err', err)
                })
        }
    }

    const fetchDonateDetails = () => {
        HttpCallGet(
            `admin/getCharityToAddReward?currentPage=1&pageSize=1000&locationId=${_id}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setChartiesList(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const vendorChecked = function (item: any, list: any) {
        if (list) {
            const index = list.findIndex(function (val: any) {
                return val._id == item?._id
            })
            return index > -1
        }
    }

    const handleCheckBoxChange = (item: any, event: any) => {
        const { id, checked } = event.target
        if (checked) {
            const charitynew = {
                name: item.name,
                _id: item._id,
                image: item.image,
            }
            setCheckedIds([...checkedIds, charitynew])
        } else {
            setCheckedIds(
                checkedIds.filter((checkedId: any) => checkedId._id !== id)
            )
        }
    }

    const handleCheckCharties = (item: any, event: any) => {
        const { id, checked } = event.target
        if (checked) {
            const charitynew = {
                name: item.name,
                _id: item._id,
                locationId: item.locationId,
            }
            setCheckedIdsDonations([...checkedIdsDonation, charitynew])
        } else {
            setCheckedIdsDonations(
                checkedIdsDonation.filter(
                    (checkedId: any) => checkedId._id !== id
                )
            )
        }
    }

    const deleteBenefitItem = (data: any) => {
        if (activeTitle == 'Add Program') {
            setProgramInfo((prevState: any) => ({
                ...prevState,
                benefitList: prevState.benefitList.filter(
                    (item: any) => item.rewardQr != data.rewardQr
                ),
            }))
        } else {
            HttpCallPost(
                'admin-reward/delete-benefit',
                {
                    _id: data._id,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setProgramInfo((prevState: any) => ({
                        ...prevState,
                        benefitList: prevState.benefitList.filter(
                            (item: any) => item.rewardQr != data.rewardQr
                        ),
                    }))
                    ToastOnSuccess(res.data.message)
                })
                .catch((err) => {
                    ToastOnFailure(err.message)
                })
        }
    }

    const AddRest = () => {
        if (programInfo.type?.length <= 0) {
            ToastOnFailure('Please enter program type')
            return
        }
        if (programInfo.title.length <= 0) {
            ToastOnFailure('Please enter title')
            return
        }
        if (programInfo.description.length <= 0) {
            ToastOnFailure('Please enter description')
            return
        }
        if (programInfo.rewardPoints.length <= 0) {
            ToastOnFailure(
                'Please enter No of points on each successful return'
            )
            return
        }

        if (checkedIds.length == 0) {
            ToastOnFailure('Please enter Vendor')
            return
        }

        if (programInfo.benefitList?.length == 0) {
            ToastOnFailure('Please select benefit')
            return
        }

        // if (programInfo.maxDonate?.length == 0) {
        //     ToastOnFailure('Please enter max-Donate')
        //     return
        // }

        if (programInfo.image.length == 0) {
            ToastOnFailure('Please upload an image')
            return
        }
        setFlagBtnDisabled(true)

        HttpCallPost(
            `${
                activeTitle === 'Add Program'
                    ? `admin/addReward`
                    : `admin/updReward`
            }`,
            {
                charities: checkedIdsDonation.map((item: any) => ({
                    _id: item._id,
                    name: item.name,
                })),
                vendors: checkedIds,
                benefits: programInfo.benefitList,
                isDonationEnabled: isEnableDonation,
                image: programInfo?.image,
                rewardValue: 0,
                rewardCode: 'code',
                locationId: _id,
                type: programInfo?.type,
                title: programInfo?.title,
                description: programInfo?.description,
                rewardPoints: programInfo?.rewardPoints,
                donatePercent: programInfo?.donatePercent,
                maxDonate: programInfo?.maxDonate,
                id:
                    activeTitle !== 'Add Program'
                        ? sessionStorage.getItem('activeId')
                        : undefined,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setFlagBtnDisabled(false)
                if (res.data.status === 1) {
                    setCurrentPage(1)
                    setShow(false)
                    ToastOnSuccess(res.data.message)
                    setIsFlag(!isFlag)
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setFlagBtnDisabled(false)
                ToastOnFailure(err.message)
            })
    }

    useEffect(() => {
        if (imageSrc.length > 0) {
            UploadImgHandler()
        }
    }, [imageSrc])

    useEffect(() => {
        setCheckedIds(programInfo.vendors ? programInfo.vendors : [])
        setCheckedIdsDonations(
            programInfo.charities ? programInfo.charities : []
        )
        setIsEnabledDonation(programInfo.charities?.length > 0 ? true : false)
    }, [programInfo.vendors])

    useEffect(() => {
        fetchDonateDetails()
    }, [])

    return (
        <div>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <>
                    <Offcanvas
                        show={show}
                        onHide={() => setShow(false)}
                        placement="end"
                        className={
                            activeTitle === 'View Program'
                                ? 'Program_slider my-div-class'
                                : 'Program_slider'
                        }
                    >
                        <Offcanvas.Header>
                            <Offcanvas.Title
                                className={`Slider__title commonHeaderTitle`}
                            >
                                {`${activeTitle}  (${sessionStorage.getItem(
                                    'WR_TITLE'
                                )})`}
                            </Offcanvas.Title>
                            {activeTitle != 'View Program' && (
                                <CustomButton
                                    btnEvt={() => !flagBtnDisabled && AddRest()}
                                    btnName={
                                        activeTitle == 'Add Program'
                                            ? 'Add'
                                            : 'Update'
                                    }
                                />
                            )}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Tabs
                                defaultActiveKey="Program"
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                            >
                                <Tab
                                    eventKey="Program"
                                    title="Program Info"
                                    className={`${
                                        activeTitle === 'View Program' &&
                                        'Program_slider__tabDisAble'
                                    }`}
                                >
                                    <div className="row">
                                        <div className="col-md-5 col-sm-12">
                                            <div className="text-center">
                                                {activeTitle !==
                                                    'View Program' && (
                                                    <div className="fileInputWrapper">
                                                        <input
                                                            type="file"
                                                            className="fileInput"
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleImageChange(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <button className="uploadBtn">
                                                            Select image
                                                        </button>
                                                    </div>
                                                )}
                                                {showImgValidator() && (
                                                    <div>
                                                        <img
                                                            className="Program_slider__upldImg"
                                                            src={
                                                                imageSrc
                                                                    ? imageSrc
                                                                    : programInfo?.image
                                                            }
                                                            // alt="image upload "
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12">
                                            <div className="serviceLabel">
                                                <Form.Check
                                                    inline
                                                    label="Vendor Sponsored"
                                                    name="group1"
                                                    type="radio"
                                                    value={1}
                                                    onChange={handleRadioChange}
                                                    checked={
                                                        programInfo?.type == 1
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Service Provider Sponsored"
                                                    name="group1"
                                                    type="radio"
                                                    value={2}
                                                    checked={
                                                        programInfo?.type == 2
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={handleRadioChange}
                                                    className="ServiceSposored"
                                                />
                                            </div>

                                            <br />
                                            <div className="serviceInputSec">
                                                <Form.Label htmlFor="title">
                                                    Title
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="title"
                                                    value={programInfo?.title}
                                                    onChange={(e) =>
                                                        setProgramInfo(
                                                            (item: any) => {
                                                                return {
                                                                    ...item,
                                                                    title: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            }
                                                        )
                                                    }
                                                    aria-describedby="passwordHelpBlock"
                                                />

                                                <Form.Label htmlFor="Description">
                                                    Description
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Description"
                                                    aria-describedby="passwordHelpBlock"
                                                    value={
                                                        programInfo?.description
                                                    }
                                                    onChange={(e) =>
                                                        setProgramInfo(
                                                            (item: any) => {
                                                                return {
                                                                    ...item,
                                                                    description:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            }
                                                        )
                                                    }
                                                />

                                                <Form.Label htmlFor="checkout">
                                                    No of points on each
                                                    successful return
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="checkout"
                                                    defaultValue={
                                                        programInfo?.rewardPoints
                                                    }
                                                    onWheel={(event) =>
                                                        event.currentTarget.blur()
                                                    }
                                                    min="0"
                                                    onChange={(e) => {
                                                        const inputValue =
                                                            e.target.value
                                                        const numericValue =
                                                            parseFloat(
                                                                inputValue
                                                            )

                                                        if (
                                                            !isNaN(numericValue)
                                                        ) {
                                                            setProgramInfo(
                                                                (item: any) => {
                                                                    return {
                                                                        ...item,
                                                                        rewardPoints:
                                                                            numericValue,
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }}
                                                    aria-describedby="passwordHelpBlock"
                                                />
                                            </div>

                                            <div className="Program_slider__restCheckbox">
                                                <p>Vendor</p>

                                                {restaurantList?.length > 0 &&
                                                    restaurantList.map(
                                                        (item: any) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        item.id
                                                                    }
                                                                >
                                                                    <Form.Check
                                                                        disabled={
                                                                            item.isUsedInReward
                                                                        }
                                                                        defaultChecked={vendorChecked(
                                                                            item,
                                                                            programInfo.vendors
                                                                        )}
                                                                        label={
                                                                            item?.name
                                                                        }
                                                                        value={
                                                                            item._id
                                                                        }
                                                                        name="group1"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleCheckBoxChange(
                                                                                item,
                                                                                e
                                                                            )
                                                                        }
                                                                        type="checkbox"
                                                                        id={
                                                                            item._id
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    eventKey="Rewards"
                                    title="Rewards"
                                    className={`${
                                        activeTitle === 'View Program' &&
                                        'Program_slider__tabDisAble'
                                    }`}
                                >
                                    <div className="Demo">
                                        {activeTitle !== 'View Program' && (
                                            <CustomButton
                                                btnName="New"
                                                btnEvt={() =>
                                                    setSecondSlider(true)
                                                }
                                                clsName="newBtn  ms-2 mb-2"
                                            />
                                        )}
                                    </div>
                                    <hr />
                                    <table>
                                        <tr>
                                            <th>QR code </th>
                                            <th>No of Points</th>
                                            <th>Type</th>
                                            <th>Title</th>
                                            <th>Value</th>
                                            {activeTitle !== 'View Program' && (
                                                <th>Action</th>
                                            )}
                                        </tr>
                                        {programInfo &&
                                            programInfo?.benefitList?.map(
                                                (item: any) => {
                                                    return (
                                                        <tr key={item._id}>
                                                            <td>
                                                                {item.rewardQr}
                                                            </td>
                                                            <td>
                                                                {item.points}
                                                            </td>
                                                            <td>
                                                                {item?.type == 1
                                                                    ? `Cash`
                                                                    : `Merchendise`}{' '}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.benefitTitle
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.benefitValue
                                                                }
                                                            </td>
                                                            {activeTitle !==
                                                                'View Program' && (
                                                                <td>
                                                                    <CustomButton
                                                                        btnName="Delete"
                                                                        btnEvt={() =>
                                                                            deleteBenefitItem(
                                                                                item
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )
                                                }
                                            )}
                                    </table>
                                </Tab>
                                <Tab
                                    eventKey="Donation"
                                    title="Donation"
                                    className={`${
                                        activeTitle === 'View Program' &&
                                        'Program_slider__tabDisAble'
                                    }`}
                                >
                                    <Form.Check
                                        label="Enabled Donation for the Programs"
                                        name="group4"
                                        type="checkbox"
                                        checked={isEnableDonation}
                                        onChange={() =>
                                            setIsEnabledDonation(
                                                !isEnableDonation
                                            )
                                        }
                                    />

                                    {isEnableDonation && (
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <div className="donateSec">
                                                    <div>
                                                        <Form.Label htmlFor="donate_percent">
                                                            Donate Percent
                                                        </Form.Label>

                                                        <Form.Control
                                                            type="number"
                                                            min="0"
                                                            id="donate_percent"
                                                            value={
                                                                programInfo?.donatePercent
                                                            }
                                                            onChange={(e) =>
                                                                setProgramInfo(
                                                                    (
                                                                        item: any
                                                                    ) => {
                                                                        e.stopPropagation()
                                                                        return {
                                                                            ...item,
                                                                            donatePercent:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                            aria-describedby="passwordHelpBlock"
                                                        />
                                                    </div>

                                                    <div>
                                                        <Form.Label htmlFor="max_donate">
                                                            Description
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            id="max_donate"
                                                            aria-describedby="passwordHelpBlock"
                                                            value={
                                                                programInfo?.maxDonate
                                                            }
                                                            onChange={(e) =>
                                                                setProgramInfo(
                                                                    (
                                                                        item: any
                                                                    ) => {
                                                                        e.stopPropagation()
                                                                        return {
                                                                            ...item,
                                                                            maxDonate:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                {charitiesList.length > 0 &&
                                                    charitiesList.map(
                                                        (item: any) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        item._id
                                                                    }
                                                                >
                                                                    <Form.Check
                                                                        label={
                                                                            item.name
                                                                        }
                                                                        name="group4"
                                                                        type="checkbox"
                                                                        id={
                                                                            item._id
                                                                        }
                                                                        defaultChecked={vendorChecked(
                                                                            item,
                                                                            programInfo?.charities
                                                                        )}
                                                                        onChange={(
                                                                            e: any
                                                                        ) =>
                                                                            handleCheckCharties(
                                                                                item,
                                                                                e
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                </Tab>
                            </Tabs>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <ProgramSliderTwo
                        show={secondSlider}
                        setShow={setSecondSlider}
                        sliderSecond={sliderSecond}
                        programInfo={programInfo}
                        setProgramInfo={setProgramInfo}
                        setSliderSecond={setSliderSecond}
                        loading={loading}
                        setLoading={setLoading}
                        activeTitle={activeTitle}
                    />
                </>
            )}
        </div>
    )
}
export default ProgramSliderOne
