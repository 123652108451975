
export const reportTableDatahander = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        boxInfo: {
            icon: item.boxTypeInfo[0].icon,
            name: item.boxTypeInfo[0].itemType,
        },
    }))
}

