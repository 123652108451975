import React, { useEffect, useMemo, useState } from 'react'
import './restaurant.scss'
import CustomTable from '../../utils/CustomTable/CustomTable'
import CustomButton from '../../utils/CustomButton/CustomButton'
import { Form, Offcanvas } from 'react-bootstrap'
import {
    HttpCallGet,
    HttpCallPUT,
    HttpCallPost,
} from '../../utils/Services/NetworkCall'
import Loader from '../../utils/Loader/Loader'
import ProgramModel from './Modals/ProgramModel'
import Pagination from 'react-js-pagination'
import CustomDropdown from '../../utils/CustomDropdown/CustomDropdown'
import DetailModal from './Modals/DetailModal'
import { addNoneHandler, modifyWrhHandler } from '../inventory/methodHandler'
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast'
import PricingModel from './Modals/Price/PricingModel'
import axios from 'axios'
import { ConfirmDeleteWithAction } from '../../utils/ConfirmAlerts'
import config from '../../configs/config'
import SubHeader from '../../component/subHeader/SubHeader'
import CharityList from '../Programs/CharityList'
import ScrollDropDown from '../../utils/scrollDropDown/ScrollDropDown'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'

import defaultVendor from '../../assets/image/default_user.png'

function Restaurants(props: any) {
    const [show, setShow] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<any>({
        program: false,
        category: false,
        Details: false,
        Pricing: false,
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<any>(10)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [tableData, setTableData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [searchField, setSearchField] = useState<any>()
    const [detailData, setDetailData] = useState<any>([])
    const [programcellData, setProgramcellData] = useState<any>([])
    const [categoryData, setCategoryData] = useState<any>([])
    const [states, setStates] = useState<any>([])
    const [countries, setCountries] = useState<any>([])
    const [aStatusActive, setAsStatusActive] = useState<any>({
        label: 'Select Country',
    })
    const [aStatesActive, setAsStatesActive] = useState<any>({
        label: 'Select State',
    })
    const [acityActive, setCityActive] = useState<any>({
        label: 'Select city',
    })
    const [cities, setCities] = useState<any>([])
    const [categoryID, setCategoryID] = useState<any>('')
    const [activeCatId, setActiveCatId] = useState<any>('')
    const [categoryActive, setCategoryActive] = useState<any>()

    const [activPriceShow, setActivePriceShow] = useState(false)
    const [activeField, setActiveField] = useState<any>()
    const [isFlag, setIsFlag] = useState(false)

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    // Get all Countries
    const fetchCountriesDetails = async () => {
        try {
            const response: any = await HttpCallGet(
                `countries`,
                sessionStorage.getItem('AUTH_TOKEN')
            )
            setCountries(addNoneHandler(response.data.data))
        } catch (error) {
            console.log('err', error)
        }
    }

    // Get all states
    const fetchStateDetails = async () => {
        if (aStatusActive?.value) {
            try {
                const response: any = await HttpCallGet(
                    `states?country_id=${aStatusActive.value}`,
                    sessionStorage.getItem('AUTH_TOKEN')
                )
                setAsStatesActive('')
                setStates(addNoneHandler(response.data.data))
            } catch (error) {
                console.log('error', error)
            }
        }
    }

    //Get all cities
    const fetchCitiesDetails = async () => {
        if (aStatesActive.value) {
            const response: any = await HttpCallGet(
                `cities?state_id=${aStatesActive.value}`,
                sessionStorage.getItem('AUTH_TOKEN')
            )

            setCities(addNoneHandler(response.data.data))
        }
    }

    const SaveHandler = () => {
        if (
            categoryActive?._id
                ? categoryActive._id
                : categoryLebelHander(categoryData, activeCatId)?.value
        ) {
            HttpCallPost(
                `admin/vendor/category/${categoryID}/assign`,
                {
                    vendorCatId: categoryActive?._id
                        ? categoryActive._id
                        : categoryLebelHander(categoryData, activeCatId)?.value,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    ToastOnSuccess(res.data.message)
                    setShowModal({
                        ...showModal,
                        category: false,
                    })
                    setIsFlag(!isFlag)
                })
                .catch((err) => {
                    ToastOnFailure(err.message)
                })
        } else {
            ToastOnFailure('Kindly select the value')
        }
    }

    const activeUser = (id: any) => {
        HttpCallGet(
            `admin/vendor-approval?vendorId=${id}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                setLoading(false)
                setIsFlag(!isFlag)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const userDetails = (data: any) => {
        setShowModal({
            ...showModal,
            Details: true,
        })
        HttpCallGet(
            `admin/vendor/history?vendorId=${data._id}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setDetailData(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const handleProgram = (programData: any) => {
        setShowModal({
            // ...showModal,
            program: true,
        })
        HttpCallGet(
            `admin/vendor-reward?currentPage=1&id=${programData._id}&pageSize=1000`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setProgramcellData(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const handleCategory = (data: any) => {
        setShowModal({
            ...showModal,
            category: true,
        })
        setActiveCatId(data.vendorCatId)
        HttpCallGet(
            `admin/vendorCat/list?currentPage=1&pageSize=1000`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setCategoryData(res.data.data)
                setCategoryID(data._id)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
        setCurrentPage(1)
    }

    const filterHandler = (arr1: any, arr2: any) => {
        return arr1.map((item1: any) => {
            const matchingItem2 = arr2.find(
                (item2: any) => item1._id === item2._id
            )
            return {
                _id: item1._id,
                name: item1.name,
                price: matchingItem2 ? matchingItem2.price : 0,
            }
        })
    }

    const activeFieldHandler = async (show: boolean, data: any) => {
        if (!data?.vendorCatId) {
            ToastOnFailure(
                'Assign the category the first before adding pricing'
            )
            return
        }

        try {
            setLoading(true)
            const categoryResponse: any = await HttpCallGet(
                `admin/vendorCatBoxType/${data?.vendorCatId}/details`,
                sessionStorage.getItem('AUTH_TOKEN')
            )
            setActiveField((prev: any) => ({
                ...prev,
                boxPricing: filterHandler(
                    categoryResponse.data.data[0]?.boxTypes,
                    data.boxPricing
                ),
                _id: data._id,
            }))
            setLoading(false)
            setActivePriceShow(show)
        } catch (error) {
            setLoading(false)
            console.log('error', error)
        }
    }

    const updateStatus = (id: any, key: any) => {
        HttpCallPUT(
            `admin/vendor/update-status`,
            {
                userId: id,
                status: key,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                // setIsFlag(!isFlag)
                FetchRestData()
                ToastOnSuccess(res.data.message)
                setShow(false)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    const columns: any = useMemo(
        () => [
            {
                Header: '',
                accessor: 'images',
                Cell: (item: any) => {
                    return (
                        <img
                            className="Resturant__typeTableImg"
                            src={
                                item.cell?.value
                                    ? item.cell.value
                                    : defaultVendor
                            }
                        />
                    )
                },
            },
            {
                Header: 'Business Name',
                accessor: 'name',
                Cell: (item: any) => {
                    return (
                        <div className="Business_Name">
                            <div className="elipsisCs">{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_Email">
                            <div className="elipsisCs">{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Mobile Number',
                accessor: 'mobile',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_Mobile">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: ' Address',
                accessor: 'address.formattedAddress',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_Address">
                            <div className="elipsisCs">{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'City',
                accessor: 'address.city',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_City">
                            <div className="elipsisCs">{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'State',
                accessor: 'address.state',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_State">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'status',
                accessor: 'status',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_status">
                            <div>
                                {item.cell?.value != 0 ? (
                                    'Activated'
                                ) : (
                                    <div className="Auto_Renewal">
                                        <CustomButton
                                            btnName="Active"
                                            btnEvt={() =>
                                                ConfirmDeleteWithAction(
                                                    item?.cell?.row?.original
                                                        ._id,
                                                    '',
                                                    'Are you sure, you want to activate it?',
                                                    'Activate',
                                                    activeUser
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: 'Total No Of Boxes Alloted',
                accessor: 'totalBoxes',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_Boxes">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Number Of Orders',
                accessor: 'totalOrders',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_Orders">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Available Boxes',
                accessor: 'currentBoxes',
                Cell: (item: any) => {
                    return (
                        <div className="ResturantaAvlBox">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'pricing',
                Cell: (item: any) => {
                    return (
                        <div className="Resturanta_Pricing">
                            <CustomButton
                                btnName="Pricing"
                                btnEvt={() =>
                                    activeFieldHandler(
                                        true,
                                        item.cell.row.original
                                    )
                                }
                            />
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'program',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_program">
                            <CustomButton
                                btnName="program"
                                btnEvt={() =>
                                    handleProgram(item.cell.row.original)
                                }
                            />
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'category',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_category">
                            <CustomButton
                                btnName="category"
                                btnEvt={() =>
                                    handleCategory(item.cell.row.original)
                                }
                            />
                        </div>
                    )
                },
            },
            {
                Header: 'icon',
                accessor: 'activeStatus',
                Cell: (item: any) => {
                    return (
                        <div className="Resturant_category">
                            <i
                                className="fa-solid fa-circle-info infoIcon"
                                onClick={() =>
                                    userDetails(item.cell.row.original)
                                }
                            >
                                <span className="tooltiptext">Detail</span>
                            </i>

                            {item.cell?.value == 0 || item.cell?.value == 4 ? (
                                <i
                                    onClick={() =>
                                        ConfirmDeleteWithAction(
                                            item?.cell?.row?.original._id,
                                            1,
                                            'Are you sure, you want to restore it?',
                                            'Restore',
                                            updateStatus
                                        )
                                    }
                                    className="fa-sharp fa-solid fa-recycle recycleIcon"
                                >
                                    <span className="tooltiptext">Restore</span>
                                </i>
                            ) : (
                                item.cell?.value == 1 &&
                                item.cell.row.original.totalOrders == 0 && (
                                    <i
                                        onClick={() =>
                                            ConfirmDeleteWithAction(
                                                item?.cell?.row?.original._id,
                                                0,
                                                'Are you sure, you want to delete it?',
                                                'Delete',
                                                updateStatus
                                            )
                                        }
                                        className="fa-sharp fa-solid fa-trash deleteIcon"
                                    >
                                        <span className="tooltiptext">
                                            Delete
                                        </span>
                                    </i>
                                )
                            )}
                        </div>
                    )
                },
            },
        ],
        [currentPage]
    )

    const FetchRestData = () => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_BASE_URL}admin/vendors`, {
                headers: {
                    Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                },
                params: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                    search: searchField,
                    locationId: _id,
                    // country: aStatusActive?.name,
                    state: aStatesActive?.name,
                    city: acityActive?.name,
                },
            })
            .then((res: any) => {
                setLoading(false)
                setTableData(res.data.data)
                setTotal(res.data.totalVendors)
            })
            .catch((err: any) => {
                setLoading(false)
                console.log('error', err)
            })
    }

    const tableHandler = (data: any) => {
        return data?.map((item: any) => ({
            ...item,
            activeStatus: item.status,
        }))
    }

    const categoryLebelHander = (data: any, id: any) => {
        if (id) {
            const index = data?.findIndex((item: any) => item._id == id)
            const obj = {
                label: data[index]?.name,
                value: data[index]?._id,
            }
            return obj
        }
    }

    useEffect(() => {
        fetchCountriesDetails()
    }, [])

    useEffect(() => {
        fetchCitiesDetails()
    }, [aStatesActive?.value])

    useEffect(() => {
        setCategoryActive('')
    }, [!showModal.categoryActive])

    useEffect(() => {
        fetchStateDetails()
    }, [aStatusActive])

    useEffect(() => {
        const timer = setTimeout(() => {
            FetchRestData()
        }, 800)
        return () => {
            clearTimeout(timer)
        }
    }, [
        searchField,
        currentPage,
        pageSize,
        aStatesActive,
        acityActive,
        isFlag,
        props.activeUser,
    ])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchField, aStatusActive, aStatesActive, acityActive])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="restaurantSection">
                    {/* <div className="Inventory">
                        <div className="Inventory__header">
                            <p className="userTitle">
                                {config.venderTitle} (
                                {sessionStorage.getItem('WR_TITLE')})
                            </p>
                        </div>
                    </div> */}
                    <SubHeader HeaderTitle="Vendors" />
                    <div className="searchFieldSec">
                        <div className="CustomDropdown customFlex">
                            <Form.Control
                                type="text"
                                value={searchField}
                                placeholder="Search"
                                className="SearchCustomInput inputField"
                                onChange={(e: any) => {
                                    setSearchField(e.target.value)
                                }}
                                autoFocus={true}
                            />
                            <i
                                className="fa fa-search searchIcon"
                                aria-hidden="true"
                            ></i>
                        </div>
                        {/* Countries */}
                        <ScrollDropDown
                            name=""
                            data={countries}
                            activeDrop={aStatusActive}
                            parrentClassName={'userDropdown'}
                            setActiveDrop={setAsStatusActive}
                        />

                        {/* States */}
                        <ScrollDropDown
                            name=""
                            data={states}
                            activeDrop={aStatesActive}
                            parrentClassName={'userDropdown'}
                            setActiveDrop={setAsStatesActive}
                        />
                        {/* Cities */}
                        <ScrollDropDown
                            name=""
                            data={cities}
                            activeDrop={acityActive}
                            parrentClassName={'userDropdown'}
                            setActiveDrop={setCityActive}
                        />
                    </div>
                    <div className="Resturant_table table-responsive restaurantTableSec">
                        <CustomTable
                            msg={`No ${config.venderTitle} found`}
                            columns={columns}
                            data={tableHandler(tableData) || []}
                        />
                        <div className="table__pagination__container tablePagination">
                            <div className="itemPagination">
                                {showPagination(total, pageSize) && (
                                    <Pagination
                                        activePage={currentPage}
                                        itemsCountPerPage={pageSize}
                                        totalItemsCount={total}
                                        onChange={(page: any) => {
                                            setCurrentPage(page)
                                        }}
                                    />
                                )}
                                {showPageSize(total) && (
                                    <select
                                        className="pagination__select"
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                    >
                                        {[10, 20, 30].map((size) => (
                                            <option key={size} value={size}>
                                                Show {size}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="perPageItem">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <PricingModel
                show={activPriceShow}
                setShow={setActivePriceShow}
                activeField={activeField}
                setActiveField={setActiveField}
                isFlag={isFlag}
                setIsFlag={setIsFlag}
            />
            <ProgramModel
                open={showModal.program}
                setOpen={setShowModal}
                data={programcellData}
            />
            <DetailModal
                open={showModal.Details}
                setOpen={setShowModal}
                data={detailData}
            />
            {/* <CategoryModel
                open={showModal.category}
                setOpen={setShowModal}
                data={categoryData}
                id={categoryID}
            /> */}
            <Offcanvas
                show={showModal.category}
                onHide={() =>
                    setShowModal({
                        ...showModal,
                        category: false,
                    })
                }
                placement="end"
                className="programModel"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title className="Catergory_ModalHead">
                        <div className="Category_heading">
                            <h3>Assign Category</h3>
                        </div>
                        <div className="Save_Button">
                            <CustomButton
                                clsName="ms-2 mt-2 mt-md-0"
                                btnName="Save"
                                btnEvt={() => SaveHandler()}
                            />
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr />
                <Offcanvas.Body>
                    <CustomDropdown
                        name="Change Category"
                        data={modifyWrhHandler(categoryData)}
                        activeDrop={
                            categoryActive
                                ? categoryActive
                                : categoryLebelHander(categoryData, activeCatId)
                        }
                        setActiveDrop={setCategoryActive}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Restaurants
