import React from 'react'
import config from '../../configs/config'

export const containerStatus = [
    {
        label: 'None',
        value: '0',
    },
    {
        label: 'Active',
        value: '1',
    },
    {
        label: 'Damage',
        value: '2',
    },
    {
        label: 'Lost',
        value: '3',
    },
]

export const inventoryDetails = [
    {
        value: 0,
        label: 'None',
    },
    {
        value: 1,
        label: 'Inventory',
    },
    {
        value: 2,
        label: config.venderTitle,
    },
    {
        value: 3,
        label: 'Users',
    },
    {
        value: 4,
        label: 'Dropstations',
    },
]
