import React, { useEffect, useState } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import './navbar.style.scss'
import Face from '../../assets/icons/face.jpeg'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import pAvtar from '../../assets/icons/face.jpeg'
import { HttpCallGet } from '../../utils/Services/NetworkCall'
import { LOGOUT } from '../../utils/Services/EndPoints'
import Loader from '../../utils/Loader/Loader'
import { ToastOnSuccess } from '../../utils/Toast/Toast'
import config from '../../configs/config'
const NavbarComponent = (props: any) => {
    const { activeUser, setActiveUser } = props
    const [loading, setLoading] = useState(false)
    const [locationList, setLocationList] = useState<any>([])
    const navigate = useNavigate()
    const location = useLocation()

    const Logout = () => {
        setLoading(true)
        HttpCallGet(LOGOUT, sessionStorage.getItem('AUTH_TOKEN'))
            .then((res: any) => {
                sessionStorage.clear()
                props.setSigninStatus(false)
                ToastOnSuccess(res.data.message)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)

                console.log(err.message)
            })
    }

    const userHandler = (res: any) => {
        if (sessionStorage.getItem('WR_TITLE') === null)
            sessionStorage.setItem('WR_TITLE', res.data.data[0].name)
        if (sessionStorage.getItem('locationStore') === null)
            sessionStorage.setItem(
                `locationStore`,
                JSON.stringify(res.data.data[0])
            )
        const id = sessionStorage.getItem('activeUser')
        if (id) {
            setActiveUser(id)
        } else {
            setActiveUser(res.data.data[0]._id)
        }
        return
    }

    const getLocationList = () => {
        HttpCallGet(
            `admin/getLocationList?currentPage=1&pageSize=1000`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                userHandler(res)
                setLocationList(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const navigationHandler = (name: any, id: any, item: any) => {
        sessionStorage.setItem('activeUser', id)
        setActiveUser(id)
        sessionStorage.setItem('WR_TITLE', name)
        if (id == 2) {
            sessionStorage.setItem(
                `locationStore`,
                JSON.stringify(locationList[0])
            )
        } else {
            sessionStorage.setItem(`locationStore`, JSON.stringify(item))
        }
        const activeSessionUser: any = sessionStorage.getItem('activeUser')

        const pathName = location.pathname
        if (activeSessionUser == 2) {
            navigate('/locations', { replace: true })
        } else {
            navigate(pathNameHandler(pathName), { replace: true })
        }
    }

    function pathNameHandler(pathname: any) {
        const pathsToCheck = [
            '/dashboard',
            '/warehouse',
            '/inventory',
            '/dropStations',
            '/restaurants',
            '/orders',
            '/checkedInReport',
            '/charity-list',
            '/reward-list',
            '/claimed-rewards',
            '/donated-rewards',
            '/location-user',
            '/Pending-contianers',
        ]
        for (let i = 0; i < pathsToCheck.length; i++) {
            if (pathname.includes(pathsToCheck[i])) {
                return pathsToCheck[i]
            }
        }
        return '/dashboard'
    }

    const userNameHandler = () => {
        const sessionData = JSON.parse(
            sessionStorage.getItem('userDetails') || '{}'
        )
        const locationData = JSON.parse(
            sessionStorage.getItem('locationStore') || '{}'
        )
        return (
            <>
                <p className="NavbarLogo">
                    {checkLocation(location.pathname)
                        ? `Super Admin`
                        : `${sessionData.firstName}, ${locationData.name}`}
                </p>
                <div className="navbarImage">
                    <img src={Face} className="Navbar__bottom_img ms-auto" />
                    <i className="fa-solid fa-circle Navbar__aIcon"></i>
                </div>
            </>
        )
    }

    const userNameDropdownHandler = () => {
        const activeUser: any = sessionStorage.getItem('activeUser')
        if (activeUser == 2) {
            return 'Super Admin'
        }
        const sessionData = JSON.parse(
            sessionStorage.getItem('userDetails') || '{}'
        )
        return sessionData.firstName
    }

    const checkLocation = (pathName: any) => {
        const pathsToCheck = [
            '/location',
            '/users',
            '/container-type',
            '/restaurant-category',
        ]
        for (let i = 0; i < pathsToCheck.length; i++) {
            if (location.pathname === '/location-user') {
                return undefined
            }
            if (pathName.includes(pathsToCheck[i])) {
                return pathsToCheck[i]
            }
        }
    }

    const navigateSuperAdmin = () => {
        navigate('/locations')
        return
    }

    useEffect(() => {
        getLocationList()
    }, [])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <Navbar className="Navbar my_nav" expand="lg">
                    <Navbar.Brand className="Navbar__brand">
                        <img
                            src={config.whiteLogoPath}
                            className="d-inline-block align-top Navbar__logo"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        className="text-white"
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto Navbar__link">
                            {checkLocation(location.pathname) === undefined ? (
                                <>
                                    <NavLink
                                        className="headerLink"
                                        to="/dashboard"
                                    >
                                        Dashboard
                                    </NavLink>
                                    <NavLink
                                        className="headerLink"
                                        to="/warehouse"
                                    >
                                        Warehouse
                                    </NavLink>
                                    <NavLink
                                        className="headerLink"
                                        to="/inventory"
                                    >
                                        Inventory
                                    </NavLink>
                                    <NavLink
                                        className="headerLink"
                                        to="/location-user"
                                    >
                                        Users
                                    </NavLink>
                                    <NavLink
                                        className="headerLink"
                                        to="/dropStations"
                                    >
                                        Drop Stations
                                    </NavLink>
                                    <NavLink
                                        className="headerLink"
                                        to="/restaurants"
                                    >
                                        {config.venderTitle}
                                    </NavLink>
                                    <NavLink
                                        className="headerLink"
                                        to="/orders"
                                    >
                                        Orders
                                    </NavLink>
                                    <NavDropdown
                                        title="Rewards"
                                        id="basic-nav-dropdown"
                                        className="Navbar__dropdown_link DropDownItem"
                                    >
                                        <NavDropdown.Item
                                            to="/reward-list"
                                            className="Charity_list"
                                            onClick={() =>
                                                navigate('/reward-list')
                                            }
                                        >
                                            Programs
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <NavLink
                                                to="/charity-list"
                                                className="Charity_list"
                                            >
                                                Charities
                                            </NavLink>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <NavLink
                                                to="/claimed-rewards"
                                                className="Charity_list"
                                            >
                                                Claims
                                            </NavLink>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <NavLink
                                                to="/donated-rewards"
                                                className="Charity_list"
                                            >
                                                Donations
                                            </NavLink>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown
                                        title="Reports"
                                        id="basic-nav-dropdown"
                                        className="Navbar__dropdown_link DropDownItem"
                                    >
                                        <NavDropdown.Item
                                            to="/checkedInReport"
                                            className="Charity_list"
                                            onClick={() =>
                                                navigate('/checkedInReport')
                                            }
                                        >
                                            Checked-In Containers
                                        </NavDropdown.Item>

                                        <NavDropdown.Item>
                                            <NavLink
                                                to="/Pending-contianers"
                                                className="Charity_list"
                                            >
                                                Pending Containers
                                            </NavLink>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </>
                            ) : (
                                <>
                                    {checkLocation(location.pathname) && (
                                        <>
                                            <NavLink to="/locations">
                                                Locations
                                            </NavLink>
                                            <NavLink to="/users">Users</NavLink>
                                            <NavLink to="/container-type">
                                                Container Type
                                            </NavLink>
                                            <NavLink to="/restaurant-category">
                                                {config.venderTitle} {''}
                                                category
                                            </NavLink>
                                        </>
                                    )}
                                </>
                            )}
                        </Nav>
                        <Nav className="ms-auto Navbar__profile">
                            <Nav.Link href="#profile" className="subNavItem">
                                <NavDropdown
                                    title={userNameHandler()}
                                    id="basic-nav-dropdown"
                                    className="Navbar__dropdown_mode titleName"
                                >
                                    <NavDropdown.Item key={'logout'}>
                                        <div className="Navbar__logout">
                                            <div className="Navbar__logout__drop_first">
                                                <img
                                                    src={pAvtar}
                                                    className="Navbar__pAvtar"
                                                />
                                                <i className="fa-solid fa-circle Navbar__aIcon"></i>
                                                <span>
                                                    {userNameDropdownHandler()}
                                                </span>
                                            </div>
                                            <div className="Navbar__logoutBtn">
                                                <button
                                                    onClick={() => Logout()}
                                                >
                                                    Logout
                                                </button>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <hr />
                                    <div className="profileDropdown">
                                        {locationList.map(
                                            (item: any, index: any) => {
                                                return (
                                                    <>
                                                        <div
                                                            key={index}
                                                            className="userList"
                                                        >
                                                            <NavDropdown.Item
                                                                key={item}
                                                                onClick={() =>
                                                                    navigationHandler(
                                                                        item.name,
                                                                        item._id,
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                {item.name}
                                                            </NavDropdown.Item>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )}
                                    </div>
                                    <hr />
                                    <NavDropdown.Item
                                        key="key2"
                                        onClick={() => navigateSuperAdmin()}
                                    >
                                        Super Admin
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            )}
        </>
    )
}
export default NavbarComponent
