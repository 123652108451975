import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import CustomButton from '../../../../utils/CustomButton/CustomButton'
import { Form } from 'react-bootstrap'
import { HttpCallPost } from '../../../../utils/Services/NetworkCall'
import { ToastOnFailure, ToastOnSuccess } from '../../../../utils/Toast/Toast'
import './price.style.scss'
import '../../../../App.css'
import { capitalizeFirstLetter } from '../../../../utils/util'
interface Iprops {
    show: boolean
    setShow: any
    activeField: any
    setActiveField: any
    isFlag: boolean
    setIsFlag: any
}
const PricingModel: React.FC<Iprops> = ({
    show,
    setShow,
    activeField,
    setActiveField,
    isFlag,
    setIsFlag,
}) => {
    const filedValidationHandler = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].price === 0) {
                return true
            }
        }
        return false
    }

    const SaveHandler = () => {
        if (filedValidationHandler(activeField.boxPricing)) {
            ToastOnFailure(
                'All fields are mandatory and price must be greater than 0'
            )
            return
        }
        HttpCallPost(
            `admin/vendor/item-pricing`,
            {
                itemPrices: activeField.boxPricing,
                vendorId: activeField._id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                setShow(false)
                setIsFlag(!isFlag)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }
    const onChangeHandler = (item: any, inputText: number, event: any) => {
        const inputValue = event.target.value
        const numericValue = parseFloat(inputValue)

        if (!isNaN(numericValue)) {
            setActiveField((prevState: any) => {
                const newArr = prevState.boxPricing.map((it: any) => {
                    if (it._id === item._id) {
                        return { ...it, price: numericValue }
                    } else {
                        return it
                    }
                })
                return { ...prevState, boxPricing: newArr }
            })
        }
    }

    return (
        <>
            {' '}
            <Offcanvas
                show={show}
                onHide={() => setShow(false)}
                placement="end"
                className="priceModel"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title className="Catergory_ModalHead">
                        <div className="Category_heading">
                            <h4 className="title">
                                Pricing ({sessionStorage.getItem('WR_TITLE')})
                            </h4>
                        </div>
                        <div className="Save_Button">
                            <CustomButton
                                clsName="ms-2 mt-2 mt-md-0"
                                btnName="Save"
                                btnEvt={() => SaveHandler()}
                            />
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="headerHr" />
                <Offcanvas.Body className="priceModelBody">
                    <div className="row mb-2 price">
                        <div className="col-8">
                            <h6 className="price-heading">Container Types</h6>
                        </div>
                        <div className="col-4">
                            <h6 className="price-heading">Unit Price ($)</h6>
                        </div>
                    </div>
                    <hr className="header" />
                    {activeField?.boxPricing.map((item: any, index: any) => {
                        return (
                            <div key={item._id} className="row mb-2 square">
                                <div className="col-8">
                                    <Form.Label htmlFor="SquareBox">
                                        {capitalizeFirstLetter(item.name)}
                                    </Form.Label>
                                </div>
                                <div className="col-4">
                                    <Form.Control
                                        className=""
                                        type="number"
                                        id="SquareBox"
                                        defaultValue={item.price}
                                        onChange={(e: any) =>
                                            onChangeHandler(
                                                item,
                                                e.target.value,
                                                e
                                            )
                                        }
                                    />
                                </div>
                                {activeField.boxPricing.length !==
                                    index + 1 && <hr className="mt-2 hrLine" />}
                            </div>
                        )
                    })}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default PricingModel
