import React from 'react'
import { Form } from 'react-bootstrap'
import './CustomInput.style.scss'
interface iButton {
    state?: any
    setState?: any
    defaultVal?: any
    placeHold?: string
    clsName?: string
    typeOfField:string
    label?:any
    inputClsName?:any
}
const CustomInput: React.FC<iButton> = ({
    placeHold = 'Enter the Value',
    defaultVal=``,
    state,
    setState,
    clsName=``,
    typeOfField=``,
    label,
    inputClsName=``
}) => {
    return (
        <div className={`CustomInput__inputField ${clsName}`}>
           {
            label &&   (<Form.Label htmlFor={typeOfField}>{label}</Form.Label>)
           }
            <Form.Control
                className={inputClsName}
                type={typeOfField}
                id={typeOfField}
                placeholder={placeHold}
                // value={state}
                onChange={(e) =>
                    setState(e.target.value)
                }
            />
        </div>
       
    )
}

export default CustomInput
