import React, { useEffect, useState, useRef } from 'react';
import QRCode from 'qrcode';
interface Iprops {
    benefitList: any,
    width?:any,
    height?:any
}
const QrGenerate: React.FC<Iprops> = ({
    benefitList,
    width=100,
    height=100
}) => {
    const qrCodeRefs = useRef<any>([]);

    useEffect(() => {
        qrCodeGenerator();
    }, [benefitList]);


    const qrCodeGenerator = () => {
        qrCodeRefs.current.forEach((qrCodeRef: any, index: any) => {
            const { vendor, rewardQr } = benefitList[index];
            const qrCodeText = JSON.stringify({ vendor, qr: rewardQr });
            QRCode.toCanvas(qrCodeRef.current, qrCodeText);
        });
    };

    return (
        <>
            {benefitList.map((benefit: any, index: any) => {
                const qrCodeRef: any = useRef(null);
                qrCodeRefs.current[index] = qrCodeRef;

                return (
                    <div key={index}>
                        <canvas ref={qrCodeRef} width={width} height={height} />
                    </div>
                );
            })}
        </>
    );
};

export default QrGenerate;
