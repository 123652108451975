import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import RewardQRModel from './RewardQRModel'
import './Modal.scss'

export default function ProgramModel(props: any) {
    const { open, setOpen, data } = props
    const [showModal, setShowModal] = useState<boolean>(false)
    const [rewardData, setRewardData] = useState<any>([])
    return (
        <div>
            <Offcanvas
                show={open}
                onHide={() => setOpen(false)}
                placement="end"
                className="Modal_head programModel"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title className="wrh__title drp_title programModelTitle">
                        <h3>Programs ({sessionStorage.getItem('WR_TITLE')})</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr />
                <Offcanvas.Body>
                    <table className="Program_table">
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Sponsored By</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        <tr></tr>
                        {data.length > 0
                            ? data.map((item: any, i: number) => {
                                  return (
                                      <tr
                                          key={i}
                                          className={
                                              item.rewardInfo[0].status == 2
                                                  ? 'Active_Inactive'
                                                  : ''
                                          }
                                      >
                                          <td className="ReportSlider__firstCl">
                                              {item.rewardInfo[0].title}
                                          </td>
                                          <td>
                                              {item.rewardInfo[0].description}
                                          </td>
                                          <td>
                                              {item.rewardInfo[0].type == 1
                                                  ? 'Resturant'
                                                  : 'Service Provider'}
                                          </td>
                                          <td>
                                              {item.rewardInfo[0].status == 2
                                                  ? 'Activated'
                                                  : 'Disabled'}
                                          </td>
                                          <td>
                                              <p
                                                  className="ViewQR_Code"
                                                  onClick={() => {
                                                      setShowModal(true)
                                                      setRewardData(
                                                          item.rewardBenefitList
                                                      )
                                                  }}
                                              >
                                                  view QR code
                                              </p>
                                          </td>
                                      </tr>
                                  )
                              })
                            : ''}
                    </table>
                    <RewardQRModel
                        open={showModal}
                        setOpen={setShowModal}
                        data={rewardData}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}
