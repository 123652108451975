import React from 'react'
import config from '../../configs/config'

export const modifyWrhHandler = (data: any) => {
    const m = {
        label: 'None',
        value: '0',
    }
    const arr = [...data]
    arr.push(m)
    return arr.map((item: any) => ({
        ...item,
        label: item.name,
        value: item._id,
    }))
}

export const modifyContainerHandler = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        label: item.type || 'NONE',
        value: item._id || 0,
    }))
}

export const modifyWrhLocationHandler = (data: any) => {
    return data
        ? data.map((item: any) => ({
              ...item,
              label: item.name || 'None',
              value: item._id || 0,
          }))
        : null
}

export const modifyCartonHandler = (data: any) => {
    return data
        ? data.map((item: any) => ({
              ...item,
              label: item._id || 'None',
              value: item.containerInventory || 0,
          }))
        : null
}

export const valueHandler = (data: any) => {
    return data ? data : undefined
}

export const checkAsignHandler = (str: any) => {
    if (str == 'Inventory') {
        return 'INVENTORY'
    } else if (str == config.venderTitle) {
        return 'VENDOR'
    }
    if (str == 'Users') {
        return 'USER'
    } else if (str == 'Dropstations') {
        return 'DROPSTATION'
    }
    return
}

export const containerStatusHandler = (val: any) => {
    return val != 0 ? val : undefined
}

export const addNoneHandler = (data: any) => {
    const newValue = {
        label: 'None',
        value: '0',
    }
    const arr: any = [...data]
    arr.unshift(newValue)
    return arr
}

export const AddSpaceInWord = (text: string) => {
    const result = text.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
}

export const tableDataHander = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        type: {
            itemType: item.boxTypeDetails[0].type,
            itemIcon: item.boxTypeDetails[0].icon,
        },
        checkbox: { boxId: item.boxId, isChecked: false },
    }))
}

export const fiilterBulkHander = (data: any) => {
    return data.filter((item: any) => item.itemType !== 'CARTON')
}

export const filterBulkContainerHandler = (data: []) => {
    return data
        .map((item: any) =>
            item.checkbox.isChecked ? { boxId: item.checkbox.boxId } : null
        )
        .filter((item: any) => item !== null)
}

export const objectHanlder = (data: any) => {
    return Object.keys(data)?.length ? true : false
}

export const activeHandler = (data: any) => {
    return data?.label === 'None' ? true : false
}
