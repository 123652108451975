import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'

export default function Donutchart(props: any) {
    const [params, setParams] = useState<any>(null)

    const data = () => {
        const label = props.data
            .filter((list: any) => list.label)
            .map((data: any) => data.label)
        const series = props.data
            .filter((list: any) => list.data)
            .map((data: any) => data.data)
        setParams({ chart_label: label, chart_data: series })
    }

    useEffect(() => {
        data()
    }, [props?.data])

    return (
        <div className="mb-3">
            {params && (
                <Chart
                    type="donut"
                    options={{
                        labels: params?.chart_label,
                        colors: [
                            '#3366cc',
                            '#dc3912',
                            '#ff9900',
                            '#109618',
                            '#6900F8',
                        ],
                        legend: {
                            position: 'bottom',
                        },
                        fill: {
                            colors: [
                                '#3366cc',
                                '#dc3912',
                                '#ff9900',
                                '#109618',
                                '#6900F8',
                            ],
                        },
                        tooltip: {
                            enabled: true, // Enable the tooltip display on hover
                        },
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: undefined,
                            formatter: function (val: any, opts: any) {
                                return opts.w.config.series[opts.seriesIndex]
                            },
                        },
                    }}
                    series={params?.chart_data}
                    width={400}
                    height={400}
                />
            )}
        </div>
    )
}
