import React, { useState, useEffect, useRef } from "react";
import './search.style.scss'
let autoComplete: {
  addListener: (arg0: string, arg1: () => void) => void;
  getPlace: () => any;
};

declare global {
  interface Window {
    google: any;
  }
}

function SearchLocationInput(props: any) {
  const [query, setQuery] = useState(props.existingAddress);
  const autoCompleteRef = useRef(null);


  const loadScript = (url: any, callback: any) => {
    const script = document.createElement("script") as any;
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );

    autoComplete.addListener("place_changed", function () {
      handlePlaceSelect(updateQuery);
      const place = autoComplete.getPlace();
      props.setLocationObj(place);
      props.locationRef.current = autoCompleteRef.current;
    });
  }

  async function handlePlaceSelect(updateQuery: any) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  const handleChange = (e: any) => {
    setQuery(e)
  }


  const inputValidationCheck = (ac: any) => {
    const currentValue = ac.current.value;
    return currentValue !== "" && currentValue !== ac.initialValue;
  };

  const inputHandler = () => {
    props.setIsFlagUpdate(inputValidationCheck(autoCompleteRef));
    return
  }

  return (
    <div className="SearchLoc">
      <input
        className="search-input-field SearchLoc__inputField"
        ref={autoCompleteRef}
        onChange={(event) => {
          props.setIsFlagUpdate && inputHandler()
          handleChange(event.target.value)
        }
        }
        placeholder="Search by location"
        value={query}
      />
    </div>
  );
}

export default SearchLocationInput;
