import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import NoData from '../../../utils/NoData/NoData'
import moment from 'moment'
import './UsersDetails.scss'
import CustomAccordian from '../../../utils/CustomAccordian/CustomAccordian'
function UserDetails(props: any) {
    const { open, setOpen, data } = props

    const statusHandler = (statusCode: number) => {
        const statusList: any = {
            1: {
                activeColor: 'greenColor',
                status: 1,
                activeTag: 'Inventory',
                color: 'green',
            },
            2: {
                activeColor: 'greenColor',
                status: 2,
                activeTag: 'warehouse',
                color: 'green',
            },
            3: {
                activeColor: 'orangeColor',
                status: 3,
                activeTag: 'Carton',
                color: 'orange',
            },
            4: {
                activeColor: 'redColor',
                status: 4,
                activeTag: 'vendor',
                color: 'red',
            },
            // 5: {
            //     activeColor: 'brownColor',
            //     status: 5,
            //     activeTag: 'User',
            //     color: 'saddlebrown',
            // },
            // 6: {
            //     activeColor: 'redColor',
            //     status: 6,
            //     activeTag: 'DropStation',
            //     color: 'red',
            // },
        }

        const status = statusList[statusCode]
        if (status) {
            return status
        } else {
            return { activeColor: '', activeTag: '', status: '', color: '' }
        }
    }

    const renderStatus = (item: any) => {
        if (item) {
            const { status, activeColor } = statusHandler(item.status)
            return (
                <p className={`${activeColor} activePara`}>
                    <span className="status">
                        <b>Status: </b>
                    </span>
                    <span className="textStatus">
                        {status === 1 && 'Box is in Inventory'}
                        {status === 2 && `Box is in warehouse: ${item.whsName}`}
                        {status === 3 &&
                            `Box is with Vendor: ${item.vendorName}`}
                        {status === 4 && `Box is with user: ${item.userName}`}
                    </span>
                </p>
            )
        }

        return <></>
    }

    return (
        <>
            <div>
                <Offcanvas
                    show={open}
                    onHide={() => setOpen(false)}
                    placement="end"
                    className="Modal_head"
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title className="wrh__title drp_title customSlider__title">
                            Details
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <hr />
                    <Offcanvas.Body className="Main_Body">
                        {data.length > 0 ? (
                            data.map((item: any, i: number) => {
                                return (
                                    <>
                                        <CustomAccordian
                                            cName={`${
                                                statusHandler(item?.status)
                                                    ?.activeColor
                                            }`}
                                            HeaderName={
                                                <div key={i}>
                                                    <div className="Main_Content">
                                                        <div className="Image_part">
                                                            <div className="Details">
                                                                <div
                                                                    style={{
                                                                        border: `2px solid ${
                                                                            statusHandler(
                                                                                item?.status
                                                                            )
                                                                                ?.color
                                                                        }`,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            item?.boxIcon
                                                                        }
                                                                        className="image"
                                                                        alt="icon"
                                                                    />
                                                                    <p
                                                                        style={{
                                                                            backgroundColor: `${
                                                                                statusHandler(
                                                                                    item.status
                                                                                )
                                                                                    ?.color
                                                                            }`,
                                                                        }}
                                                                    >
                                                                        <b className="boxClsName">
                                                                            {
                                                                                item?.boxId
                                                                            }
                                                                        </b>
                                                                    </p>
                                                                </div>
                                                                <p>
                                                                    {
                                                                        item?.userAccount
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="Details">
                                                                <img
                                                                    src={
                                                                        item?.inventoryIcon
                                                                    }
                                                                    className="image"
                                                                    alt=".."
                                                                />
                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            1 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {item?.inventoryTime &&
                                                                        moment(
                                                                            item?.inventoryTime
                                                                        ).format(
                                                                            'DD-MMM-YYYY h:mm a'
                                                                        )}
                                                                </p>
                                                            </div>
                                                            <div className="Details">
                                                                <img
                                                                    src={
                                                                        item?.whsIcon
                                                                    }
                                                                    className="image"
                                                                    alt=".."
                                                                />
                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            2 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {
                                                                        item?.whsName
                                                                    }
                                                                </p>
                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            2 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {item?.whsCheckOutTime &&
                                                                        moment(
                                                                            item?.whsCheckOutTime
                                                                        ).format(
                                                                            'DD-MMM-YYYY h:mm a'
                                                                        )}
                                                                </p>
                                                            </div>
                                                            <div className="Details">
                                                                <img
                                                                    src={
                                                                        item?.vendorIcon
                                                                    }
                                                                    className="image"
                                                                    alt=".."
                                                                />
                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            3 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {
                                                                        item?.vendorName
                                                                    }
                                                                </p>
                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            3 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {item?.vendorCheckOutTime &&
                                                                        moment(
                                                                            item?.vendorCheckOutTime
                                                                        ).format(
                                                                            'DD-MMM-YYYY h:mm a'
                                                                        )}
                                                                </p>

                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            3 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {
                                                                        item?.orderNumber
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="Details">
                                                                <img
                                                                    src={
                                                                        item?.userIcon
                                                                    }
                                                                    className="image"
                                                                    alt=".."
                                                                />
                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            4 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {
                                                                        item?.userName
                                                                    }
                                                                </p>
                                                                <p
                                                                    className={` ${
                                                                        item.status ===
                                                                            4 &&
                                                                        'orangePara'
                                                                    }`}
                                                                >
                                                                    {item?.userCheckOutTime &&
                                                                        moment(
                                                                            item?.userCheckOutTime
                                                                        ).format(
                                                                            'DD-MMM-YYYY h:mm a'
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            key={i}
                                            showFilterIcon={false}
                                        >
                                            <>
                                                <iframe
                                                    width="100%"
                                                    height="300"
                                                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${item?.vendor?.geo?.coordinates?.[0]}+${item?.vendor?.geo?.coordinates?.[1]}&zoom=17`}
                                                >
                                                    <a href="https://www.gps.ie/farm-gps/">
                                                        GPS Navigation
                                                    </a>
                                                </iframe>
                                            </>
                                        </CustomAccordian>
                                        {renderStatus(item)}
                                    </>
                                )
                            })
                        ) : (
                            <NoData content="No Details Found" />
                        )}
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    )
}

export default UserDetails
