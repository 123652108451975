import React, { useEffect, useState } from 'react'
import { Form, Offcanvas } from 'react-bootstrap'
import CustomButton from '../../../utils/CustomButton/CustomButton'
import { HttpCallGet, HttpCallPost } from '../../../utils/Services/NetworkCall'
import { ToastOnFailure, ToastOnSuccess } from '../../../utils/Toast/Toast'
import { HttpCallDelete } from '../../../utils/Services/NetworkCall'
import './restslider.style.scss'
interface Iprops {
    show: boolean
    setShow: any
    activeId: string
    restList: any
    setRestList: any
    restActiveList: any
    setRestActiveList: any
    currentPage: number
    pageSize: number
    isFlag: boolean
    setIsFlag: any
}
const RestSlider: React.FC<Iprops> = ({
    show,
    setShow,
    restList,
    setRestList,
    restActiveList,
    setRestActiveList,
    pageSize,
    currentPage,
    activeId,
    isFlag,
    setIsFlag,
}) => {
    const [catDetails, setCatDetails] = useState({
        _id: '',
    })

    const fetchActiveList = () => {
        if (activeId) {
            HttpCallGet(
                `admin/vendorCatBoxType/${activeId}/details`,
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setRestActiveList(
                        res.data.data.length > 0
                            ? res.data.data[0]?.boxTypes
                            : []
                    )
                    setCatDetails(
                        res.data.data.length > 0
                            ? res.data.data[0]
                            : {
                                  _id: '',
                              }
                    )
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
    }

    const fetchLists = () => {
        HttpCallGet(
            `admin/ContainerType/list?currentPage=1&pageSize=1000`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setRestList(res.data.data)
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    const handleCheckRestaurants = (item: any, event: any) => {
        const { id, checked } = event.target
        if (checked) {
            const charitynew = {
                name: item.title,
                _id: item._id,
            }
            setRestActiveList([...(restActiveList || []), charitynew])
        } else {
            setRestActiveList(
                restActiveList.filter((checkedId: any) => checkedId._id !== id)
            )
        }
    }

    const restChecked = function (item: any, list: any) {
        if (list) {
            const index = list.findIndex(function (val: any) {
                return val._id == item?._id
            })
            return index > -1
        }
    }

    const updateDetails = () => {
        HttpCallPost(
            `admin/vendorCatBoxType/${activeId}/assign`,
            {
                boxTypes: restActiveList,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                setIsFlag(!isFlag)

                setShow(false)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }
    // HttpCallDelete

    const deleteCatBoxType = () => {
        console.log(catDetails)
        HttpCallDelete(
            `admin/vendorCatBoxType/${catDetails?._id}/delete`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                setIsFlag(!isFlag)
                setShow(false)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    useEffect(() => {
        fetchActiveList()
        fetchLists()
        if (catDetails) {
            setRestActiveList([])
        }
    }, [])

    return (
        <div>
            <Offcanvas
                show={show}
                onHide={() => setShow(false)}
                placement="end"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title className={`restSlider`}>
                        Assign Container Types
                    </Offcanvas.Title>
                    <CustomButton
                        btnName="Save"
                        btnEvt={() => updateDetails()}
                    />
                    <CustomButton
                        clsName={`${!catDetails && `restSlider__disbleBtn`}`}
                        btnName="Delete"
                        btnEvt={() => deleteCatBoxType()}
                    />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>Container Type</p>
                    {restList.length > 0 &&
                        restList.map((item: any) => {
                            return (
                                <div key={item._id}>
                                    {item.itemType === 'CONTAINER' && (
                                        <Form.Check
                                            label={item.title}
                                            name="group4"
                                            type="checkbox"
                                            id={item._id}
                                            checked={restChecked(
                                                item,
                                                restActiveList
                                            )}
                                            onChange={(e: any) =>
                                                handleCheckRestaurants(item, e)
                                            }
                                        />
                                    )}
                                </div>
                            )
                        })}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default RestSlider
