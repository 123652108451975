import React, { useEffect, useState } from 'react'
import './dashboard.style.scss'
import Donutchart from '../../utils/Chart/Donutchart'
import PendingDonutchart from '../../utils/Chart/PendingDonutchart'
import { HttpCallGet } from '../../utils/Services/NetworkCall'
import Loader from '../../utils/Loader/Loader'
import BarChart from '../../utils/Chart/BarChart'
import {
    DASHBOARD_BARCHART,
    DASHBOARD_PAI_CHART,
    DASHBOARD_PENDING,
} from '../../utils/Services/EndPoints'
import SubHeader from '../../component/subHeader/SubHeader'
import config from '../../configs/config'
import { capitalizeWords } from '../../utils/util'
// interface MyComponentProps {
//     HeaderTitle: string
// }

function Dashboard(props: any) {
    const { activeUser, setActiveUser } = props
    const token = sessionStorage.getItem('AUTH_TOKEN')
    const [barChart, setBarChart] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [inventoryData, setInventoryData] = useState<any>([])
    const [boxesWithUser, setBoxesWithUser] = useState<any>([])
    const [boxesWithRestaurant, setBoxesWithRestaurant] = useState<any>([])
    const [pending, setPending] = useState<any>(0)

    const getInitialData = async () => {
        try {
            setLoading(true)
            Barchart_Data()
            Inventory_Data()
            User_Data()
            Boxes_With_Restaurant()
            Pending_Data()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log('error', error)
        }
    }

    // Bar chart data

    const Barchart_Data = async () => {
        try {
            const response: any = await HttpCallGet(
                `${DASHBOARD_BARCHART}?locationId=${activeUser}`,
                token
            )
            setBarChart(response?.data?.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const modifyDataHandler = (data: any) => {
        return data.length > 0
            ? data.map((item: any) => ({
                  label: capitalizeWords(item.label),
                  data: item.data,
              }))
            : []
    }

    // Inventory data

    const Inventory_Data = async () => {
        try {
            const response: any = await HttpCallGet(
                `${DASHBOARD_PAI_CHART}?locationId=${activeUser}&type=INVENTORY`,
                token
            )
            if (response.data) {
                setInventoryData(modifyDataHandler(response.data.data))
            } else {
                alert('data not found')
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    // User_Data

    const User_Data = async () => {
        try {
            const response: any = await HttpCallGet(
                `${DASHBOARD_PAI_CHART}?locationId=${activeUser}&type=USER`,
                token
            )
            setBoxesWithUser(
                response ? modifyDataHandler(response.data.data) : []
            )
        } catch (error) {
            console.log('error', error)
        }
    }

    // Boxes_With_Restaurant

    const Boxes_With_Restaurant = async () => {
        try {
            const response: any = await HttpCallGet(
                `${DASHBOARD_PAI_CHART}?locationId=${activeUser}&type=VENDOR`,
                token
            )

            setBoxesWithRestaurant(modifyDataHandler(response.data.data))
        } catch (error) {
            console.log('error', error)
        }
    }
    const Pending_Data = async () => {
        try {
            const response: any = await HttpCallGet(
                `${DASHBOARD_PENDING}?locationId=${activeUser}`,
                token
            )
            setPending(response.data.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const dataHandler = (data: any, msg: string, clsName?: string) => {
        const newCls: string = clsName || 'textMsg'
        return data?.length <= 0 && <p className={newCls}>{msg}</p>
    }

    const dataLengthChecker = (data: any) => {
        return data?.length > 0 && true
    }

    useEffect(() => {
        if (activeUser !== 2 && activeUser !== 0) {
            getInitialData()
        }
    }, [activeUser])

    const lowerCaseHandler = (str: string) => {
        return str.toLowerCase()
    }

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="SidebarSec">
                    <div className="dashboardSec">
                        <SubHeader HeaderTitle="Dashboard" />
                        <div className="wrapper-md hbox">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="hbox__panel-heading font_bold">
                                            <p>Boxes in Inventory</p>
                                        </div>
                                        <div className="chart_div">
                                            {dataLengthChecker(
                                                inventoryData
                                            ) && (
                                                <Donutchart
                                                    data={
                                                        inventoryData
                                                            ? inventoryData
                                                            : []
                                                    }
                                                />
                                            )}

                                            {dataHandler(
                                                inventoryData,
                                                'No boxes in inventory.'
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="hbox__panel-heading font_bold">
                                            <p>Boxes with User</p>
                                        </div>
                                        <div className="chart_div">
                                            {dataLengthChecker(
                                                boxesWithUser
                                            ) && (
                                                <Donutchart
                                                    data={
                                                        boxesWithUser
                                                            ? boxesWithUser
                                                            : []
                                                    }
                                                />
                                            )}

                                            {dataHandler(
                                                boxesWithUser,
                                                'No boxes with user.'
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="hbox__panel-heading font_bold">
                                            <p>
                                                Boxes with{' '}
                                                {lowerCaseHandler(
                                                    config.venderTitle
                                                )}
                                            </p>
                                        </div>
                                        <div className="chart_div">
                                            {dataLengthChecker(
                                                boxesWithRestaurant
                                            ) && (
                                                <Donutchart
                                                    data={
                                                        boxesWithRestaurant
                                                            ? boxesWithRestaurant
                                                            : []
                                                    }
                                                />
                                            )}
                                            {dataHandler(
                                                boxesWithRestaurant,
                                                'No boxes with vendor.'
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="hbox__panel-heading font_bold">
                                            <p>On Board Users</p>
                                        </div>

                                        <div className="chart_div">
                                            <div>
                                                {' '}
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="onboard_User_details">
                                                                <p>
                                                                    Monthly
                                                                    Users-
                                                                </p>
                                                                <div className="onboard_User_details_subheading">
                                                                    <p>
                                                                        Users:
                                                                        {
                                                                            barChart
                                                                                ?.users
                                                                                ?.monthly
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {lowerCaseHandler(
                                                                            config.venderTitle
                                                                        )}
                                                                        :
                                                                        {
                                                                            barChart
                                                                                ?.vendors
                                                                                ?.monthly
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="onboard_User_details">
                                                                <p>
                                                                    Weekly
                                                                    Users-
                                                                </p>
                                                                <div className="onboard_User_details_subheading">
                                                                    <p>
                                                                        {' '}
                                                                        Users:
                                                                        {
                                                                            barChart
                                                                                ?.users
                                                                                ?.weekly
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {lowerCaseHandler(
                                                                            config.venderTitle
                                                                        )}
                                                                        :
                                                                        {
                                                                            barChart
                                                                                ?.vendors
                                                                                ?.weekly
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="onboard_User_details">
                                                                <p>
                                                                    {`Today's Users-`}
                                                                </p>
                                                                <div className="onboard_User_details_subheading">
                                                                    <p>
                                                                        Users:
                                                                        {
                                                                            barChart
                                                                                ?.users
                                                                                ?.today
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {lowerCaseHandler(
                                                                            config.venderTitle
                                                                        )}
                                                                        :
                                                                        {
                                                                            barChart
                                                                                ?.vendors
                                                                                ?.today
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <BarChart data={barChart} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 center">
                                        <div className="hbox__panel-heading font_bold">
                                            <p>Pending Orders</p>
                                        </div>
                                        <div className="chart_div">
                                            {pending !== 0 && (
                                                <PendingDonutchart
                                                    pending={pending}
                                                />
                                            )}
                                            {pending === 0 && (
                                                <p className="textMsg">
                                                    Pending Data : 0
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Dashboard
