import React, { useEffect, useMemo, useState } from 'react'
import CustomTable from '../../utils/CustomTable/CustomTable'
import { HttpCallGet } from '../../utils/Services/NetworkCall'
import Loader from '../../utils/Loader/Loader'
import Pagination from 'react-js-pagination'
import './Location.style.scss'
import SubHeader from '../../component/subHeader/SubHeader'
import CustomButton from '../../utils/CustomButton/CustomButton'
import CustomSlider from '../../utils/CustomSlider/CustomSlider'
import AddLocation from './addLocation/AddLocation'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'

function Locations(props: any) {
    const [locationData, setLocationData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [data, setData] = useState<any>({
        data: {},
        mode: '',
    })
    const [flag, setFlag] = useState<boolean>(false)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const fetchLocationData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/getLocationList?currentPage=${currentPage}&pageSize=${pageSize}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setLocationData(dataHandler(res.data.data))
                setTotal(res.data.totalRecords)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const dataHandler = (data: any) => {
        return (
            data.length > 0 &&
            data.map((item: any) => ({
                ...item,
                terms: item?.termsAndCondition ? true : false,
            }))
        )
    }

    const fetchViewData = async (id: string, actionType: string) => {
        try {
            setLoading(true)
            const response: any = await HttpCallGet(
                `admin/getLocationById?_id=${id}`,
                sessionStorage.getItem('AUTH_TOKEN')
            )
            setData({
                data: response.data.data[0],
                mode: actionType,
            })
            setShow(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const booleanHandler = (value: boolean) => {
        return value === true ? 'Yes' : 'No'
    }
    const arrayHandler = (value: any) => {
        return value?.length > 0 ? `Yes` : `No`
    }

    const termsHandler = (value: string) => {
        return value ? `Yes` : `No`
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (item: any) => {
                    return (
                        <div className="Business_Name">
                            <div>{item.cell?.value}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Card Requried',
                accessor: 'isCardRequired',
                Cell: (item: any) => {
                    return (
                        <div className="Business_Name">
                            <div>{booleanHandler(item.cell?.value)}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Domain Restrictions',
                accessor: 'isDomainRestriction',
                Cell: (item: any) => {
                    return (
                        <div className="Business_Name">
                            <div>{booleanHandler(item.cell?.value)}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Has Additional Fields',
                accessor: 'extraFields',
                Cell: (item: any) => {
                    return (
                        <div className="Business_Name">
                            <div>{arrayHandler(item.cell?.value)}</div>
                        </div>
                    )
                },
            },
            {
                Header: 'Terms & Conditions',
                accessor: 'terms',
                Cell: (item: any) => {
                    return (
                        <div className="Business_Name">
                            <div>{termsHandler(item.cell.value)}</div>
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'dev',
                Cell: (item: any) => {
                    return (
                        <div className="view">
                            <CustomButton
                                btnName="View"
                                btnEvt={() =>
                                    fetchViewData(
                                        item.cell?.row?.original?._id,
                                        'view'
                                    )
                                }
                            />
                        </div>
                    )
                },
            },
        ],
        []
    )

    const showHandler = (actionType: string) => {
        setData({
            data: {},
            mode: actionType,
        })
        setShow(true)
    }

    useEffect(() => {
        fetchLocationData()
    }, [props.activeUser, flag, currentPage, pageSize])

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="locationComponent">
                    <SubHeader HeaderTitle="Locations" isShow={false}>
                        <div className="locationDiv">
                            <CustomButton
                                btnName="Add Location"
                                clsName="locationBtn"
                                btnEvt={() => showHandler('add')}
                            />
                        </div>
                    </SubHeader>
                    <div className="location_table">
                        <CustomTable
                            msg="No location found"
                            columns={columns}
                            data={locationData.length > 0 ? locationData : []}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="table__pagination__container">
                            {showPagination(total, pageSize) && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {showPageSize(total) && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="perPageItem">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <CustomSlider
                        show={show}
                        setShow={setShow}
                        btnEventName="Close"
                        Evt={() => setShow(false)}
                        hideHr={true}
                        activeTitle={
                            data.mode == 'add'
                                ? 'Add Location'
                                : 'View Location'
                        }
                    >
                        <AddLocation
                            flag={flag}
                            setFlag={setFlag}
                            setShow={setShow}
                            data={data.data}
                            mode={data.mode}
                        />
                    </CustomSlider>
                </div>
            )}
        </>
    )
}

export default Locations
