import React, { useEffect } from 'react';
import './App.css';
import RoutePath from './routes/AppRoute';
import "bootstrap/dist/css/bootstrap.css";
import config from './configs/config';
function App() {

  useEffect(() => {
    const favicon:any = document.querySelector('link[rel="icon"]');
    favicon.href = config.faviconPath;
    window.document.title = config.projectTitle;
  }, []);

  return (
    <div className="App">
      <RoutePath />
    </div>
  );
}

export default App;
