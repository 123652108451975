import React from 'react'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import './addlocation.style.scss'
import { HttpCallPost } from '../../../utils/Services/NetworkCall'
import { ToastOnFailure, ToastOnSuccess } from '../../../utils/Toast/Toast'

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required('Location name is required')
        .matches(
            /^[A-Za-z0-9\s]*$/,
            'Only alphabet characters, spaces, and numbers are allowed'
        )
        .max(30, 'Location name must be at most 30 characters'),
    isCardRequired: Yup.boolean().required('This field is required'),
    isDomainRestriction: Yup.boolean().required('This field is required'),
    description: Yup.string().max(
        500,
        'Description must be at most 500 characters'
    ),
    extraFields: Yup.array().test({
        name: 'extraFields',
        test: function (value) {
            if (value && value.length > 0) {
                return value.every(
                    (field) =>
                        field && field.trim() !== '' && field.length <= 50
                )
            }
            return true
        },
        message:
            'Extra fields cannot be empty and must not exceed 50 characters',
    }),

    domain: Yup.array().test({
        test: function (value: any) {
            const isDomainRestriction = this.parent.isDomainRestriction

            if (isDomainRestriction && (!value || value.length === 0)) {
                return false
            }

            if (!isDomainRestriction && (!value || value.length === 0)) {
                return true // No validation needed for non-restricted domain
            }

            return value.every((domain: any) => !!domain && !!domain.trim())
        },
        message: function (errors) {
            if (errors[0] === false) {
                return 'Domain is required'
            } else {
                return 'Domain field can not be empty' // "Domain field can not be empty" error message
            }
        },
    }),
    termsAndCondition: Yup.string().max(
        5000,
        'TermsAndCondition must be at most 5000 characters'
    ),
})

interface Iprops {
    flag: boolean
    setFlag: any
    setShow: any
    data: any
    mode?: string
}

const AddLocation: React.FC<Iprops> = ({
    flag,
    setFlag,
    setShow,
    data,
    mode,
}) => {
    const initialValues = {
        name: data?.name ? data.name : '',
        isCardRequired: data?.isCardRequired ? data.isCardRequired : '',
        isDomainRestriction: data?.isDomainRestriction
            ? data.isDomainRestriction
            : '',
        domain: data?.domain ? data.domain : [],
        description: data?.description ? data.description : '',
        extraFields: data?.extraFields ? data.extraFields : [],
        termsAndCondition: data?.termsAndCondition
            ? data.termsAndCondition
            : '',
    }

    const checkMode = (mode: any) => {
        return mode === 'add' ? true : false
    }
    const toBool = (string: any) => {
        if (string === 'true' || string === true) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        console.log(values)
        try {
            setSubmitting(true)

            const response: any = await HttpCallPost(
                'admin/addLocation',
                {
                    name: values.name,
                    isCardRequired: toBool(values.isCardRequired),
                    isDomainRestriction: toBool(values.isDomainRestriction),
                    domain: toBool(values.isDomainRestriction)
                        ? values.domain
                        : undefined,
                    termsAndCondition: values.termsAndCondition,
                    description: values.description,
                    extraFields:
                        values.extraFields.length > 0
                            ? values.extraFields
                            : undefined,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
            setSubmitting(false)
            if (response.data.status == 1) {
                ToastOnSuccess(response?.data?.message)
                setFlag(!flag)
                setShow(false)
            } else {
                ToastOnFailure(response?.data.message)
            }
        } catch (error: any) {
            setSubmitting(false)
            ToastOnFailure(error?.response?.message)
            console.log(error)
        }
    }

    const domainInputValidator = (values: any) => {
        const lastFieldIndex = values.domain.length - 1
        return lastFieldIndex < 0 || values.domain[lastFieldIndex].trim() !== ''
    }

    return (
        <div className={`addLocation ${!checkMode(mode) && `readOnly`}`}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="name">
                                Location Name
                                <span className="text-danger">*</span>
                            </label>
                            <Field
                                type="text"
                                name="name"
                                className="form-control"
                            />
                            <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description </label>
                            <Field
                                as="textarea"
                                name="description"
                                className={`form-control ${
                                    !checkMode(mode) && `viewLocationDetails`
                                }`}
                                readOnly={!checkMode(mode)}
                            />
                            {checkMode(mode) && (
                                <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="text-danger"
                                />
                            )}
                        </div>

                        <div className="form-group form-group1">
                            <label>
                                Card Required
                                <span className="text-danger">*</span>
                            </label>
                            <div className="radio-group">
                                {checkMode(mode) ? (
                                    <>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isCardRequired"
                                                value="true"
                                            />
                                            <span className="m-2">Yes</span>
                                        </label>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isCardRequired"
                                                value="false"
                                            />
                                            <span className="m-2">No</span>
                                        </label>
                                    </>
                                ) : (
                                    <>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isCardRequired"
                                                value="true"
                                                checked={
                                                    values.isCardRequired ==
                                                    true
                                                }
                                            />
                                            <span className="m-2">Yes</span>
                                        </label>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isCardRequired"
                                                value="false"
                                                checked={
                                                    values.isCardRequired ==
                                                    false
                                                }
                                            />
                                            <span className="m-2">No</span>
                                        </label>
                                    </>
                                )}
                            </div>
                            <ErrorMessage
                                name="isCardRequired"
                                component="div"
                                className="text-danger"
                            />
                        </div>

                        <div className="form-group form-group1">
                            <label>
                                Domain Restriction Required
                                <span className="text-danger">*</span>
                            </label>
                            <div className="radio-group">
                                {checkMode(mode) ? (
                                    <>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isDomainRestriction"
                                                value="true"
                                                onClick={() =>
                                                    values.domain.push('')
                                                }
                                            />
                                            <span className="m-2">Yes</span>
                                        </label>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isDomainRestriction"
                                                value="false"
                                                onClick={() =>
                                                    (values.domain.length = 0)
                                                }
                                            />
                                            <span className="m-2">No</span>
                                        </label>
                                    </>
                                ) : (
                                    <>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isDomainRestriction"
                                                value="true"
                                                checked={
                                                    values.isDomainRestriction ==
                                                    true
                                                }
                                            />
                                            <span className="m-2">Yes</span>
                                        </label>
                                        <label>
                                            <Field
                                                type="radio"
                                                name="isDomainRestriction"
                                                value="false"
                                                checked={
                                                    values.isDomainRestriction ==
                                                    false
                                                }
                                            />
                                            <span className="m-2">No</span>
                                        </label>
                                    </>
                                )}
                            </div>

                            <ErrorMessage
                                name="isDomainRestriction"
                                component="div"
                                className="text-danger"
                            />
                        </div>

                        {toBool(values.isDomainRestriction) && (
                            <div className="form-group">
                                <FieldArray name="domain">
                                    {({ push, remove }) => (
                                        <div>
                                            {values.domain.map(
                                                (email: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        className="domain-field"
                                                    >
                                                        <Field
                                                            type="text"
                                                            name={`domain.${index}`}
                                                            className="form-control"
                                                        />
                                                        {checkMode(mode) &&
                                                        values.domain[index] ? (
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    remove(
                                                                        index
                                                                    )
                                                                }
                                                                className="remove-btn"
                                                            >
                                                                x
                                                            </button>
                                                        ) : (
                                                            checkMode(mode) &&
                                                            values.domain
                                                                .length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        remove(
                                                                            index
                                                                        )
                                                                    }
                                                                    className="remove-btn"
                                                                >
                                                                    x
                                                                </button>
                                                            )
                                                        )}
                                                    </div>
                                                )
                                            )}
                                            <ErrorMessage
                                                name="domain"
                                                component="div"
                                                className="text-danger"
                                            />

                                            {checkMode(mode) &&
                                                values.isDomainRestriction && (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (
                                                                domainInputValidator(
                                                                    values
                                                                )
                                                            ) {
                                                                push('')
                                                            }
                                                        }}
                                                        className="add-btn"
                                                    >
                                                        Add Domain
                                                    </button>
                                                )}
                                        </div>
                                    )}
                                </FieldArray>
                            </div>
                        )}

                        <div className="form-group">
                            <label htmlFor="extraFields">Extra Fields</label>
                            <FieldArray name="extraFields">
                                {({ push, remove }) => (
                                    <div>
                                        {values.extraFields.map(
                                            (field: any, index: any) => (
                                                <div
                                                    key={index}
                                                    className="extra-field"
                                                >
                                                    <Field
                                                        type="text"
                                                        name={`extraFields.${index}`}
                                                        className="form-control"
                                                    />
                                                    {checkMode(mode) && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                            className="remove-btn"
                                                        >
                                                            x
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        )}
                                        <ErrorMessage
                                            name="extraFields"
                                            component="div"
                                            className="text-danger"
                                        />
                                        {checkMode(mode) && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const lastFieldIndex: any =
                                                        values.extraFields
                                                            .length - 1
                                                    if (
                                                        lastFieldIndex < 0 ||
                                                        values.extraFields[
                                                            lastFieldIndex
                                                        ].trim() !== ''
                                                    ) {
                                                        push('')
                                                    }
                                                }}
                                                className="add-btn"
                                            >
                                                Add Field
                                            </button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </div>

                        <div className="form-group">
                            <label htmlFor="termsAndCondition">
                                Terms and Conditions
                                {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                                as="textarea"
                                name="termsAndCondition"
                                className={`form-control ${
                                    !checkMode(mode) && `viewLocationDetails`
                                }`}
                                readOnly={!checkMode(mode)}
                            />
                            {checkMode(mode) && (
                                <ErrorMessage
                                    name="termsAndCondition"
                                    component="div"
                                    className="text-danger"
                                />
                            )}
                        </div>
                        {checkMode(mode) && (
                            <button
                                type="submit"
                                className="submit-btn"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submiting' : `Submit`}
                            </button>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AddLocation
