import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'

export default function BarChart(props: any) {
    const [users, setUsers] = useState<any>(null)
    const [vendors, setVendors] = useState<any>(null)

    const getData = () => {
        setUsers(props?.data?.users)
        setVendors(props?.data?.vendors)
    }

    useEffect(() => {
        getData()
    }, [props?.data])
    return (
        <div>
            <div className="container-fluid mb-3">
                <Chart
                    type="bar"
                    width={1120}
                    height={260}
                    series={[
                        {
                            name: 'Users',
                            data: [users?.monthly, users?.weekly, users?.today],

                            color: '#3399cc',
                        },
                        {
                            name: 'Restuarant',
                            data: [
                                vendors?.monthly,
                                vendors?.weekly,
                                vendors?.today,
                            ],
                            color: '#80c65a',
                        },
                    ]}
                    options={{
                        chart: {
                            stacked: true,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                                columnWidth: '100%',
                            },
                        },
                        stroke: {
                            width: 1,
                        },
                        xaxis: {
                            categories: ['Monthly', 'Weekly', 'TOday'],
                        },
                        yaxis: {},
                        legend: {
                            position: 'right',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        grid: {
                            show: true,
                            xaxis: {
                                lines: {
                                    show: false,
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: false,
                                },
                            },
                        },
                        tooltip: {
                            enabled: false, // Disable the tooltip display on hover
                        },
                    }}
                />
            </div>
        </div>
    )
}
