import React from 'react'
import { useTable, useSortBy, useFlexLayout } from 'react-table'
import NoData from '../NoData/NoData'
import './Custom.style.scss'
import downArrow from '../../assets/image/downArrow.png'
import CustomTooltip from '../ToolTip/ToolTips'

interface Iprops {
    columns: any
    data: any
    msg: string
}

const CustomTable: React.FC<Iprops> = ({ columns, data, msg }) => {
    // add any additional options for your table here
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
            },
            useSortBy
        )

    function isStringValid(inputString: any) {
        const urlRegex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i

        if (urlRegex.test(inputString)) {
            return false
        }
        return true
    }

    const toolTipShow = (cellValue: any) => {
        // !Number(cellValue) &&
        return typeof cellValue !== 'object' && cellValue
    }

    const cellHandler = (cellValue?: any, cell?: any) => {
        if (typeof cell === 'string' && isStringValid(cell)) {
            if (cell.length > 25) {
                return (
                    <span className="activeCell" data-tip="">
                        <CustomTooltip content={toolTipShow(cell)}>
                            {cell.substring(0, 25)}...
                        </CustomTooltip>
                    </span>
                )
            }
        }
        return cellValue
    }

    return (
        <div className="m-4 mt-2 table-responsive mainHead">
            {data.length > 0 ? (
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup: any, ind: any) => (
                            <tr
                                key={ind}
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map(
                                    (column: any, index: any) => (
                                        <th
                                            key={index}
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            <div className="theadTitleContent">
                                                <p className="theadTitle">
                                                    {column.render('Header')}
                                                </p>
                                                <span>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <img
                                                                className="downArrow"
                                                                src={downArrow}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <img
                                                                className="downArrow"
                                                                src={downArrow}
                                                                alt=""
                                                            />
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        </th>
                                    )
                                )}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row: any, index: any) => {
                            prepareRow(row)
                            return (
                                <tr key={index} {...row.getRowProps()}>
                                    {row.cells.map((cell: any, index: any) => {
                                        return (
                                            <td
                                                key={index}
                                                {...cell.getCellProps()}
                                            >
                                                {cellHandler(
                                                    cell.render('Cell'),
                                                    cell.value
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : (
                <NoData content={msg} />
            )}
        </div>
    )
}

export default CustomTable
