export function getObjectKeys(obj: any) {
    if (typeof obj !== 'object' || obj === null) {
        return { key: '', value: '' }
    }
    const result: any = {}
    for (const key in obj) {
        result[key] = obj[key]
    }
    return result
}

export const dataHandler = (data: any) => {
    return (
        data.length > 0 &&
        data.map((item: any) => {
            if (item && item.userAdditionField) {
                let keysAndValues: any = {}
                for (let i = 0; i < item.userAdditionField.length; i++) {
                    const key = getObjectKeys(item.userAdditionField[i])
                    keysAndValues = { ...keysAndValues, ...key }
                }
                const newItem = {
                    ...item,
                    box: { name: item.boxName, icon: item.boxIcon },
                    ...keysAndValues,
                }
                return newItem
            } else {
                return {
                    ...item,
                    box: { name: item.boxName, icon: item.boxIcon },
                }
            }
        })
    )
}

export const keyExtracter = (myObject: any) => {
    const keyValuesArray = Object.entries(myObject)
    const keys = keyValuesArray.map(([key, _]) => key)
    const values = keyValuesArray.map(([, value]) => value)
    return { keys: keys.join(''), values: values.join('') }
}
