import React from 'react'
import { formateDate } from '../../../utils/util'
import config from '../../../configs/config'

interface iProps {
    orderHistoryDetails: any
}
const PrintableOrders: React.FC<iProps> = ({ orderHistoryDetails }) => {
    return (
        <div
            id="printableOrders"
            className="printarea"
            style={{ display: 'none' }}
        >
            <style type="text/css">
                {`
          .row {
            box-sizing: border-box;
            clear: both;
          }

          .col-sm-6 {
            width: 50%;
            float: left;
            font-size: 16px;
          }

          .col-sm-6 span {
            font-weight: bold;
          }

          .col-sm-4 {
            width: 33.33%;
            float: left;
          }

          .col-sm-3 {
            width: 20%;
            float: left;
          }
        `}
            </style>

            <div className="row">
                <div className="col-sm-12">
                    <h2>{config.venderTitle} Details</h2>
                </div>
                <div className="col-sm-6">
                    <span>Bussiness Name :</span> {orderHistoryDetails?.orderBy}
                </div>
                <div className="col-sm-6">
                    <span>Email :</span> {orderHistoryDetails?.vendorEmail}
                </div>
                <div className="col-sm-12">
                    <span>Mobile :</span> {orderHistoryDetails?.vendorMobile}
                </div>
                <div className="col-sm-12">
                    <span>Address :</span>{' '}
                    {orderHistoryDetails?.vendorAddress.formattedAddress}
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <h2>Order Details</h2>
                </div>
                <div className="col-sm-6">
                    <span>Order Number :</span>{' '}
                    {orderHistoryDetails?.orderNumber}
                </div>
                <div className="col-sm-6">
                    <span>Order Date :</span>{' '}
                    {formateDate(orderHistoryDetails?.orderDate)}
                </div>
                <div className="col-sm-6">
                    <span>Order Invoice :</span>{' '}
                    {formateDate(orderHistoryDetails?.invoice)}
                </div>
                <div className="col-sm-6">
                    <span>Total Number of boxes :</span>{' '}
                    {orderHistoryDetails?.totalBoxAssigned}
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <h2 style={{ paddingTop: '20px', clear: 'both' }}>
                        Boxes Detail
                    </h2>
                </div>
                {orderHistoryDetails?.orderedBoxes.map(
                    (orders: any, index: any) => (
                        <div className="row" key={index}>
                            <h4 style={{ paddingTop: '20px', clear: 'both' }}>
                                <b>{orders.boxTitle}</b>
                            </h4>
                            {orders.assignedBoxes.map(
                                (orderIds: any, innerIndex: any) => (
                                    <div className="col-sm-3" key={innerIndex}>
                                        <label>{orderIds}</label>
                                    </div>
                                )
                            )}
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default PrintableOrders
