import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import './firstModel.style.scss'
import { OrderHistoryDateHandler, printOrder, statusHandler } from '../handler'
import CustomButton from '../../../utils/CustomButton/CustomButton'
import OrderModelSecond from '../secondModel/SecondModel'
import { ToastOnFailure, ToastOnSuccess } from '../../../utils/Toast/Toast'
import { HttpCallPost } from '../../../utils/Services/NetworkCall'
import {
    ConfirmDelete,
    ConfirmDeleteWithAction,
} from '../../../utils/ConfirmAlerts'
import PrintableOrders from '../print/Print'

interface OrderModelProps {
    show?: boolean
    setShow?: any
    secondSlider?: any
    setSecondSlider?: any
    data?: any
    boxType?: any
    orderHistoryDetails?: any
    orderDetailTotalValues?: any
    fetchInfoData: any
}

const FirstModel: React.FC<OrderModelProps> = ({
    show,
    setShow,
    orderHistoryDetails,
    secondSlider,
    setSecondSlider,
    orderDetailTotalValues,
    fetchInfoData,
}) => {
    const removeAssignedBox = function (orderD: any) {
        HttpCallPost(
            `admin-order/remove-boxes-from-order`,
            {
                orderId: orderHistoryDetails._id,
                isRemoved: true,
                boxId: orderD.boxId,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                fetchInfoData(orderHistoryDetails._id)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    const getInvoicePDF = (url: any) => {
        return window.open(url, '_blank')
    }

    const redirectionHandler = (
        showModel: boolean,
        flag: string,
        item: any
    ) => {
        setSecondSlider({
            ...secondSlider,
            isActive: showModel,
            flag: flag,
            data: item,
        })
    }

    const completeOrder = function (status: any) {
        if (status.status == 3) {
            const isvalid = validateForm()
            if (isvalid) {
                changeOrderStatus(status)
            }
        } else {
            changeOrderStatus(status)
        }
    }

    function validateForm() {
        let count = 0
        for (let n = 0; n < orderHistoryDetails.verticalList.length; n++) {
            if (
                orderHistoryDetails.verticalList[n].quantity !=
                orderHistoryDetails.verticalList[n].assigned
            ) {
                count++
            }
        }

        if (count > 0) {
            ToastOnFailure(
                'Error, Ordered quantity and assigned quantity must be equal'
            )
            return false
        } else {
            return true
        }
    }

    const changeOrderStatus = function (status: any) {
        HttpCallPost(
            `admin/vendor/complete-order`,
            {
                status: status.status,
                orderId: secondSlider?.orderDetails?._id,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                fetchInfoData(orderHistoryDetails._id)
                ToastOnSuccess(res.data.message)
            })
            .catch((err) => {
                ToastOnFailure(err.message)
            })
    }

    const printStatusHandler = (details: any) => {
        return details?.isBoxAssigned == false || details?.orderStatus < 2
    }

    return (
        <div>
            <Offcanvas
                className="OrderHistory"
                placement="end"
                show={show}
                onHide={() => setShow(false)}
                size="lg"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title className="title">
                        <span className="commonHeaderTitle">
                            Order Details{' '}
                            {`${sessionStorage.getItem('WR_TITLE')}`}
                        </span>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="orderModalBody">
                        <div className="orderModalHeader">
                            <div className="OrderStatus">
                                <h5 className="title">Order Status:</h5>
                                <p
                                    className={`${
                                        statusHandler(
                                            orderHistoryDetails?.orderStatus
                                        )?.activeClName
                                    }`}
                                >
                                    {
                                        statusHandler(
                                            orderHistoryDetails?.orderStatus
                                        )?.activeBtName
                                    }
                                </p>
                            </div>
                            <div className="OrderNumber">
                                <h5 className="title">Order No:</h5>
                                <p className="orderNum">
                                    #{orderHistoryDetails?.orderNumber}
                                </p>
                            </div>
                            <div className="OrderPrint">
                                <div className="printDiv">
                                    <CustomButton
                                        clsName={`${
                                            printStatusHandler(
                                                orderHistoryDetails
                                            ) && `OrderHistory__printBtn`
                                        } m-1`}
                                        btnName="Print"
                                        btnEvt={() =>
                                            !printStatusHandler(
                                                orderHistoryDetails
                                            ) && printOrder('printableOrders')
                                        }
                                    />
                                    <CustomButton
                                        clsName="m-1"
                                        btnName="Done"
                                        btnEvt={() => setShow(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="orderModalTable table-responsive">
                            <table>
                                <tr>
                                    <th></th>
                                    {orderHistoryDetails?.verticalList.length >
                                        0 &&
                                        orderHistoryDetails?.verticalList.map(
                                            (item: any) => {
                                                return (
                                                    <th key={item._id}>
                                                        {item.boxTitle}
                                                    </th>
                                                )
                                            }
                                        )}
                                </tr>
                                <tr>
                                    <td>Ordered Quantity </td>
                                    {orderHistoryDetails?.verticalList.map(
                                        (item: any) => {
                                            return (
                                                <td key={item._id}>
                                                    {item.quantity}
                                                </td>
                                            )
                                        }
                                    )}
                                </tr>
                                <tr>
                                    <td>Assigned Quantity</td>
                                    {orderHistoryDetails?.verticalList.map(
                                        (item: any) => {
                                            return (
                                                <td key={item._id}>
                                                    {item.assigned}
                                                </td>
                                            )
                                        }
                                    )}
                                </tr>
                                <tr>
                                    <td>Remaining</td>
                                    {orderHistoryDetails?.verticalList.map(
                                        (item: any) => {
                                            return (
                                                <td key={item._id}>
                                                    {item.remaining}
                                                </td>
                                            )
                                        }
                                    )}
                                </tr>

                                {orderHistoryDetails?.horizontalList.map(
                                    (item: any) => (
                                        <tr key={item._id}>
                                            <td>
                                                {item.itemType ===
                                                'CONTAINER' ? (
                                                    <b>{item.boxId}</b>
                                                ) : (
                                                    <div className="OrderHistory__tableItem">
                                                        {item.itemType ===
                                                            'CARTON' && (
                                                            <a
                                                                onClick={() => {
                                                                    redirectionHandler(
                                                                        true,
                                                                        'SHOW_ORDER',
                                                                        item
                                                                    )
                                                                }}
                                                            >
                                                                {item.boxId}
                                                            </a>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            {item.containers.map((it: any) => {
                                                return (
                                                    <td key={it._id}>
                                                        {it.quantity}
                                                    </td>
                                                )
                                            })}
                                            <td>{item.container?.quantity}</td>
                                            {orderHistoryDetails?.orderStatus <
                                                3 && (
                                                <td className="removeButton">
                                                    <a
                                                        onClick={() =>
                                                            ConfirmDelete(
                                                                item,
                                                                removeAssignedBox
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </a>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                )}
                            </table>
                        </div>
                        <div className="AddOrderBtn m-4">
                            {orderHistoryDetails?.orderStatus < 3 && (
                                <CustomButton
                                    btnName="Add to Order"
                                    btnEvt={() => {
                                        redirectionHandler(
                                            true,
                                            'ADD_ORDER',
                                            []
                                        )
                                    }}
                                />
                            )}
                        </div>
                        <div className="orderRecivedTable mt-4">
                            <table className="table">
                                {orderHistoryDetails?.orderStatus >= 3 && (
                                    <tr>
                                        <th>Order Value</th>
                                        <td className="text-right">
                                            ${' '}
                                            {orderDetailTotalValues?.orderValue}
                                        </td>
                                    </tr>
                                )}

                                {orderHistoryDetails?.orderStatus >= 3 && (
                                    <tr>
                                        <th>Tax Rate</th>
                                        <td className="text-right">
                                            {orderDetailTotalValues?.taxRate}%
                                        </td>
                                    </tr>
                                )}

                                {orderHistoryDetails?.orderStatus >= 3 && (
                                    <tr>
                                        <th>Tax Value</th>
                                        <td className="text-right">
                                            ${orderDetailTotalValues?.taxValue}
                                        </td>
                                    </tr>
                                )}

                                {/* {orderHistoryDetails?.orderStatus >= 3 && (
                                    <tr>
                                        <th>Sub Total</th>
                                        <td className="text-right">
                                            $
                                            {
                                                orderDetailTotalValues?.orderValueWithTax
                                            }
                                        </td>
                                    </tr>
                                )} */}

                                {orderHistoryDetails?.orderStatus >= 3 && (
                                    <tr>
                                        <th>Sub Total</th>
                                        <td className="text-right">
                                            $
                                            {
                                                orderDetailTotalValues?.orderValueWithTax
                                            }
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <th>Received</th>
                                    <td className="text-right">
                                        {OrderHistoryDateHandler(
                                            orderHistoryDetails?.orderDate
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <th>Shipping status</th>
                                    {orderHistoryDetails?.orderStatus <= 2 &&
                                        !orderHistoryDetails?.isBoxAssigned && (
                                            <td className="text-right">
                                                pending
                                            </td>
                                        )}

                                    {orderHistoryDetails?.orderStatus == 2 &&
                                        orderHistoryDetails?.isBoxAssigned && (
                                            <td className="text-right Shipping_Status">
                                                <CustomButton
                                                    btnEvt={() => {
                                                        ConfirmDeleteWithAction(
                                                            { status: 3 },
                                                            '',
                                                            'Are you sure, you want to complete Order?',
                                                            'Confirm',
                                                            completeOrder
                                                        )
                                                    }}
                                                    clsName="dispatched"
                                                    btnName="Done"
                                                />
                                            </td>
                                        )}

                                    {orderHistoryDetails?.orderStatus >= 3 && (
                                        <td className="text-right">
                                            {OrderHistoryDateHandler(
                                                orderHistoryDetails?.dispatchDate
                                            )}
                                        </td>
                                    )}
                                </tr>

                                <tr>
                                    <th>Delivery status</th>
                                    {orderHistoryDetails?.orderStatus < 3 && (
                                        <td className="text-right">pending</td>
                                    )}
                                    {orderHistoryDetails?.orderStatus == 3 && (
                                        <td className="text-right">
                                            <CustomButton
                                                btnEvt={() => {
                                                    ConfirmDeleteWithAction(
                                                        { status: 4 },
                                                        '',
                                                        'Are you sure, you want to complete Order?',
                                                        'Confirm',
                                                        completeOrder
                                                    )
                                                }}
                                                clsName="delivered"
                                                btnName="Done"
                                            />
                                        </td>
                                    )}

                                    {orderHistoryDetails?.orderStatus >= 4 && (
                                        <td className="text-right">
                                            {OrderHistoryDateHandler(
                                                orderHistoryDetails?.deliveryDate
                                            )}
                                        </td>
                                    )}
                                </tr>

                                <tr>
                                    <th>
                                        Invoice status
                                        {orderHistoryDetails?.orderStatus >=
                                            5 && (
                                            <a>
                                                <i
                                                    className="fa fa-download invoiceDownload"
                                                    onClick={() =>
                                                        getInvoicePDF(
                                                            orderHistoryDetails.invoiceUrl
                                                        )
                                                    }
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                        )}
                                    </th>
                                    {orderHistoryDetails?.orderStatus < 5 && (
                                        <td className="text-right">pending </td>
                                    )}
                                    {orderHistoryDetails?.orderStatus >= 5 && (
                                        <td className="text-right">
                                            {OrderHistoryDateHandler(
                                                orderHistoryDetails?.invoicedDate
                                            )}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <th>Payment status</th>
                                    {orderHistoryDetails?.orderStatus < 6 && (
                                        <td className="text-right">pending</td>
                                    )}
                                    {orderHistoryDetails?.orderStatus >= 6 && (
                                        <td className="text-right">
                                            {OrderHistoryDateHandler(
                                                orderHistoryDetails?.invoicePaidDate
                                            )}
                                        </td>
                                    )}
                                </tr>
                            </table>
                        </div>
                    </div>
                    <OrderModelSecond
                        show={secondSlider}
                        setShow={setSecondSlider}
                        orderHistoryDetails={orderHistoryDetails}
                        fetchInfoData={fetchInfoData}
                    />
                </Offcanvas.Body>
            </Offcanvas>
            <PrintableOrders orderHistoryDetails={orderHistoryDetails} />
        </div>
    )
}

export default FirstModel
