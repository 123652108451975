import React from 'react'
import './customSlider.style.scss'
import Offcanvas from 'react-bootstrap/Offcanvas'
import CustomButton from '../CustomButton/CustomButton'
interface WrhProps {
    customSlider?: any
    setCustomSlider?: any
    show?: boolean
    setShow: any
    Evt?: any
    children?: any
    activeTitle?: any
    btnEventName?: any
    parrentClassName?: any
    activeTitleClassName?: any
    hideHr?: boolean
}

const CustomSlider: React.FC<WrhProps> = ({
    children,
    show,
    setShow,
    Evt,
    activeTitle,
    activeTitleClassName,
    btnEventName,
    parrentClassName,
    hideHr,
}) => {
    return (
        <div className={parrentClassName}>
            <Offcanvas
                show={show}
                onHide={() => setShow(false)}
                placement="end"
                className="dropStationModal"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title
                        className={`customSlider__title ${activeTitleClassName}`}
                    >
                        {activeTitle}
                    </Offcanvas.Title>
                    <CustomButton btnEvt={() => Evt()} btnName={btnEventName} />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {!hideHr && <hr />}
                    {children}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}
export default CustomSlider
