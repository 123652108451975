import React, { useEffect, useMemo, useState } from 'react'
import Pagination from 'react-js-pagination'
import {
    useTable,
    Column,
    useSortBy,
    HeaderGroup,
    useFlexLayout,
    usePagination,
} from 'react-table'
import { ConfirmDelete } from '../../../utils/ConfirmAlerts'
import NoData from '../../../utils/NoData/NoData'
import './table.style.scss'
import downArrow from '../../../assets/image/downArrow.png'
import { paginationHandler, showPageSize } from '../../../utils/util'

type Props<T extends object> = {
    data: T[]
    columns: readonly Column<T>[]
    width?: string
    height?: string
    headerGroups?: HeaderGroup<T>[]
    updateRow?: any
    deleteRow?: any
    show?: any
    setShow?: any
    currentPage?: any
    setCurrentPage?: any
    pageSize?: any
    setPageSize?: any
    total?: any
    sortValue?: any
}
function WarehouseTable<T extends object>({
    data,
    columns,
    width = '100%',
    height = '100%',
    headerGroups,
    updateRow,
    deleteRow,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    total,
    sortValue,
}: Props<T>): JSX.Element {
    const memoizedColumns = useMemo(() => columns, [columns])
    const memoizedData = useMemo(() => data, [data])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups: tableHeaderGroups,
        rows,
        prepareRow,
    } = useTable<T>(
        {
            columns: memoizedColumns,
            data: memoizedData,
        },
        useSortBy,
        usePagination,
        useFlexLayout
    )

    const mergedHeaderGroups = headerGroups
        ? [...tableHeaderGroups, ...headerGroups]
        : tableHeaderGroups

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const IdentifyAction = (e: any, data?: any) => {
        if (e.target.id === 'wr__edit_btn') {
            return updateRow(e.target.id, data)
        } else if (e.target.id === 'wr__delete_btn') {
            return ConfirmDelete(data._id, deleteRow)
        }
    }

    return (
        <>
            <div className="parrent__table">
                {data.length > 0 ? (
                    <>
                        {' '}
                        <table
                            className="table"
                            {...getTableProps()}
                            style={{ width, height }}
                        >
                            <thead className="table__header">
                                {mergedHeaderGroups.map(
                                    (headerGroup: any, index: any) => (
                                        <tr
                                            key={index}
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column: any, index: any) => (
                                                    <th
                                                        className="table__heading"
                                                        key={index}
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                    >
                                                        {column.render(
                                                            'Header'
                                                        )}
                                                        <span className="table__sort-icon">
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <img
                                                                        className="downArrow"
                                                                        src={
                                                                            downArrow
                                                                        }
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="downArrow"
                                                                        src={
                                                                            downArrow
                                                                        }
                                                                        alt=""
                                                                    />
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </span>
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    )
                                )}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row: any, index: any) => {
                                    prepareRow(row)
                                    return (
                                        <tr
                                            className="table__row"
                                            key={index}
                                            {...row.getRowProps()}
                                        >
                                            {row.cells.map(
                                                (cell: any, ind: any) => (
                                                    <td
                                                        className="table__cell table__edit_btn"
                                                        key={ind}
                                                        {...cell.getCellProps()}
                                                        onClick={(e: any) =>
                                                            IdentifyAction(
                                                                e,
                                                                cell.render(
                                                                    'Cell'
                                                                ).props.row
                                                                    .original
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`table__editButton ${
                                                                !cell.render(
                                                                    'Cell'
                                                                ).props.cell.row
                                                                    .original
                                                                    .canDelete
                                                                    ? `table__dBtnHide`
                                                                    : ``
                                                            }`}
                                                            dangerouslySetInnerHTML={{
                                                                __html: cell.render(
                                                                    'Cell'
                                                                ).props.cell
                                                                    .value,
                                                            }}
                                                        ></div>
                                                    </td>
                                                )
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <div className="NodataWareHouse">
                        <NoData content="No WareHouse Aviliable" />
                    </div>
                )}

                {/* <div className="table__pagination__container">
                    {total >= pageSize && (
                        <>
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={total}
                                onChange={(page) => {
                                    setCurrentPage(page)
                                }}
                            />
                            <select
                                className="pagination__select"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                            >
                                {[10, 20, 30].map((size) => (
                                    <option key={size} value={size}>
                                        Show {size}
                                    </option>
                                ))}
                            </select>
                        </>
                    )}
                </div> */}
                <div className="table__pagination__container tablePagination">
                    <div className="itemPagination">
                        {total >= pageSize && (
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={total}
                                onChange={(page: any) => {
                                    setCurrentPage(page)
                                }}
                            />
                        )}
                        {showPageSize(total) && (
                            <select
                                className="pagination__select"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                            >
                                {[10, 20, 30].map((size) => (
                                    <option key={size} value={size}>
                                        Show {size}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                    <div className="perPageItem pageItem">
                        <p className="itemText">
                            {paginationHandler(currentPage, pageSize, total)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WarehouseTable
