import Offcanvas from 'react-bootstrap/Offcanvas'
import React, { useState, useEffect } from 'react'
import QrGenerate from '../Qr/QrGenerate'
import './rewardmodel.style.scss'
import config from '../../../configs/config'
function RewardQRModel(props: any) {
    const { open, setOpen, data } = props
    return (
        <>
            <Offcanvas
                show={open}
                onHide={() => setOpen(false)}
                placement="end"
                className="Modal_head"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="wrh__title drp_title">
                        {config.venderTitle} Reward QR code
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr />
                <Offcanvas.Body>
                    <div className="rewardQr">
                        <table className="rewardQr__table">
                            {data.length > 0
                                ? data.map((item: any, i: number) => {
                                      return (
                                          <tr key={i}>
                                              <td>{item.benefitTitle}</td>
                                              <td>{item.rewardQr}</td>
                                              <td>
                                                  <div className="rewardQr__second">
                                                      <QrGenerate
                                                          benefitList={data}
                                                          height={50}
                                                          width={50}
                                                      />
                                                  </div>
                                              </td>
                                          </tr>
                                      )
                                  })
                                : ''}
                        </table>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default RewardQRModel
