import React, { useEffect, useState } from 'react'
import { Form, Offcanvas } from 'react-bootstrap'
import CustomButton from '../../../../../utils/CustomButton/CustomButton'
import './programSliderTwo.style.scss'
import {
    HttpCallGet,
    HttpCallPost,
} from '../../../../../utils/Services/NetworkCall'
import {
    ToastOnFailure,
    ToastOnSuccess,
} from '../../../../../utils/Toast/Toast'
interface IProps {
    show: boolean
    setShow: any
    data?: any
    activeTitle?: string
    Evt?: any
    btnEventName?: string
    sliderSecond: any
    setSliderSecond: any
    programInfo: any
    loading: any
    setLoading: any
    setProgramInfo: any
}
const ProgramSliderTwo: React.FC<IProps> = ({
    activeTitle,
    show,
    setShow,
    sliderSecond,
    setSliderSecond,
    programInfo,
    setProgramInfo,
}) => {
    const addRewardItems = () => {
        if (sliderSecond.type.length <= 0) {
            ToastOnFailure('Please select the checkbox')
            return
        }
        if (sliderSecond.rewardQr.length <= 0) {
            ToastOnFailure('Please enter qr')
            return
        }
        if (sliderSecond.points.length <= 0) {
            ToastOnFailure('Please enter points')
            return
        }
        if (sliderSecond.benefitTitle?.length <= 0) {
            ToastOnFailure('Please enter title')
            return
        }
        if (sliderSecond.benefitValue.length <= 0) {
            ToastOnFailure('Please enter value')
            return
        }

        HttpCallGet(
            `admin-reward/qr-avail-check?rewardQr=${sliderSecond.rewardQr}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                sessionStorage.setItem('qrAvailable', res.data.status)
                if (res.data.status === 0) {
                    ToastOnFailure(res.data.message)
                } else {
                    ToastOnSuccess(res.data.message)
                }
                if (res.data.status === 1) {
                    const obj1: any = {
                        benefitDesc: '',
                        rewardQr: sliderSecond.rewardQr,
                        type: sliderSecond.type,
                        points: sliderSecond.points,
                        benefitTitle: sliderSecond.benefitTitle,
                        benefitValue: sliderSecond.benefitValue,
                        rewardId: programInfo?._id,
                    }

                    if (activeTitle === 'Add Program') {
                        const obj2 = {
                            benefitDesc: '',
                            rewardQr: sliderSecond.rewardQr,
                            type: sliderSecond.type,
                            points: sliderSecond.points,
                            benefitTitle: sliderSecond.benefitTitle,
                            benefitValue: sliderSecond.benefitValue,
                        }
                        setProgramInfo((prevState: any) => {
                            const benefitList = Array.isArray(
                                prevState.benefitList
                            )
                                ? prevState.benefitList
                                : []
                            return {
                                ...prevState,
                                benefitList: [...benefitList, obj2],
                            }
                        })
                        setShow(false)
                    } else {
                        HttpCallPost(
                            `admin-reward/create-benefit`,
                            {
                                benefitDesc: '',
                                rewardQr: sliderSecond.rewardQr,
                                type: sliderSecond.type,
                                points: sliderSecond.points,
                                benefitTitle: sliderSecond.benefitTitle,
                                benefitValue: sliderSecond.benefitValue,
                                rewardId: programInfo?._id,
                            },
                            sessionStorage.getItem('AUTH_TOKEN')
                        )
                            .then((res: any) => {
                                setShow(false)
                                setProgramInfo((prevState: any) => ({
                                    ...prevState,
                                    benefitList: [
                                        ...prevState.benefitList,
                                        obj1,
                                    ],
                                }))
                                ToastOnSuccess(res.data.message)
                            })
                            .catch((err) => {
                                console.log(err.message)
                            })
                    }
                } else {
                    setSliderSecond((prev: any) => ({ ...prev, rewardQr: '' }))
                }
            })
            .catch((err) => {
                console.log(err.message)
                return false
            })
    }

    const handleRadioChange = (event: any) => {
        setSliderSecond((prev: any) => ({ ...prev, type: event.target.value }))
    }

    useEffect(() => {
        setSliderSecond({
            type: '',
            rewardQr: '',
            points: '',
            benefitTitle: '',
            benefitValue: '',
        })
    }, [!show])

    return (
        <div>
            <Offcanvas
                show={show}
                onHide={() => setShow(false)}
                placement="end"
                className="sliderTwo"
            >
                <Offcanvas.Header>
                    <Offcanvas.Title className={`SliderTwo__title`}>
                        Add Rewards ({sessionStorage.getItem('WR_TITLE')})
                    </Offcanvas.Title>
                    <div>
                        <CustomButton
                            clsName="ms-2"
                            btnEvt={() => setShow(false)}
                            btnName="Cancel"
                        />
                        <CustomButton
                            clsName="ms-2"
                            btnEvt={() => addRewardItems()}
                            btnName="Create"
                        />
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="secProgramSlider">
                        <Form.Check
                            inline
                            label="Cash"
                            name="group1"
                            type="radio"
                            value={1}
                            checked={sliderSecond.type == 1 ? true : false}
                            onChange={handleRadioChange}
                        />
                        <Form.Check
                            inline
                            label="Merchendise"
                            name="group1"
                            type="radio"
                            value={2}
                            checked={sliderSecond.type == 2 ? true : false}
                            onChange={handleRadioChange}
                        />

                        <br className="mb-2" />
                        <div className="mt-3">
                            <Form.Label htmlFor="qr">QR code</Form.Label>
                            <Form.Control
                                type="text"
                                id="qr"
                                aria-describedby="passwordHelpBlock"
                                value={sliderSecond.rewardQr}
                                onChange={(e: any) =>
                                    setSliderSecond((prev: any) => ({
                                        ...prev,
                                        rewardQr: e.target.value,
                                    }))
                                }
                            />

                            <Form.Label htmlFor="points">
                                No of Points
                            </Form.Label>
                            <Form.Control
                                type="number"
                                id="points"
                                aria-describedby="passwordHelpBlock"
                                min="0"
                                step="1"
                                onWheel={(event) => event.currentTarget.blur()}
                                defaultValue={sliderSecond?.points}
                                // onChange={(e) => setSliderS  econd((prev: any) => ({ ...prev, points: e.target.value }))}
                                onChange={(e: any) => {
                                    const inputValue = e.target.value
                                    const numericValue = parseFloat(inputValue)
                                    if (
                                        !isNaN(numericValue) &&
                                        !['e', 'E', '+', '-'].includes(e.key)
                                    ) {
                                        setSliderSecond((prev: any) => ({
                                            ...prev,
                                            points: numericValue,
                                        }))
                                    } else {
                                        e.preventDefault()
                                    }
                                }}
                            />

                            <Form.Label htmlFor="Title">Title</Form.Label>
                            <Form.Control
                                type="text"
                                id="Title"
                                aria-describedby="passwordHelpBlock"
                                value={sliderSecond.benefitTitle}
                                onChange={(e: any) =>
                                    setSliderSecond((prev: any) => ({
                                        ...prev,
                                        benefitTitle: e.target.value,
                                    }))
                                }
                            />

                            <Form.Label htmlFor="Value">Value</Form.Label>
                            <Form.Control
                                type="number"
                                id="Value"
                                min="0"
                                step="1"
                                onWheel={(event) => event.currentTarget.blur()}
                                aria-describedby="passwordHelpBlock"
                                defaultValue={sliderSecond.benefitValue}
                                // onChange={(e) => setSliderSecond((prev: any) => ({ ...prev, benefitValue: e.target.value }))}
                                onChange={(e: any) => {
                                    const inputValue = e.target.value
                                    const numericValue = parseFloat(inputValue)

                                    if (!isNaN(numericValue)) {
                                        setSliderSecond((prev: any) => ({
                                            ...prev,
                                            benefitValue: numericValue,
                                        }))
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default ProgramSliderTwo
