import React, { useState } from 'react'
import './forget.style.scss'
import { useNavigate } from 'react-router-dom';
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast';
import { FORGET_PASSWORD } from '../../utils/Services/EndPoints';
import { HttpCallPost } from '../../utils/Services/NetworkCall';
import config from '../../configs/config';

const Forget = (props:any) => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [loading ,setLoading] = useState(false)

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(email.length==0){
            ToastOnFailure("Please enter the email")
           return  
        }
        setLoading(true)
        HttpCallPost(FORGET_PASSWORD, {
            email:email,
        })
        .then((response: any) => {
          setLoading(false);
          if(response.data.status<=0){
            ToastOnFailure( response.data.message);
          }else{
            navigate('/login');
            ToastOnSuccess(response.data.message)
          }
       
        })
        .catch((error) => {
            ToastOnFailure(error?.data?.msg);
          setLoading(false);
          console.log(error);
        });

    }

    const handleForgetPasswordClick = () => {
        navigate('/login')   
    }

    return (
        <div className="forget">
            <div className="forget__background" />
            <div className="forget__content">
                <form className="forget__form" onSubmit={handleSubmit}>
                    <div className="forget__form-group">
                        <div className="forget__logo">
                            <img className="forget__bg" src={config.logoPath} />
                        </div>
                        <strong className="forget__heading">Forget Password</strong>
                        <label className="forget__label" htmlFor="email">
                            Email
                        </label>
                        <input
                            className="forget__input"
                            id="email"
                            type="email"
                            value={email}
                            placeholder="Email"
                            onChange={handleEmailChange}
                        />
                    </div>
                    <button className="forget__button" type="submit">
                        Submit
                    </button>
                    <div className="forget__form-group">
                        <a
                            className="forget__forget-password"
                            onClick={handleForgetPasswordClick}
                        >
                            Back To Login
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Forget
