import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import './reportSlider.style.scss'
import { DetailsInterface } from '../Report'
import { dateFormater } from '../../../utils/util'

interface SliderProps {
    show: boolean
    setShow: any
    data: any
}
const ReportSlider: React.FC<SliderProps> = ({ show, setShow, data }) => {
    return (
        <>
            <Offcanvas
                placement="end"
                show={show}
                onHide={() => setShow(false)}
                className="ModalHead"
            >
                <Offcanvas.Header
                    className="Report_Modal_Header commonHeaderTitle"
                    closeButton
                >
                    Report Details ({sessionStorage.getItem('WR_TITLE')})
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="orderModalTable table-responsive">
                        {/* <table>
                            <tr>
                                <th className="Demo">Box</th>
                                <th className="Demo">From</th>
                                <th className="Demo">To</th>
                                <th className="Demo">Checkout Date</th>
                            </tr>
                            <tr>
                                <td className="firstCl">
                                    <img
                                        src={data.imgUrl}
                                        className="ReportSlider_img"
                                    />
                                    <div className="_id">{data.id}</div>
                                </td>
                                <td>{data.from}</td>
                                <td>{data.to}</td>
                                <td>{data.date} </td>
                            </tr>
                        </table> */}
                        <table>
                            <tr>
                                <th className="Demo">Box</th>
                                <th className="Demo">From</th>
                                <th className="Demo">To</th>
                                <th className="Demo">Checkout Date</th>
                            </tr>
                            {data.length > 0 ? (
                                data.map((item: any, i: number) => {
                                    console.log("item", item)
                                    return (
                                        <>
                                            <tr>
                                                <td className="firstCl">
                                                    <img
                                                        src={item.boxTypeInfo[0].icon}
                                                        className="ReportSlider_img"
                                                    />
                                                    <div className="_id">{item.boxId}</div>
                                                </td>
                                                <td>{item.assignor[0].name}</td>
                                                <td>{item.assignee[0].name}</td>
                                                <td>{dateFormater(item.updatedAt)} </td>
                                            </tr>
                                        </ >
                                    )
                                })
                            ) : ''}
                        </table>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ReportSlider
