import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

interface Iprops {
    inputType?: any
    state?: any
    setState?: any
    placeHold?: any
    callBackHandler?: any
    data?: any
    setData?: any
}
const Search: React.FC<Iprops> = ({
    inputType,
    placeHold,
    data,
    setData
}) => {
    const [searchValue, setSearchValue]= useState('')

    const searchingUser = (words?: any) => {
        if (words.length > 0) {
            const users = data.filter((item: any) => {
               return item?.name ?  item?.name?.toLowerCase().includes(words.toLowerCase()) : ''
            })
            setData(users)
        } else {
            setData(data)
        }
    }

    return (
        <div>
            <Form.Control
                className={''}
                type={inputType}
                id={inputType}
                value={searchValue}
                placeholder={placeHold}
                onChange={(e: any) => {
                    searchingUser(e.target.value)
                    setSearchValue(e.target.value)
                }}
            />
        </div>
    )
}

export default Search
